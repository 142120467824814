// export const app_config = {

//     "backend_url": "https://pacman-mr-backend-python.optum.com/",
//     "javaServiceUrl": "https://pacman-mr-backend-java.optum.com/",
//     "interaction_url": "https://pacman-dev-login.optum.com",
//     "logging_url": "https://pacman-dev-login.optum.com",
//     "env": "Prod",
//     "patient_backend_url": "https://pacman-dev-patient.optum.com",
//     "timeout":900

// }

// export const app_config = {
//     "backend_url": "https://pacman-mr-backendflask-dev.optum.com/",
//  "javaServiceUrl": "https://pacman-mr-backendjava-dev.optum.com/",
// "interaction_url": "https://pacman-dev-login.optum.com",
//     "logging_url": "https://pacman-dev-login.optum.com",
//     "env": "dev",
//     "patient_backend_url": "https://pacman-dev-patient.optum.com",
//     "timeout":900
// }

// export const app_config = {
//     "backend_url": "https://pacman-mr-backendflask-stage.optum.com/",
//     "javaServiceUrl": "https://pacman-mr-backendjava-stage.optum.com/",
//     "interaction_url": "https://pacman-dev-login.optum.com",
//     "logging_url": "https://pacman-dev-login.optum.com",
//     "env": "stage",
//     "patient_backend_url": "https://pacman-dev-patient.optum.com",
//     "timeout":900

// }


// export const app_config = {
//     "backend_url": "https://pacman-mr-backendflask-stage.optum.com/",
//     "javaServiceUrl": "https://pacman-mr-backendjava-stage.optum.com/",
//     "interaction_url": "https://pacman-dev-login.optum.com",
//     "logging_url": "https://pacman-dev-login.optum.com",
//     "env": "stage",
//     "patient_backend_url": "https://pacman-dev-patient.optum.com",
//     "timeout":900

// }


export const app_config = {
    "backend_url": "https://pacman-mr-backendflask-dev.optum.com/",
    "javaServiceUrl": "https://pacman-mr-backendjava-dev.optum.com/",
    "interaction_url": "https://pacman-dev-login.optum.com",
    "logging_url": "https://pacman-dev-login.optum.com",
    "env": "Dev",
    "patient_backend_url": "https://pacman-dev-patient.optum.com",
    "timeout":900

}
