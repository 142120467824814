<div class="loginWrapper" fxLayoutAlign="end stretch">
  <div class="loginWrap" fxFlex="25%" fxLayout="column" fxLayoutAlign="center center">
    <div class="logoContent">
      <div class="logoClass" fxLayout="row wrao" fxLayoutAlign="center end">
        <div class="logoFont" fxFlex="100%" style="margin-bottom: 10px;"> <img src="assets/images/optum.png"
            alt="Optum"> </div>
        <div class="logoText"><strong>P</strong>rescription <strong>A</strong>dherence & <strong>C</strong>linical
          <strong>M</strong>anagement
        </div>
      </div>
      <form [formGroup]="loginForm" fxLayout="column" fxLayoutGap="15px">
        <div fxLayout="column" class="innSpinner" *ngIf="isSpinner">
          <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div fxLayout="column">
          <mat-form-field appearance="outline">
            <mat-label>Region <span style="color: red;"> *</span></mat-label>
            <mat-icon matPrefix>public</mat-icon>
            <mat-select name="cdos" formControlName="cdos">
              <mat-option *ngFor="let cdo of cdos" [value]="cdo.region">
                {{cdo.regionName}}
              </mat-option>
            </mat-select>
            <mat-hint>Please Choose the region </mat-hint>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>User Name <span style="color: red;"> *</span></mat-label>
            <input matInput type="text" placeholder="User Name" name="username" formControlName="username">
            <mat-icon matPrefix>sentiment_very_satisfied</mat-icon>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>Password <span style="color: red;"> *</span></mat-label>
            <input matInput type="password" placeholder="Password" name="password" formControlName="password">
            <mat-icon matPrefix>lock</mat-icon>
            <mat-hint>Please use your MSID and Password.</mat-hint>
          </mat-form-field>

        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <button color="warn" type="submit" mat-flat-button fxFlex="100%" (click)="loginusingMSID()">Login</button>
        </div>
        <div fxLayout="row" fxLayoutAlign="center center">
          <button color="warn" mat-flat-button fxFlex="100%" (click)="OHIDlogin()">One Health Care ID login</button>

        </div>
        <div fxLayout="row" fxLayoutAlign="center center">



        </div>
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="failedMsg" class="failedMsg"><strong
            [ngClass]="FadeOutFaiedlMsg()">Invaild username and password</strong> </div>
        <div fxLayout="row" fxLayoutAlign="center center" *ngIf="noAccessMsg" class="failedMsg"><strong
            [ngClass]="FadeOutFaiedlMsg()">You do not have access for Pacman.</strong> </div>
      </form>
    </div>
  </div>
</div>