<app-header></app-header>
<div class="container-fluid mt-4">

    <div class="is-loader">
        <div class="innSpinner" *ngIf="isSpinner">
            <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="container text-center align-items-center encountertoggle">
            <div class="row justify-content-md-center">
                <div class="col-1 text-end pt-1">
                    <button mat-mini-fab color="primary" class="mat-elevation-z0 backIcon" [routerLink]="['/landingPage']">
                        <mat-icon class="material-icons-outlined">home</mat-icon>
                    </button>
                </div>
                <div class="col-3">
                    <div class="searchWrap" matTooltip="Eg: Pending & diab or new&anti..."
                        matTooltipClass="tooltipSearch">
                        <mat-form-field appearance="standard" (keyup)="globalSearch()">
                            <mat-label>Search</mat-label>
                            <input matInput [(ngModel)]="globalSearchText"
                                placeholder="Eg: Pending & diab or new&anti...">
                            <mat-icon matPrefix class="searchIcon">search</mat-icon>
                            <button matSuffix class="resetBtn" type="reset" color="accent" mat-icon-button
                                (click)="resetSearch()">
                                <mat-icon class="close-icon">close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-md-3 text-start pt-1">
                    <mat-slide-toggle class="active-thumb" (change)="assigntome($event.checked)" style="margin-right: 15px;" [checked]='isAssigned' >Assigned to me</mat-slide-toggle>
                    <mat-slide-toggle class="active-thumb" (change)="allEncounters($event.checked)" [checked]="isAll" >Show all encounters</mat-slide-toggle>
                    <button matSuffix class="resetBtn" type="reset" color="accent" mat-icon-button (click)="resetQueueState()" style="margin-left: 15px;">
                        <mat-icon class="closeIcon" title="Reset">restart_alt</mat-icon>
                    </button>
                </div>
         
            </div>

        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12">
            <div mat-card fxLayout="row" fxFlex="100%" class="patient-card-heading">
                <div fxFlex="8%" style="margin: 0 6px 0 0;"><span>Program Type</span></div>
                <div fxFlex="6%" style="margin: 0 6px 0 0;"><span>Visit Type</span></div>
                <div fxFlex="6%" style="margin: 0 6px 0 0;"><span>Encounter Type</span></div>
                <div fxFlex="11%" style="margin: 0 6px 0 0;"><span>Name</span></div>
                <div fxFlex="7%" style="margin: 0 6px 0 0;"><span>DOB (Age)</span></div>
                <div fxFlex="6%" style="margin: 0 6px 0 0;"><span>Source</span></div>
                <div fxFlex="6%" style="margin: 0 6px 0 0;"><span>Destination</span></div>
                <div fxFlex="6%" style="margin: 0 6px 0 0;"><span>Requested</span></div>
                <div fxFlex="6%" style="margin: 0 6px 0 0;"><span>Notified</span></div>
                <div fxFlex="6%" style="margin: 0 6px 0 0;"><span>Status </span></div>
                <div fxFlex="6%" style="margin: 0 6px 0 0;"><span>Reviewed By</span></div>
                <div fxFlex="6%" style="margin: 0 6px 0 0;"><span>Reviewed Dt</span></div>
                <div fxFlex="7%" style="margin: 0 6px 0 0;"><span>Assigned To</span></div>
                <div fxFlex="11%" style="margin: 0 6px 0 0;"><span>Actions</span></div>
            </div>
        </div>
    </div>
    <div class="col-12">


        <div fxLayout="column" class="patient-wrap" fxLayoutAlign="start start" *ngIf="!queuespinner">

            <div class="iPatient-card" fxLayoutAlign="start start" *ngFor="let ipatientList of memberdetails | paginate: {id:'providersPaginate', itemsPerPage: itemsPerPage, currentPage: page };"
                [style.border-left]="ipatientList.isStatus">
                <div mat-card fxLayout="row" fxLayoutAlign="start start" fxFlex="100%" (click)="rowclick(ipatientList,$event)" class="grid">
                    <div class="label-text-new" fxFlex="8%" style=" margin: 0 6px 0 0; line-height: 37px;" matTooltip="{{ipatientList.lob}}">{{ipatientList.lob}}</div>
                    <div fxFlex="6%" style=" margin: 0 6px 0 0; line-height: 37px;">{{ipatientList.visitType}}</div>
                    <div class="label-text-new" fxFlex="6%" style=" margin: 0 6px 0 0; line-height: 37px;" matTooltip="{{ipatientList.encounterType}}">{{ipatientList.encounterType}}</div>
                    <div fxFlex="11%" style=" margin: 0 6px 0 0; line-height: 37px;" matTooltip="{{getTitleCase(ipatientList.firstName)}} {{getTitleCase(ipatientList.lastName)}}">{{getTitleCase(ipatientList.firstName)}} {{getTitleCase(ipatientList.lastName)}} </div>
                    <div fxFlex="7%" style=" margin: 0 6px 0 0; line-height: 37px;">{{ipatientList.dob  | date: 'MM-dd-yyyy'}}  ({{getAge(ipatientList.dob)}}) </div>
                    <div class="label-text-new" fxFlex="6%" style=" margin: 0 6px 0 0; line-height: 37px;" matTooltip="{{ipatientList.sourceProvider}}">{{ipatientList.sourceProvider}}</div>
                    <div fxFlex="6%" style=" margin: 0 6px 0 0; line-height: 37px;">{{ipatientList.destinationProvider}}</div>
                    <div fxFlex="6%" style=" margin: 0 6px 0 0; line-height: 37px;">{{ipatientList.requestedOn | date: 'MM-dd-yyyy'}}</div>
                    <div fxFlex="6%" style=" margin: 0 6px 0 0; line-height: 37px;"> {{ipatientList.notifiedOn | date: 'MM-dd-yyyy'}}</div>
                    <div fxFlex="6%" style=" margin: 0 5px 0 0; line-height: 37px;">{{ipatientList.status}} </div>
                    <div class="label-text-new" fxFlex="6%" style=" margin: 0 6px 0 0; line-height: 37px;" matTooltip="{{ipatientList.reviewedBy}}">{{ipatientList.reviewedBy}}</div>
                    <div fxFlex="6%" style=" margin: 0 6px 0 0; line-height: 37px;">{{ipatientList.reviewedDate | date: 'MM-dd-yyyy'}}</div>
                    <div class="label-text-new" fxFlex="7%" style=" margin: 0 6px 0 0; line-height: 37px;" matTooltip="{{ipatientList.assignedTo}}">{{ipatientList.assignedTo}} </div>
                </div>
                <div fxFlex="11%" style=" margin: 0 6px 0 0; line-height: 37px;" class="actionsbtns">
           
                    <button mat-icon-button type="edit" color="default">
                        <mat-icon matTooltip="Edit Encounter" (click)="edit(ipatientList)">edit</mat-icon>
                    </button>
                    <button mat-icon-button type="edit" color="default">
                        <mat-icon matTooltip="Delete Encounter" (click)="delete(ipatientList)">delete</mat-icon>
                    </button>
                    <button mat-icon-button type="edit" color="default">
                        <mat-icon matTooltip="Close Encounter" (click)="close(ipatientList)">assignment_late</mat-icon>
                    </button>
                    <button mat-icon-button type="edit" color="default">
                        <mat-icon matTooltip="Show Activity Log" (click)="showLog(ipatientList)">list</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div class="no-Data" *ngIf="memberdetails?.length == 0" style="text-align: center;">No Rows To Show</div>
        <div class="paginationWrapper" fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex="170px" class="pageWarp">
                <label>Items Per Page</label>
                <select [(ngModel)]="itemsPerPage">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="100000">All</option>
                </select>
            </div>
            <div>
                <pagination-controls (click)="setPage(page)" id="providersPaginate" (pageChange)="page = $event"
                    class="paginationWrap"></pagination-controls>
            </div>
        </div>
    </div>
</div>
