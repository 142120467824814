import { SelectionModel } from '@angular/cdk/collections';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from '../data.service';
import { TechniciansComponent } from '../technicians/technicians.component';
import { IdleTimeoutService } from '../idle-timeout.service';

@Component({
  selector: 'app-assignmember',
  templateUrl: './assignmember.component.html',
  styleUrls: ['./assignmember.component.css']
})
export class AssignmemberComponent implements OnInit {

  itemsPerPage = 10;
  page: any;
  isSpinner: boolean;
  public ipatientDetails: any
  public errorMsg;
  patientMrnValue: string;
  patientList: any
  searchFail = false;
  totalPages = [1];
  currentPage = 1;
  filterPatient: string;
  onAddFilterdatas = []
  onAddSortdatas = []
  showNewCases = true;
  showPendingCases = true;
  filteredCategory: any;
  mrnStringArray: any

  selectedCategory: any;
  selectedUpdatedBy: any;
  selectedPatName: any;
  filters = {}
  showFilterWRap = {
    customizeDiv: false,
    filterDiv: false,
  }
  globalSearchText: any = "";
  unassignedTech: any;
  texttech: any;

  showAssign = false;

  filteredPatientDetails;
  patientDetail: Object;
  //  nestedPatientList: Object;
  filteredSystemStatus: Array<any>;
  medicationlist: Object;
  backButtonVisible: boolean = false;
  filteredDrugCategory: Observable<any[]>;
  filteredUpdatedBy: Observable<any[]>;
  filteredPatName: Observable<any[]>;
  drugCategory = new Set();
  medlastUpdatedBy = new Set();
  medPatName = new Set();
  noData: string;

  getMedCountd = new Set();
  ismuliserach: any;
  filter: any = "Search"
  snackBarRef: any;
  isNewCases = false
  isRenewCases = false
  region: any = ""
  currList:any;
  encounterData :any;
  isAll:boolean = false;
  isAssigned:boolean = false;
  totalActCount:boolean = false;

  constructor(public dialog: MatDialog, public router: Router, public dataservice: DataService,private idleService: IdleTimeoutService) {
    this.idleService.idleTimeout.subscribe(() => {
      this.dialog.closeAll()
    })


  }
  mrnCount: any;
  medCount: any;
  selectedRows: any;
  overAllMedcount = 0;
  memberdetails: any
  cdo: any = localStorage.getItem('cdo')


  ngOnInit(): void {


    this.dataservice.getEncounterworkqueudata().subscribe(data => {
      console.log(data)
      this.patientList = data
      this.memberdetails = this.patientList.filter(e => (e['status'].toLowerCase()==('data entry')||e['status'].toLowerCase()==('rph review') || e['status'].toLowerCase()==('reviewed')||e['status'].toLowerCase()==('reconciled')));;
      this.totalActCount = this.memberdetails.length
      this.currList = this.memberdetails; 

    }, error => {

    });



  }


  viewUnassigned(event) {
    console.log(event);
    localStorage.setItem("unassignedTech", event.toString());
    window.location.reload();
  }
  viewTextworkqueue(event) {
    console.log(event);
    localStorage.setItem("texttech", event.toString());
    window.location.reload();
  }



  private _filter(source, value: string): string[] {
    const filterValue = value.toLowerCase();
    return source.filter(option => option.toLowerCase().includes(filterValue));

  }












  backbutton() {


  }

  FadeOutSearchFail() {
    setTimeout(() => {
      this.searchFail = false;
    }, 5000);
  }

  setPage(currentPage) {
    console.log("current Page>>", currentPage);
    localStorage.setItem('page', currentPage);

  }





  /* start */
  name: 'Indeterminate';
  isChecked: false;
  checkCount: false;
  allComplete: boolean = false;
  mrnArray: any[];

  updateAllComplete() {
    this.allComplete = this.memberdetails != null && this.memberdetails.every(t => t.isChecked);
    console.log(this.memberdetails);
    console.log("updateALL>>>>>", this.memberdetails.filter(t => t.isChecked))
    this.updateCount();
  }
  updateCount() {
    this.mrnCount = 0;
    this.medCount = 0;
    this.overAllMedcount = 0;
    //this.mrnCount = this.filteredSystemStatus.filter(t => t.isChecked === true).length;
    this.selectedRows = this.memberdetails.filter(t => t.isChecked === true);
    this.mrnCount = this.selectedRows.length;
    this.selectedRows.forEach((t) => this.medCount = this.medCount + t.medicationDetails.length)

    this.memberdetails.forEach((t) => this.overAllMedcount = this.overAllMedcount + t.medicationDetails.length)



  }

  someComplete(): boolean {
    if (this.memberdetails == null) {
      return false;
    }
    return this.memberdetails.filter(t => t.isChecked).length > 0 && !this.allComplete;
  }

  setAll(isChecked: boolean) {
    this.allComplete = isChecked;
    if (this.memberdetails == null) {

      return;
    }
    this.memberdetails.forEach(t => (t.isChecked = isChecked));
    this.updateCount();
  }
  edit(data) {

    this.router.navigate(['/editEncounter/' + data.encounterId]); //610882
  }
  rowclick(data) {
    localStorage.setItem('encounterId', data.encounterId)



    this.router.navigate(['/patientdetails/' + data.mbrRefNo]);
  }
  assigntome(value) {

    console.log(value)
    if ((value)&&(!this.isAll)) {
      this.memberdetails = this.currList.filter(this.getassigned);
      this.currList = this.memberdetails
      this.isAssigned = true
      console.log('1')
    }
    else if((value)&&(this.isAll)){
      this.memberdetails = this.currList.filter(this.getassigned);
      this.currList = this.memberdetails
      this.isAssigned= true
      console.log('2')
    }
    else if((!value)&&(this.isAll)){
      this.memberdetails=this.patientList
      this.currList = this.memberdetails
      this.isAssigned = false
      console.log('3')
    }
    else {
      this.memberdetails = this.patientList.filter(e => (e['status'].toLowerCase()==('data entry')||e['status'].toLowerCase()==('rph review') || e['status'].toLowerCase()==('reviewed')||e['status'].toLowerCase()==('reconciled')));      
      this.currList = this.memberdetails
      this.isAssigned = false
      console.log('4')
    }
  }

  getassigned(obj) {
    return obj.assignedTo == localStorage.getItem('usname');
  }

  globalSearch() {
    localStorage.setItem("searchTerm", this.globalSearchText);
    if (this.globalSearchText.includes("&")) {
      console.log(this.globalSearchText.split("&"));

      this.memberdetails = this.searchData(this.currList, this.globalSearchText.split("&")[0]);
      for (let ic: any = 1; ic <= this.globalSearchText.split("&").length; ic++) {
        this.memberdetails = this.searchData(this.memberdetails, this.globalSearchText.split("&")[ic]);

      }

    }
    else {
      this.memberdetails = this.searchData(this.currList, this.globalSearchText);

      this.page = 1;
    }
  }
  searchData(dataObj, searchValue: any = '') {

    return dataObj.flatMap(ele => {
      const objHasSearchTerm = Object.entries(ele)
        .some(([key, value]) => key !== 'medicationDetails' && String(value).toLowerCase().includes(searchValue.toLowerCase()));

      if (objHasSearchTerm) return [ele]

      const matchedMedication = this.searchData(ele.medicationDetails ?? [], searchValue);
      let sd = objHasSearchTerm || matchedMedication.length > 0
        ? [{
          ...ele,
          medicationDetails: matchedMedication,
        }]
        : [];

      return sd;
    })

  }
  getAge(dob) {
    if (dob != null || dob != undefined) {
      let dobarr = dob.split("-")
      let birthMonth: any = dobarr[1]
      let birthDay: any = dobarr[2];
      let birthYear: any = dobarr[0]
      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth();
      var currentDay = currentDate.getDate();
      var calculatedAge = currentYear - birthYear;

      if (currentMonth < birthMonth - 1) {
        calculatedAge--;
      }
      if (birthMonth - 1 == currentMonth && currentDay < birthDay) {
        calculatedAge--;
      }
      return calculatedAge;
    }
    else {
      return "NA"
    }
  }
  resetSearch() {

    this.globalSearchText = "";
    console.log("searchempy>>>", this.globalSearchText)

    this.globalSearchText = "";
    localStorage.setItem("searchTerm", this.globalSearchText);
    this.memberdetails = this.currList;
  }
  assignTo() {
    console.log(this.memberdetails);
    console.log("updateALL>>>>>", this.memberdetails.filter(t => t.isChecked))
    this.mrnArray = this.memberdetails.filter((t) => t.isChecked).map(t => t.encounterId)
    this.mrnStringArray = [];
    this.mrnArray.forEach(e => {
      let stringMrn = "'" + e + "'"
      this.mrnStringArray.push(stringMrn)

    })
    console.log(this.mrnArray)

    this.dialog.open(TechniciansComponent, {
      data: {
        dataMrnList: this.mrnStringArray,
        totalCount: this.totalActCount
      },
      height: '69%',
      width: '600px'
    });
  }

  allEncounters(value){
    console.log(value)
    if ((value)&&(!this.isAssigned)) {
      this.memberdetails = this.patientList
      this.currList = this.memberdetails
      this.isAll = true
      console.log('1-2')
    }
    else if((value)&&(this.isAssigned)){
      this.currList = this.patientList
      this.memberdetails = this.currList.filter(this.getassigned)
      this.currList = this.memberdetails
      this.isAll = true
      console.log('2-2')
    }
    else if((!value)&&(this.isAssigned)){
      this.memberdetails=this.currList.filter(e => (e['status'].toLowerCase()==('data entry')||e['status'].toLowerCase()==('rph review') || e['status'].toLowerCase()==('reviewed')||e['status'].toLowerCase()==('reconciled')))
      this.currList = this.memberdetails
      this.isAll = false
      console.log('3-2')
    }
    else {
      this.memberdetails = this.patientList.filter(e => (e['status'].toLowerCase()==('data entry')||e['status'].toLowerCase()==('rph review') || e['status'].toLowerCase()==('reviewed')||e['status'].toLowerCase()==('reconciled')));      
      this.currList = this.memberdetails
      this.isAll = false
      console.log('4-2')
    }

    }
  /* End */
}






/* End */




function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}