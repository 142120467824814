export  function getDateTime() {
    var now: any = new Date();
    var year: any = now.getFullYear();
    var month: any = now.getMonth() + 1;
    var day: any = now.getDate();
    var hour: any = now.getHours();
    var minute: any = now.getMinutes();
    var second: any = now.getSeconds();
    if (month.toString().length == 1) {
      month = '0' + month;
    }
    if (day.toString().length == 1) {
      day = '0' + day;
    }
    if (hour.toString().length == 1) {
      hour = '0' + hour;
    }
    if (minute.toString().length == 1) {
      minute = '0' + minute;
    }
    if (second.toString().length == 1) {
      second = '0' + second;
    }
    var dateTime = year + "" + month + "" + day + "" + hour + "" + minute + "" + second;
    return dateTime;
  }

  export  function getDate() {
    var now: any = new Date();
    var year: any = now.getFullYear();
    var month: any = now.getMonth() + 1;
    var day: any = now.getDate();
    var hour: any = now.getHours();
    var minute: any = now.getMinutes();
    var second: any = now.getSeconds();
    if (month.toString().length == 1) {
      month = '0' + month;
    }
    if (day.toString().length == 1) {
      day = '0' + day;
    }
    if (hour.toString().length == 1) {
      hour = '0' + hour;
    }
    if (minute.toString().length == 1) {
      minute = '0' + minute;
    }
    if (second.toString().length == 1) {
      second = '0' + second;
    }
    var dateTime = year + "-" + month + "-" + day
    return dateTime;
  }
  export function formatDataDDMMYYYYhyphennated(date: any) {
    var dateArray = date.split("-");
    var formattedDate = dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0];
    return formattedDate;
  }
  export function formatDataDDMMYYYY(date: any) {
    var dateArray = date.split("-");
    var formattedDate = dateArray[0] + dateArray[1]  + dateArray[2];
    return formattedDate;
  }
  export function formatDataDDMMYYYYDashed(date: any) {
    var dateArray = date.split("-");
    var formattedDate = dateArray[1] +"-" + dateArray[2]+"-"+ dateArray[0];
    return formattedDate;
  }

  export function titleCase(str) {
    return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
  }
  export function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  export function formatToDDMMYYFromYYMMDDHHMMSS(date: any) {
    if(date!=null && date!=undefined && date!=""){
    var dateArray = date.split("-");
    var formattedDate = dateArray[1].split(" ")[0] +"-" + dateArray[2].split(" ")[0]+"-"+ dateArray[0];
    return formattedDate;
    }
    else return "";
  }
  

