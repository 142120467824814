import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'phoneformat'
})
export class PhoneformatPipe implements PipeTransform {

  transform(data: string, ...args: unknown[]): unknown {
    if (data!=undefined && data!='' && data!= null && data.length == 10) {

      return "(" + data.substring(0, 3) + ") " + data.substring(3, 6) + "-" +data.substring(6, 10)
 


  }
  return data;



}
}
