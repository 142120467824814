

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { app_config } from 'src/assets/config';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  })
};
@Injectable({
  providedIn: 'root'
})


export class DataService {

  
  private _ndcdata: string = app_config.backend_url + 'getndcinfo'
  private _getuser: string = app_config.backend_url + 'login'
  private _gettechnicians = app_config.backend_url + 'technicians'
  private _getNdcData = app_config.backend_url + 'getNDC'
  private _getDrugNDCGPIMapping = app_config.backend_url + "getdruginfo"
  private _ldaploginUrl = app_config.backend_url + 'loginmsid'
  private _interventionUrl = app_config.backend_url + 'generateinterventionreport'
  private _generatepatientreportUrl = app_config.backend_url + 'generatepatientreport'
  private _generateproviderreportUrl = app_config.backend_url + 'generateproviderreport'
  private _fetchActiveMedications = app_config.backend_url + "fetch_active_mediation"
  private _getdrugintercation = app_config.interaction_url + '/mdspan/druginteraction'
  private _addnewmed: string = app_config.javaServiceUrl + 'v1/api/toc/addMedication';
  private _searchMember = app_config.javaServiceUrl + "v1/api/toc/patientSearch"
  private _getPatientData = app_config.javaServiceUrl + "v1/api/toc/patient/"
  private _getPatientData1 = app_config.javaServiceUrl + "v1/api/toc/serachPatient"
  private _addDrugRegime = app_config.javaServiceUrl + "v1/api/toc/medRegime"
  private _addDrugRegimews = app_config.javaServiceUrl + "v1/api/toc/medRegimews"
  private _getActionPlanPdfData = app_config.javaServiceUrl + "v1/api/toc/generateActionPLan"
  private _getOutreachPdfPdfData = app_config.javaServiceUrl + "v1/api/toc/generateOutreachPdf"
  private _getMedRegimeGPIData = app_config.javaServiceUrl + "v1/api/toc/medRegime/gpi"
  private _updateMedReview = app_config.javaServiceUrl + "v1/api/toc/medReview"
  private _addMemberNote = app_config.javaServiceUrl + "v1/api/toc/addNotes"
  private _getMemberNote = app_config.javaServiceUrl + "v1/api/toc/notes"
  private _addActionPlan = app_config.javaServiceUrl + "v1/api/toc/addActionPlan"
  private _addActionPlanSummay = app_config.javaServiceUrl + "v1/api/toc/actionPlanSummary"
  private _addActionPlanSummayStatus = app_config.javaServiceUrl + "v1/api/toc/actionPlanStatus"
  private _deleteActionPlanMed = app_config.javaServiceUrl + "v1/api/toc/updateActionPlanMed"
  private _editActionPlan = app_config.javaServiceUrl + "v1/api/toc/editActionPan"
  private _getPatientSeq = app_config.javaServiceUrl + "/v1/api/toc/patientSeq"
  private _encounterQueue: string = app_config.javaServiceUrl + "v1/api/toc/encounterDetails"
  private _addEncounter = app_config.javaServiceUrl + "v1/api/toc/encounterDetails"
  private _deleteEncounter = app_config.javaServiceUrl + "v1/api/toc/deleteEncounter"
  private _completeEncounter = app_config.javaServiceUrl + "v1/api/toc/closeEncounter"
  private _updateReviewEncounter = app_config.javaServiceUrl + "v1/api/toc/updateReview"
  private _getismpdata = app_config.javaServiceUrl + "v1/api/toc/ismp"
  private _deleteMedRegimen = app_config.javaServiceUrl + "v1/api/toc/medRegime"
  private _addPatient = app_config.javaServiceUrl + "v1/api/toc/patient"
  private _duplicatePatient = app_config.javaServiceUrl + "v1/api/toc/duplicatePatient"
  private _editPatient = app_config.javaServiceUrl + "v1/api/toc/patient/"
  private _checkMrnPM = app_config.patient_backend_url + '/v1/api/patients/'
  private _assignmember = app_config.javaServiceUrl + "v1/api/toc/assignPatient"
  private _encounterDetailsByMember = app_config.javaServiceUrl + "v1/api/toc/encounterDetailsByPatient"
  public region = localStorage.getItem('regionName')
  private _allergyurl = app_config.javaServiceUrl + "v1/api/mr/common/allergy/medication"
  private _memberAllergies = app_config.javaServiceUrl + "v1/api/toc/addMemberAllergies"
  private _getmemberAllergies = app_config.javaServiceUrl + "v1/api/toc/allergies/"
  private _icdCodes = app_config.javaServiceUrl + "v1/api/mr/common/icd/search"
  private _getDiagnosis = app_config.javaServiceUrl + "v1/api/toc/diagnosis/"
  private _updateDiagnosis = app_config.javaServiceUrl + "v1/api/toc/addPatientDiag"
  private _addIntervention = app_config.javaServiceUrl + "v1/api/toc/addIntervention"
  private _editIntervention = app_config.javaServiceUrl + "/v1/api/toc/editIntervention"
  private _getInterventions: string=app_config.javaServiceUrl + "v1/api/toc/interventions"
  private _getInterventionsmbr: string=app_config.javaServiceUrl + "v1/api/toc/mbrinterventions"
  private __deleteInterventions: string=app_config.javaServiceUrl + "v1/api/toc/deleteIntervention"
  private _deleteManuallyAddedMedication:string=app_config.javaServiceUrl+ "v1/api/toc/deleteManualMedication"
  private _updateEncounterStatus = app_config.javaServiceUrl + "v1/api/toc/updateEncounterStatus"
  private _completeEncounterStatus = app_config.javaServiceUrl + "v1/api/toc/completeEncounter"
  private _getEncounterLog = app_config.javaServiceUrl + "v1/api/toc/encounterLog"
  private _getTechnicians = app_config.javaServiceUrl + "v1/api/toc/"
  private lookupUrl: string = app_config.javaServiceUrl + 'v1/api/mr/common/lookup';
  private _getPatientInfo: string = app_config.javaServiceUrl + 'v1/api/toc/patientsInfo';
  private _updateStatus: string = app_config.javaServiceUrl + 'v1/api/toc/updateStatusOnNewSource'
  private _updateIntervention: string = app_config.javaServiceUrl + 'v1/api/toc/updateInterventions'





  constructor(private httpClient: HttpClient) {

    console.log('hiii')


  }



  getEncounterworkqueudata() {

    return this.httpClient.get(this._encounterQueue + "?region=" + localStorage.getItem('regionName') + "&isDebug=false");
  }


  addnewmed(data) {
    return this.httpClient.post(this._addnewmed + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data)
  }

  addEncounter(data) {

    return this.httpClient.post(this._addEncounter + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data)

  }
  addPatient(data) {

    return this.httpClient.post(this._addPatient + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data)

  }
  checkDuplicatePatient(data) {
    return this.httpClient.post(this._duplicatePatient + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data)


  }
  getAllergyDropdown(value: any) {
    return this.httpClient.post(this._allergyurl, value);

  }
  editPatientData(data) {

    return this.httpClient.put(this._editPatient + data.mbrRefNo + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data)

  }
  deletePatient(data) {
    return this.httpClient.delete(this._editPatient + data + "?region=" + localStorage.getItem('regionName') + "&isDebug=false")

  }


  serachndcdata(data) {
    return this.httpClient.post(this._ndcdata, data)

  }


  getMedispanDrugInteraction(data) {

    return this.httpClient.post(this._getdrugintercation, data);

  }
  login(data) {
    return this.httpClient.post(this._getuser, data);


  }

  getserachmemberdata(data) {

    return this.httpClient.post(this._searchMember + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data);

  }

  gettechnicans() {

    return this.httpClient.get(this._gettechnicians);

  }
  assignmember(data) {

    return this.httpClient.post(this._assignmember + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data);

  }
  searchMemberINPM(data) {
    return this.httpClient.post(this._checkMrnPM + "patbydemo?region=CO&debug=false", data)

  }


  loginUsingMSID(data: any): Observable<any> {

    let authorizationData = 'Basic ' + btoa(data['username'] + ':' + data['userpassword']);
    console.log(":", authorizationData);
    return this.httpClient.post(this._ldaploginUrl, { "region": data['cdos'] }, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authorizationData

      }
    });

  }

  private authToken = "Bearer " + localStorage.getItem("token");

  cdos: any;

  public header = {
    headers: {
      'Authorization': this.authToken
    }
  }

  openedByOthers(data) {
    return this.httpClient.post(app_config.logging_url + '/searchMrn', data)
  }



  getmedispanAdverseEffects1(ndc) {

    return this.httpClient.post(app_config.logging_url + "/mdspan/adverseeffect", ndc);

  }


  getMedispanformatDrugname(mrnID) {
    return this.httpClient.get(app_config.logging_url + "/medication/" + mrnID);
  }





  getNDC(data) {
    return this.httpClient.post(this._getNdcData, data);

  }
  getPatientData(data) {
    return this.httpClient.get(this._getPatientData + data + "?region=" + localStorage.getItem('regionName') + "&isDebug=false");

  }
  getPatientSeq() {
    return this.httpClient.get(this._getPatientSeq + "?region=" + localStorage.getItem('regionName') + "&isDebug=false");

  }
  getdropdown(data) {
    return this.httpClient.get(app_config.javaServiceUrl + "v1/api/toc/drodownList/" + data + "?region=" + localStorage.getItem('regionName') + "");

  }
  getProviderList() {
    return this.httpClient.get(app_config.javaServiceUrl + "/v1/api/toc/providerList" + "?region=" + localStorage.getItem('regionName') + "");


  }
  getEncounterData(data) {

    return this.httpClient.get(app_config.javaServiceUrl + "v1/api/toc/encounterDetail?region=" + localStorage.getItem('regionName') + "&isDebug=false&encounterId=" + data);

  }
  updateEncounter(data) {

    return this.httpClient.put(app_config.javaServiceUrl + "v1/api/toc/encounterDetails?region=" + localStorage.getItem('regionName') + "&isDebug=false", data)

  }
  searchMemberinEligibility(data) {

    return this.httpClient.post(this._getPatientData1 + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data);

  }
  addDrugRegime(data) {

    return this.httpClient.post(this._addDrugRegime + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data);

  }

  updateDrugRegime(data) {
    return this.httpClient.put(this._addDrugRegime + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data);


  }
  getActiveMedications(data) {

    return this.httpClient.post(this._fetchActiveMedications + "?region=" + localStorage.getItem('regionName'), data);

  }
  getMedregimen(data) {

    return this.httpClient.get(this._addDrugRegime + "?region=" + localStorage.getItem('regionName') + "&isDebug=false&id=" + data);

  }
  getMedregimenws(data) {

    return this.httpClient.get(this._addDrugRegimews + "?region=" + localStorage.getItem('regionName') + "&isDebug=false&id=" + data);

  }
  getactionPlanPdfData(encounterid) {
    return this.httpClient.get(this._getActionPlanPdfData + "?region=" + localStorage.getItem('regionName') + "&isDebug=false&id=" + encounterid );


  }
  updateMedReview(data) {

    return this.httpClient.post(this._updateMedReview + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data);

  }
  postMemberNote(data) {
    return this.httpClient.post(this._addMemberNote + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data);

  }
  getMemberNote(data) {
    return this.httpClient.post(this._getMemberNote + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data);

  }
  addActionPlan(data) {
    return this.httpClient.post(this._addActionPlan + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data);

  }
  getActionPlanSummary(data) {
    return this.httpClient.get(this._addActionPlanSummay + "?region=" + localStorage.getItem('regionName') + "&isDebug=false&id=" + data.encounterId + "&status=" + data.status);


  }
  updateActionPlanStatus(id, status) {
    return this.httpClient.put(this._addActionPlanSummayStatus + "?region=" + localStorage.getItem('regionName') + "&isDebug=false&id=" + id + "&status=" + status, '');


  }
  deleteMedActionPlan(data) {
    return this.httpClient.put(this._deleteActionPlanMed + "?region=" + localStorage.getItem('regionName') + "&isDebug=false&id=" + data.actionPlanId + "&gpi=" + data.gpi, '');

  }
  editActionPlan(data) {
    return this.httpClient.post(this._editActionPlan + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data);


  }
  getMedFromNDCGPI(data) {
    return this.httpClient.post(this._getDrugNDCGPIMapping, data);


  }
  deleteEncounter(id) {
    let data = {
      "encounterId": id,
      "userid": localStorage.getItem('usname')
    }
    return this.httpClient.post(this._deleteEncounter + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data);

  }
  completeEncounter(id) {
    let data = {
      "encounterId": id,
      "userid": localStorage.getItem('usname')
    }
    return this.httpClient.post(this._completeEncounter + "?region=" + localStorage.getItem('regionName') + "&isDebug=false", data);

  }
  updateReviewDetails(data, flag) {
    return this.httpClient.post(this._updateReviewEncounter + "?region=" + localStorage.getItem('regionName') + "&isDebug=false&flag=" + flag, data);

  }
  getEncounterworkqueudataForMember(data) {
    return this.httpClient.post(this._encounterDetailsByMember + "?region=" + localStorage.getItem('regionName') + "&isdebug=false", data);
  }
  getIsmpData(flag) {
    return this.httpClient.get(this._getismpdata + "/" + flag + "?region=" + localStorage.getItem('regionName') + "&isdebug=false");

  }
  deleteMedRegimen(data) {
    return this.httpClient.delete(this._deleteMedRegimen + "?region=" + localStorage.getItem('regionName') + "&isdebug=false&id=" + data.encounterId + "&gpi=" + data.gpi);

  }
  genrateProviderPdf(data) {
    return this.httpClient.get(this._getOutreachPdfPdfData + "?region=" + localStorage.getItem('regionName') + "&isdebug=false&id=" + data);
  }
  getDrugRegimeMed(data) {
    return this.httpClient.get(this._getMedRegimeGPIData + "?region=" + localStorage.getItem('regionName') + "&isdebug=false&id=" + data.id + "&gpi=" + data.gpi);
  }
  saveMemberAllegies(data) {
    return this.httpClient.post(this._memberAllergies + "?region=" + localStorage.getItem('regionName') + "&isdebug=false", data);
  }
  geteMemberAllegies(data) {
    return this.httpClient.get(this._getmemberAllergies + data + "?region=" + localStorage.getItem('regionName') + "&isdebug=false");
  }
  getIcdCodes(data) {
    return this.httpClient.post(this._icdCodes,data);
  }
  getPatDiagnosis(data) {
    console.log(this._getDiagnosis+data+"?region="+localStorage.getItem('regionName')+"&isdebug=false")
    return this.httpClient.get(this._getDiagnosis+data+"?region="+localStorage.getItem('regionName')+"&isdebug=false");
  }
  updatePatDiagnosis(data) {
    return this.httpClient.post(this._updateDiagnosis+"?region="+localStorage.getItem('regionName')+"&isdebug=false",data);
  }

  addIntervention(data) {
    return this.httpClient.post(this._addIntervention + "?region=" + localStorage.getItem('regionName'), data);
  }
  editIntervention(data) {
    return this.httpClient.post(this._editIntervention + "?region=" + localStorage.getItem('regionName'), data);
  }
  getInterventions(data) {
    return this.httpClient.get(this._getInterventions+"/"+data + "?region=" + localStorage.getItem('regionName'));
  }

  getInterventionsByMbrRefNO(data) {
    return this.httpClient.get(this._getInterventionsmbr+"/"+data + "?region=" + localStorage.getItem('regionName'))
  }
  deleteIntervention(id){
    return this.httpClient.delete(this.__deleteInterventions+ "?region=" + localStorage.getItem('regionName')+"&isdebug=false"+"&id="+id);
  }
  deleteManuallyAddedMedication(data){
    return this.httpClient.post(this._deleteManuallyAddedMedication + "?region=" + localStorage.getItem('regionName'), data);
  }
  updateEncounterStatus(data, flag,revert) {
    return this.httpClient.post(this._updateEncounterStatus + "?region=" + localStorage.getItem('regionName') + "&isDebug=false&flag=" + flag+'&revert='+revert, data);
  }
  completeEncounterStatus(data){
      return this.httpClient.post(this._completeEncounterStatus + "?region=" + localStorage.getItem('regionName'), data)
    }
   
  getEncounterLog(data)   {
    return this.httpClient.get(this._getEncounterLog+ "?region=" + localStorage.getItem('regionName')+'&isDebug=false'+'&encounterId='+data)
  }

  getTechnicians()   {
    return this.httpClient.get(this._getTechnicians+ "?region=" + localStorage.getItem('regionName'),this.header)
  }

  getLookup(token) {        
    return this.httpClient.get(this.lookupUrl + "?region=" + localStorage.getItem('regionName'), {      
      headers: {
        "Authorization": "Bearer "+token
      }
    }
    )
  }

  getPatientInfo(data)   {
    return this.httpClient.post(this._getPatientInfo+"?region=" + localStorage.getItem('regionName'),data)
  }

  getUpdateStatus()   {
    return this.httpClient.get(this._updateStatus+"?region=" + localStorage.getItem('regionName')+'&isDebug=false')
  }
  getinterventionpdf(data){
    return this.httpClient.post(this._interventionUrl,data,  {
      responseType: 'blob'
    })


  }
  generatePatient(data){
    return this.httpClient.post(this._generatepatientreportUrl,data,  {
      responseType: 'blob'
    })
  }
  generateProvider(data){
    return this.httpClient.post(this._generateproviderreportUrl,data,  {
      responseType: 'blob'
    })
  }

  updateIntervention(data){
    return this.httpClient.post(this._updateIntervention + "?region=" + localStorage.getItem('regionName'), data)
  }
}
