import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { threadId } from 'worker_threads';
import { DataService } from '../data.service';
import { formatAMPM, formatDataDDMMYYYY, formatDataDDMMYYYYDashed, formatToDDMMYYFromYYMMDDHHMMSS, getDate, getDateTime } from '../utilityMethods/helperMethods';
import { saveAs } from 'file-saver';
import { CONSTANT } from 'src/assets/appConstant';


@Component({
  selector: 'app-provider-data',
  templateUrl: './provider-data.component.html',
  styleUrls: ['./provider-data.component.css']
})
export class  ProviderDataComponent implements OnInit {
  responseRequested: any = ''
  providerData: any
  allergies: any
  diagnosis: any = []
  docList: any = []
  selectedDoclist: any = ""
  reportdata2: any
  reportdata3: any
  buttonName = "Generate Report"
  docSpinner: boolean = false
  regimendata: any = []
  havingCoverLatter: boolean = false;
  haveconfigData: boolean;
  configdata: any;
  encounterData: any;
  memberdata: any;
  drugRegimen: any;
  currentTime: string;
  interventiondata: any

  constructor(public dataservice: DataService, private toastr: ToastrService, public dialogRef: MatDialogRef<ProviderDataComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data.popupType == "Provider Report") {
      this.allergies = data.allergies
      this.diagnosis = data.diagnosis
      this.encounterData = data.encounterData,
        this.memberdata = data.memberdata,
        this.drugRegimen = data.drugRegimen
      console.log(this.allergies)
      this.diagnosis = getDiagnosisList(data.diagnosisList)
    }
    else {
      debugger
      this.interventiondata = data.interventiondata
      console.log("interventiondata", this.interventiondata)

    }
  }

  ngOnInit(): void {

    this.addReportsDropdown();

  }
  addReportsDropdown() {
    if (this.data.popupType == "Provider Report") {
      this.docList.push("Provider Report")
      this.docList.push("Provider + Cover Report")
      this.docList.push("Patient Report")
    }
    else {
      this.docList.push("Intervention Tracker")
      this.docList.push("Intervention Tracker+Cover Letter")
      this.docList.push("Cover Letter")

    }


  } onChangeResponseRequested(data) {

    this.responseRequested = data

  }
  generateReport() {
    debugger


    if (this.selectedDoclist.length <= 0) {
      this.toastr.error("Please Select A Document", '', {
        timeOut: 1500,
      })
    }
    else {
      this.buttonName = this.selectedDoclist
    }
    if (this.data.popupType == "Provider Report") {

      this.dataservice.getactionPlanPdfData(localStorage.getItem("encounterId")).subscribe(data => {
        this.configdata = data
        this.haveconfigData = true
        this.downloadOutreachDocuments()
      }, error => {


      })
    }
    else if (this.data.popupType == "Intervention Tracker") {
      this.downloadsInterVentionDocuments()
    }

  }
  downloadOutreachDocuments() {
    this.docSpinner = true

    let data = {}

    data["sendTo"] = getFormattedprimaryphone(this.memberdata.pcpFax),
      data["cdoName"] = "OptumCare " + this.configdata.actionPlanConfig.state
    data["cdoAddress"] = this.configdata.actionPlanConfig.address,
      data["cdoPhone"] = getFormattedprimaryphone(this.configdata.actionPlanConfig.phone),
      data["cdoFax"] = getFormattedprimaryphone(this.configdata.actionPlanConfig.fax),
      data["recipientProvider"] = this.memberdata.pcp,
      data["recipientProviderPhone"] = getFormattedprimaryphone(this.memberdata.pcpPhone),
      data["recipientProviderFax"] = getFormattedprimaryphone(this.memberdata.pcpFax),
      data["reportTitle"] = CONSTANT.ENCOUNTER_TYPE_REVIEW.includes(this.configdata.encounterDetails.encounterType) ? 'Medication Review' : 'Medication Reconciliation';
    data["facilityName"] = this.configdata.encounterDetails.facilityName,
      data["patientName"] = this.configdata.encounterDetails.firstName + " " + this.configdata.encounterDetails.lastName,
      data["patientDob"] = formatDataDDMMYYYYDashed(this.configdata.encounterDetails.dob)
    data["patientgender"] = this.configdata.encounterDetails.gender
    data["patientAge"] = this.getAge(this.configdata.encounterDetails.dob) + ""
    data["admitdate"] = formatToDDMMYYFromYYMMDDHHMMSS(this.configdata.encounterDetails.admitDate),
      data["reviewedDate"] = formatToDDMMYYFromYYMMDDHHMMSS(this.configdata.encounterDetails.reviewedDate)
    data["reviewedBy"] = formatProviderName(this.configdata.encounterDetails.reviewedBy)
    data["providerName"] = this.configdata.encounterDetails.destinationProvider
    data["diagnosis"] = this.configdata.encounterDetails.diagnosedFor.split('-')
    data["encounterType"] = this.configdata.encounterDetails.encounterType

    data["allergies"] = this.allergies
    if (this.selectedDoclist == "Provider Report") {
      data["medicationDetails"] = this.getAllMedications(this.drugRegimen)
      data["category"]="provider"
      data["coverLetter"] = "N"
      this.generateProviderReport(data)

    }
    else if (this.selectedDoclist == "Provider + Cover Report") {
      data["medicationDetails"] = this.getAllMedications(this.drugRegimen)
      data["coverLetter"] = "Y"
      data["comments"] = this.responseRequested
      data["category"]="both"
      this.generateProviderReport(data)

    }
    else if (this.selectedDoclist == "Patient Report") {
      data["medicationDetailsContinued"] = this.getContMedications(this.drugRegimen)
      data["medicationDetailsHold"] = this.getholdMedications(this.drugRegimen)
      console.log("data", data)
      this.generatePatientReport(data)
    }
  }
  downloadsInterVentionDocuments() {
    if(this.selectedDoclist=="Cover Letter"){
      this.interventiondata["coverLetter"]="Y"
      this.interventiondata["category"]="coverletter"
      this.interventiondata["comments"] = this.responseRequested


    }
    else if(this.interventiondata.interventionDetails.length==0){
      this.toastr.error("Please Select Intervention", '', {
        timeOut: 1500,
      })
      return

    }
    else if (this.selectedDoclist=="Intervention Tracker+Cover Letter"){
      this.interventiondata["coverLetter"]="Y"
      this.interventiondata["category"]="both"
      this.interventiondata["comments"] = this.responseRequested

    }
    else if(this.selectedDoclist=="Intervention Tracker"){
      this.interventiondata["coverLetter"]="N"
      this.interventiondata["category"]="intervention"

    }
    this.dataservice.getinterventionpdf(this.interventiondata).subscribe(response => {
      let data: any = response
      const blob = new Blob([data], { type: 'blob' })
      saveAs(blob, this.getInterventionFileName(this.interventiondata) + '.docx');

      this.dataservice.updateIntervention(this.data.interventionList).subscribe( response => {
        console.log(response)
      },
      error => {
      });
    }, error => {
    });
  }
  getFileName(name: any) {
    return this.getTitleCase(this.memberdata.lastName) + this.memberdata.firstName.charAt(0).toUpperCase() + "_" + this.memberdata.dob.replaceAll("-", "") + "_" + name + "_" + formatDataDDMMYYYY(this.getReportdate())


  }
  getInterventionFileName(data: any) {
    return this.getTitleCase(data.patientName.split(' ')[1]) + data.patientName.split(' ')[0].charAt(0).toUpperCase() + "_" + data.patientDob.replaceAll("-", "") + "_intervention_" + formatDataDDMMYYYY(this.getReportdate())

  }

  generatePatientReport(data) {
    this.dataservice.generatePatient(data).subscribe(response => {
      debugger

      let data: any = response
      const blob = new Blob([data], { type: 'blob' })
      saveAs(blob, this.getFileName("PatientML") + '.docx');
    }, error => {


    });
  }
  generateProviderReport(data) {
    this.dataservice.generateProvider(data).subscribe(response => {
      debugger

      let data: any = response
      const blob = new Blob([data], { type: 'blob' })
      saveAs(blob, this.getFileName("MedRec") + '.docx');
    }, error => {


    });
  }
  getDiagnosisList(diagnosisList: any): any {
    let data = []
    diagnosisList.forEach(element => {
      data.push(element.icdShortDesc)
    });
    return data
  }
  getAllMedications(data: any) {
    let medications: any = []
    let statusListFull = []

    statusListFull = ["New", "Changed", "Continued", "Held", "Stopped"]
    statusListFull.forEach(element => {
      let tempObj = {}
      let tempmedications = []
      let flag = false
      data.forEach(ele => {
        if (ele.rphCheck == element) {
          ele["comments"] = ele.providerComments
          ele["isPatTakingMed"] = ele.patTakingMed
          ele["medicationName"] = ele.medication
          ele["medicationNameSuffix"] = this.getMedicationNameSuffix(ele)
          tempmedications.push(ele)
          flag = true
        }
      })
      if (flag) {
        tempObj["medStatus"] = element
        tempObj["medications"] = tempmedications
        medications.push(tempObj)
      }

    })
    return medications
  }
  getContMedications(data: any) {
    let medications: any = []
    let statusListFull = []

    statusListFull = ["New", "Changed", "Continued"]
    statusListFull.forEach(element => {
      let tempObj = {}
      let tempmedications = []
      let flag = false
      data.forEach(ele => {
        if (ele.rphCheck == element) {

          ele["comments"] = ele.patComments
          ele["isPatTakingMed"] = ele.patTakingMed
          ele["medicationName"] = ele.medication
          ele["medicationNameSuffix"] = this.getMedicationNameSuffix(ele)
          tempmedications.push(ele)
          flag = true
        }
      })
      if (flag) {
        tempObj["medStatus"] = element
        tempObj["medications"] = tempmedications
        medications.push(tempObj)
      }

    })
    return medications
  }
  getholdMedications(data: any) {
    let medications: any = []
    let statusListFull = []

    statusListFull = ["Held", "Stopped"]
    statusListFull.forEach(element => {
      let tempObj = {}
      let tempmedications = []
      let flag = false
      data.forEach(ele => {
        if (ele.rphCheck == element) {
          ele["comments"] = ele.patComments
          ele["isPatTakingMed"] = ele.patTakingMed
          ele["medicationName"] = ele.medication
          ele["medicationNameSuffix"] = this.getMedicationNameSuffix(ele)
          tempmedications.push(ele)
          flag = true
        }
      })
      if (flag) {
        tempObj["medStatus"] = element
        tempObj["medications"] = tempmedications
        medications.push(tempObj)
      }

    })
    return medications
  }

  getMedicationNameSuffix(data) {
    let medicationName = ''
    if (data.medDecision == 'EHR') {
      medicationName = data.ehrDose + " " + data.ehrForm + " " + data.ehrRoute + " " + data.ehrFrequency + " " + data.ehrInstructions

    }
    else if (data.medDecision == 'SNF') {
      medicationName = data.snfDose + " " + data.snfForm + " " + data.snfRoute + " " + data.snfFrequency + " " + data.snfInstructions

    }
    else if (data.medDecision == 'Member Reported') {
      medicationName = data.patDose + " " + data.patForm + " " + data.patRoute + " " + data.patFrequency + " " + data.patInstructions
    }
    else if (data.medDecision == 'Claims') {
      medicationName = data.claimDose + " " + data.claimForm + " " + data.claimRoute + " " + data.claimFrequency + " " + data.claimInstructions
    }
    else if (data.medDecision == 'POS') {
      medicationName = data.ssDose + " " + data.ssForm + " " + data.ssRoute + " " + data.ssFrequency + " " + data.ssInstructions
    }
    else if (data.medDecision == 'Inpatient') {
      medicationName = data.dsDose + " " + data.dsForm + " " + data.dsRoute + " " + data.dsFrequency + " " + data.dsInstructions
    }
    return medicationName

  }

  onDoclIstChange(data) {
    if(data!=null && data!=undefined){
    this.selectedDoclist = data
    this.buttonName = this.selectedDoclist

    if (this.selectedDoclist.includes("Provider + Cover Report") || this.selectedDoclist.includes("Intervention Tracker+Cover Letter") || this.selectedDoclist.includes("Cover Letter")) {
      this.havingCoverLatter = true
    }
    else {
      this.havingCoverLatter = false
    }
  }



  }


  getAge(dob) {
    if (dob != null || dob != undefined) {
      let dobarr = dob.split("-")
      let birthMonth: any = dobarr[1]
      let birthDay: any = dobarr[2];
      let birthYear: any = dobarr[0]
      var currentDate = new Date();
      var currentYear = currentDate.getFullYear();
      var currentMonth = currentDate.getMonth();
      var currentDay = currentDate.getDate();
      var calculatedAge = currentYear - birthYear;

      if (currentMonth < birthMonth - 1) {
        calculatedAge--;
      }
      if (birthMonth - 1 == currentMonth && currentDay < birthDay) {
        calculatedAge--;
      }
      return calculatedAge;
    }
    else {
      return "NA"
    }
  }
  getReportdate() {
    this.currentTime = formatAMPM(new Date())
    return getDate()
  }

  formatPhone(data: any) {
    if (data != null && data.length == 10) {
      return "(" + data.slice(0, 3) + ") " + data.slice(3, 6) + "-" + data.slice(6, 10)
    }
    if (data == '' && data == 'null' && data == null && data == 'undefined' && data == undefined && data.length == 0) {
      return "NA"
    }
    return data;
  }

  checkNUll(data: any) {
    if (data != '' && data != 'null' && data != null && data != 'undefined' && data != undefined) {
      return data
    }
    else {
      return "NA"
    }
  }
  getTitleCase(str) {
    if (str == null || str == undefined || str == '') {
      return ""
    }
    return str.replace(
      /\w\S*/g,
      function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }
}
function getDiagnosisList(diagnosisList: any): any {
  let data = []
  diagnosisList.forEach(element => {
    data.push(element.icdShortDesc)
  });
  return data
}
function getFormattedprimaryphone(data: any) {
  if (data != undefined && data != '' && data != null && data.length == 10) {
    return "(" + data.substring(0, 3) + ") " + data.substring(3, 6) + "-" + data.substring(6, 10)
  }
  return data

}
function formatProviderName(data: any) {
  debugger

  if (data != null && data != 'null' && data != '' && data != undefined) {
    let nameArray = data.split(",")
    if (nameArray.length > 1) {
      return nameArray[1] + " " + nameArray[0]
    }
    else {
      return data
    }

  }
  return ""

}