export const CONSTANT = {
    INTERVENTION_OPEN: "Open",
     INTERVENTION_PENDING: "Pending",
     INTERVENTION_CLOSED: "Closed",
     ENCOUNTER_TYPE_REVIEW:'Review',
     ENCOUNTER_TYPE_RECONCILE:'Reconcile',
     INTERVENTION_INI:["Patient Initiated", "Pharmacist Initiated", "Other"],
     REGION_UTAH: "UT",
     REGION_IDAHO: "ID",
    
 
 };