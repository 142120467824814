import { Component, OnInit } from '@angular/core';
import { DataService } from '../data.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-intervention-managment',
  templateUrl: './intervention-managment.component.html',
  styleUrls: ['./intervention-managment.component.css']
})
export class InterventionManagmentComponent implements OnInit {
  isSpinner: boolean=false;
  serviceData: any;
  dataSource1: MatTableDataSource<unknown>;

  displayedColumns1: any = ["createdDate","interventionType", "intervention","interventionCat", "medications", "status", "adherenceBarrier","drugTherapyProblem","transferredToHomedelivery","severityLevel","outcome","dateOfOutcome","notes","responseReceived","recommededAction","interventionStatus"]


  constructor(public dataService:DataService) { }

  ngOnInit(): void {

    this.getInterventionManagement()
  }


  getInterventionManagement(){
    debugger
    this.isSpinner=true
    this.dataService.getInterventionsByMbrRefNO(localStorage.getItem('mberRefNo')).subscribe(data=>{
      
      this.serviceData=data
      this.serviceData.sort(this.sortByDate)
      this.dataSource1 = new MatTableDataSource(this.serviceData);
      this.isSpinner=false

    },error=>{  console.log(error)})
  }

  sortByDate(a, b) {
    if (a.createdDate != null && b.createdDate != null) {


      let c: any = new Date(a.createdDate.split(' ')[0])
      let d: any = new Date(b.createdDate.split(' ')[0])
      console.log('hii')
      return d - c;
    }

}
}
