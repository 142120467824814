<app-header></app-header>
<div class="container-fluid  mt-4">
        <div class="is-loader">
                <div class="innSpinner" *ngIf="isSpinner">
                        <div class="lds-spinner">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                        </div>
                </div>
        </div>

        <div class="row mb-3">
                <div class="container text-center align-items-center">
                        <div class="row">
                                <div class="col-1">
                                        <div fxFlex="15%" fxLayout="row" fxLayoutAlign="start center">
                                                <div @enterAnimation class="assigned-button" fxLayout="row"
                                                        fxLayoutAlign="space-between center" fxLayoutGap="15px" *ngIf="mrnCount">
                                                        <div fxFlex="100%" fxLayout="row wrap" fxLayoutGap="15px"
                                                                fxLayoutAlign="space-between center">
                                                                <div fxLayout="column" fxLayoutAlign="space-between center">Selected
                                                                        <strong>{{mrnCount}}<span>/</span>{{memberdetails?.length}}</strong>
                                                                </div>
                                                        </div>
                                                        <button color="primary" class="mat-elevation-z0" mat-mini-fab
                                                                (click)="assignTo()">
                                                                <mat-icon>group_add</mat-icon>
                                                        </button>
                                                </div>
                                        </div>
                                </div>
                                <div class="col-11">
                                        <div class="row justify-content-md-center">
                                                <div class="col-1 text-end pt-2">
                                                        <button mat-mini-fab color="primary" class="mat-elevation-z0 backIcon" [routerLink]="['/landingPage']">
                                                                <mat-icon class="material-icons-outlined">home</mat-icon>
                                                        </button>
                                                </div>
                                                <div class="col-3">
                                                        <div class="searchWrap" matTooltip="Eg: Pending & diab or new&anti..." matTooltipClass="tooltipSearch">
                                                            <mat-form-field appearance="standard" (keyup)="globalSearch()">
                                                                <mat-label>Search</mat-label>
                                                                <input matInput [(ngModel)]="globalSearchText" placeholder="Eg: Pending & diab or new&anti...">
                                                                <mat-icon matPrefix class="searchIcon">search</mat-icon>
                                                                <button matSuffix class="resetBtn" type="reset" color="accent" mat-icon-button (click)="resetSearch()">
                                                                    <mat-icon class="close-icon">close</mat-icon>
                                                                </button>
                                                            </mat-form-field>
                                                        </div>
                                                </div>
                                                <div class="col-md-3 text-start pt-3">
                                                        <mat-slide-toggle class="active-thumb" (change)="assigntome($event.checked)" style="margin-right: 15px;">Assigned to me</mat-slide-toggle>
                                                        <mat-slide-toggle class="active-thumb" (change)="allEncounters($event.checked)">Show all encounters</mat-slide-toggle>
                                                </div>

                                        </div>
                                </div>
                        </div>
                        

                </div>
        </div>


        <div class="row mt-2">
                <div class="col-12">
                        <div mat-card fxLayout="row" fxFlex="100%" class="patient-card-heading">
                                <mat-checkbox fxFlex="2%" class="example-margin" color="primary" [checked]="allComplete"
                                        [indeterminate]="someComplete()" (change)="setAll($event.checked)">
                                </mat-checkbox>
                                <div class="label-text" fxFlex="8%" style="margin-right: 5px;"><span>Program Type</span></div>
                                <div class="label-text" fxFlex="7%" style="margin-right: 5px;"><span>Visit Type</span></div>
                                <div class="label-text" fxFlex="8%" style="margin-right: 5px;"><span>Encounter Type</span></div>
                                <div class="label-text" fxFlex="12%" style="margin-right: 5px;"><span>Name </span></div>
                                <div class="label-text" fxFlex="6%" style="margin-right: 5px;"><span>DOB(Age) </span></div>
                                <div class="label-text" fxFlex="14%" style="margin-right: 5px;"><span>Source</span></div>
                                <div class="label-text" fxFlex="7%" style="margin-right: 5px;"><span>Destination</span></div>
                                <div class="label-text align-right" fxFlex="6%" style="margin-right: 5px;"><span>Requested</span></div>
                                <div class="label-text align-right" fxFlex="6%" style="margin-right: 5px;"><span>Notified</span></div>
                                <div class="label-text align-right" fxFlex="5%" style="margin-right: 5px;"><span>Status </span></div>
                                <div class="label-text align-right" fxFlex="6%" style="margin-right: 5px;"><span>Reviewed By</span></div>
                                <div class="label-text align-right" fxFlex="6%" style="margin-right: 5px;"><span>Reviewed Dt</span></div>
                                <div class="label-text align-right" fxFlex="7%" style="margin-right: 5px;"><span>Assigned To</span></div>
                        </div>
                </div>
        </div>
        <div class="col-12">
                <div fxLayout="column" class="patient-wrap" fxLayoutAlign="start start">
                        <div class="iPatient-card" fxLayoutAlign="start center"
                                *ngFor="let ipatientList of memberdetails | paginate: {id:'providersPaginate', itemsPerPage: itemsPerPage, currentPage: page };">
                                <div mat-card fxLayout="row" fxLayoutAlign="start center" fxFlex="100%">
                                        <mat-checkbox fxFlex="2%" color="primary" class="example-margin" [(ngModel)]="ipatientList.isChecked" (ngModelChange)="updateAllComplete()"></mat-checkbox>
                                        <div class="mrn-text" fxLayout="column" fxLayoutAlign="start start" fxFlex="8%" style="margin-right: 5px;line-height: 50px;">{{ipatientList.lob}}</div>
                                        <div class="mrn-text" fxLayout="column" fxLayoutAlign="start start" fxFlex="7%" style="margin-right: 5px;">{{ipatientList.visitType}}</div>
                                        <div class="mrn-text" fxLayout="column" fxLayoutAlign="start start" fxFlex="8%" style="margin-right: 5px;">{{ipatientList.encounterType}}</div>
                                        <div class="mrn-text" fxLayout="column" fxLayoutAlign="start start" fxFlex="12%" style="margin-right: 5px;">{{ipatientList.firstName}} {{ipatientList.lastName}}  </div>
                                        <div class="mrn-text" fxLayout="column" fxLayoutAlign="start start" fxFlex="6%" style="margin-right: 5px;">{{ipatientList.dob | date: 'MM-dd-yyyy'}} ({{getAge(ipatientList.dob)}})  </div>
                                        <div class="label-text-new" fxLayout="column" fxLayoutAlign="start start" fxFlex="14%" style="margin-right: 5px;" matTooltip="{{ipatientList.sourceProvider}}">{{ipatientList.sourceProvider}} </div>
                                        <div class="mrn-text" fxLayout="column" fxLayoutAlign="start start" fxFlex="7%" style="margin-right: 5px;">{{ipatientList.destinationProvider}} </div>
                                        <div class="mrn-text" fxLayout="column" fxLayoutAlign="start start" fxFlex="6%" style="margin-right: 5px;">{{ipatientList.requestedOn | date: 'MM-dd-yyyy'}} </div>
                                        <div class="mrn-text" fxLayout="column" fxLayoutAlign="start start" fxFlex="6%" style="margin-right: 5px;">{{ipatientList.notifiedOn | date: 'MM-dd-yyyy'}} </div>
                                        <div class="mrn-text" fxLayout="column" fxLayoutAlign="start start" fxFlex="5%" style="margin-right: 5px;">{{ipatientList.status}} </div>
                                        <div class="mrn-text" fxLayout="column" fxLayoutAlign="start start" fxFlex="6%" style="margin-right: 5px;" matTooltip="{{ipatientList.lastupdate_by}}">{{ipatientList.lastupdate_by }} </div>
                                        <div class="mrn-text" fxLayout="column" fxLayoutAlign="start start" fxFlex="6%" style="margin-right: 5px;">{{ipatientList.lastupdate_dt | date: 'MM-dd-yyyy'}} </div>
                                        <div class="mrn-text" fxLayout="column" fxLayoutAlign="start start" fxFlex="7%" style="margin-right: 5px;">{{ipatientList.assignedTo}} </div>
                                </div>
                        </div>
                </div>
                <div *ngIf="memberdetails?.length === 0" style="text-align: center; ">
                        No Rows To Show
                      </div>
                <div class="paginationWrapper" fxLayout="row" fxLayoutAlign="center center">
                        <div fxFlex="170px" class="pageWarp">
                                <label>Items Per Page</label>
                                <select [(ngModel)]="itemsPerPage">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="100000">All</option>
                                </select>
                        </div>
                        <div>
                                <pagination-controls (click)="setPage(page)" id="providersPaginate"
                                        (pageChange)="page = $event" class="paginationWrap"></pagination-controls>
                        </div>
                </div>
        </div>
</div>