import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reportgrid',
  templateUrl: './reportgrid.component.html',
  styleUrls: ['./reportgrid.component.css'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        animate(500, style({ opacity: 0 }))
      ])
    ]),
    trigger('widthGrow', [
      state('closed', style({
        width: 100,
      })),
      state('open', style({
        width: 500
      })),
      transition('* => *', animate(100))
    ])
  ]
})
export class ReportgridComponent implements OnInit {

  app_config: any
  sessionObj: any;
  region: any

  constructor(public _router: Router) { }

  ngOnInit(): void {
    localStorage.setItem('reportName', 'OUTREACH_REPORT')
    this._router.navigate(['/report/oureachreport'])


  }

  homeBtn() {

    this._router.navigate(['landingPage'])


  }
  MetricReportold(reportName:any) {
    localStorage.setItem('reportName', reportName)


  }



}
