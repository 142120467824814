import { formatNumber } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { DataService } from '../data.service';

@Component({
  selector: 'app-edit-med-regime',
  templateUrl: './edit-med-regime.component.html',
  styleUrls: ['./edit-med-regime.component.css']
})
export class EditMedRegimeComponent implements OnInit {
  isEditPatient = false
  isOTHclaim: boolean = false
  isOTHss: boolean = false
  isOTHds: boolean = false
  isOTHsnf: boolean = false
  isOTHehr: boolean = false
  isOTHpat: boolean = false
  payload: any
  loadedData: any
  dataSource = ''
  step = 0
  step2 = 0
  patientTakingDropdown = ['Yes', 'No']
  frequencyData: any = []
  medData: any
  formData : any = []
  // ["Oral", "Sublingual", "Intranasally", "Topically", "IM", "SubQ", "Rectally", "IV", "NG-Tube", "J-peg"]
  routeData : any = []
  sourceListInpatient: any = []
  sourceListSnf:any = []
  sourceListMemberReported : any = []
  routeDict : any = {'ehr':false,'snf':false,'claim':false,'ds':false,'ss':false,'pat':false}
  enableForm : any = {'ehr':false,'snf':false,'claim':false,'ds':false,'ss':false,'pat':false}
  enableFormVal : any = {'ehr':'ehrForm','snf':'snfForm','ds':'dsForm','ss':'ssForm','pat':'patForm','claim':'claimForm'}
  
  Dose : any
  Route : any
  Units : any
  Frequency : any
  PRN : any
  Instructions : any
  WrittenDate : any
  lastFilledDate : any
  Strength : any
  Quantity : any
  DaysOfSupply : any
  prescriptionEnddate : any
  RefilNbr : any
  TotalRefill : any
  RxNbr : any
  Diagnosis : any
  DiagnosisDescription : any
  DiagnosisEffectFrom : any
  prescriberName : any
  prescriberNPI : any
  prescriberSpeciality : any
  PharmacyStore : any
  PharmacyContact : any
  freqVal : any
  NDC : any
  Form : any
  RouteVal : any

  medform: FormGroup = new FormGroup({
    encounterId: new FormControl(),
    prescriptionId: new FormControl(),
    recordId: new FormControl(),
    medication: new FormControl(),
    drugCategory: new FormControl(),
    gpi: new FormControl(),
    claimNDC: new FormControl(),
    claimFrequency: new FormControl(),
    claimlastFilledDate: new FormControl(),
    claimDaysOfSupply: new FormControl(),
    claimInstructions: new FormControl(),
    claimUnits: new FormControl(),
    claimStrength: new FormControl(),
    claimRefilNbr: new FormControl(),
    claimRxNbr: new FormControl(),
    claimTotalRefill: new FormControl(),
    claimWrittenDate: new FormControl(),
    claimprescriptionEnddate: new FormControl(),
    claimstatus: new FormControl(),
    ssNDC: new FormControl(),
    ssFrequency: new FormControl(),
    freqValss: new FormControl(),
    sslastFilledDate: new FormControl(),
    ssDaysOfSupply: new FormControl(),
    ssInstructions: new FormControl(),
    ssUnits: new FormControl(),
    ssStrength: new FormControl(),
    ssRefilNbr: new FormControl(),
    ssRxNbr: new FormControl(),
    ssTotalRefill: new FormControl(),
    ssWrittenDate: new FormControl(),
    ssprescriptionEnddate: new FormControl(),
    ssMessageId: new FormControl(),
    dsNDC: new FormControl(),
    dsFrequency: new FormControl(),
    freqValds: new FormControl(),
    freqValclaim: new FormControl(),
    dslastFilledDate: new FormControl(),
    dsDaysOfSupply: new FormControl(),
    dsInstructions: new FormControl(),
    dsUnits: new FormControl(),
    dsStrength: new FormControl(),
    dsRefilNbr: new FormControl(),
    dsRxNbr: new FormControl(),
    dsTotalRefill: new FormControl(),
    dsWrittenDate: new FormControl(),
    dsprescriptionEnddate: new FormControl(),
    snfNDC: new FormControl(),
    snfFrequency: new FormControl(),
    freqValsnf: new FormControl(),
    snflastFilledDate: new FormControl(),
    snfDaysOfSupply: new FormControl(),
    snfInstructions: new FormControl(),
    snfUnits: new FormControl(),
    snfStrength: new FormControl(),
    snfRefilNbr: new FormControl(),
    snfRxNbr: new FormControl(),
    snfTotalRefill: new FormControl(),
    snfWrittenDate: new FormControl(),
    snfprescriptionEnddate: new FormControl(),
    ehrNDC: new FormControl(),
    ehrFrequency: new FormControl(),
    freqValehr: new FormControl(),
    freqValPat: new FormControl(),
    ehrlastFilledDate: new FormControl(),
    ehrDaysOfSupply: new FormControl(),
    ehrInstructions: new FormControl(),
    ehrUnits: new FormControl(),
    ehrStrength: new FormControl(),
    ehrRefilNbr: new FormControl(),
    ehrRxNbr: new FormControl(),
    ehrTotalRefill: new FormControl(),
    ehrWrittenDate: new FormControl(),
    ehrprescriptionEnddate: new FormControl(),
    patNDC: new FormControl(),
    patFrequency: new FormControl(),
    patlastFilledDate: new FormControl(),
    patDaysOfSupply: new FormControl(),
    patInstructions: new FormControl(),
    patUnits: new FormControl(),
    patStrength: new FormControl(),
    patRefilNbr: new FormControl(),
    patRxNbr: new FormControl(),
    patTotalRefill: new FormControl(),
    patWrittenDate: new FormControl(),
    patprescriptionEnddate: new FormControl(),
    patComments: new FormControl(),
    patTakingMed: new FormControl(),
    techCheck: new FormControl(),
    rphCheck: new FormControl(),
    finalSource: new FormControl(),
    medDecision: new FormControl(),
    reviewBy: new FormControl(),
    reviewDatettime: new FormControl(),
    reconcilledBy: new FormControl(),
    reconcilledDatetime: new FormControl(),
    providerComments: new FormControl(),
    claimprescriberName: new FormControl(),
    claimprescriberNPI: new FormControl(),
    claimprescriberSpeciality: new FormControl(),
    claimPharmacyName: new FormControl(),
    claimPharmacyStore: new FormControl(),
    claimPharmacyContact: new FormControl(),
    ssprescriberName: new FormControl(),
    ssprescriberNPI: new FormControl(),
    ssprescriberSpeciality: new FormControl(),
    ssPharmacyName: new FormControl(),
    ssPharmacyStore: new FormControl(),
    ssPharmacyContact: new FormControl(),
    dsprescriberName: new FormControl(),
    dsprescriberNPI: new FormControl(),
    dsprescriberSpeciality: new FormControl(),
    dsPharmacyName: new FormControl(),
    dsPharmacyStore: new FormControl(),
    dsPharmacyContact: new FormControl(),
    snfprescriberName: new FormControl(),
    snfprescriberNPI: new FormControl(),
    snfprescriberSpeciality: new FormControl(),
    snfPharmacyName: new FormControl(),
    snfPharmacyStore: new FormControl(),
    snfPharmacyContact: new FormControl(),
    ehrprescriberName: new FormControl(),
    ehrprescriberNPI: new FormControl(),
    ehrprescriberSpeciality: new FormControl(),
    ehrPharmacyName: new FormControl(),
    ehrPharmacyStore: new FormControl(),
    ehrPharmacyContact: new FormControl(),
    patprescriberName: new FormControl(),
    patprescriberNPI: new FormControl(),
    patprescriberSpeciality: new FormControl(),
    patPharmacyName: new FormControl(),
    patPharmacyStore: new FormControl(),
    patPharmacyContact: new FormControl(),
    claimDiagnosis: new FormControl(),
    claimDiagnosisDescription: new FormControl(),
    snfDiagnosis: new FormControl(),
    snfDiagnosisDescription: new FormControl(),
    snfDnosisEffectFrom: new FormControl(),
    dsDiagnosis: new FormControl(),
    dsDiagnosisDescription: new FormControl(),
    dsDnosisEffectFrom: new FormControl(),
    claimDnosisEffectFrom: new FormControl(),
    ssDiagnosis: new FormControl(),
    ssDiagnosisDescription: new FormControl(),
    ssDnosisEffectFrom: new FormControl(),
    ehrDiagnosis: new FormControl(),
    ehrDiagnosisDescription: new FormControl(),
    ehrDnosisEffectFrom: new FormControl(),
    patDiagnosis: new FormControl(),
    patDiagnosisDescription: new FormControl(),
    patDnosisEffectFrom: new FormControl(),
    ssQuantity: new FormControl(),
    dsQuantity: new FormControl(),
    snfQuantity: new FormControl(),
    patQuantity: new FormControl(),
    claimQuantity: new FormControl(),
    ehrQuantity: new FormControl(),
    ssPRN: new FormControl(),
    dsPRN: new FormControl(),
    snfPRN: new FormControl(),
    patPRN: new FormControl(),
    claimPRN: new FormControl(),
    ehrPRN: new FormControl(),
    ssDose: new FormControl(),
    dsDose: new FormControl(),
    snfDose: new FormControl(),
    patDose: new FormControl(),
    claimDose: new FormControl(),
    ehrDose: new FormControl(),
    ssRoute: new FormControl(),
    dsRoute: new FormControl(),
    snfRoute: new FormControl(),
    patRoute: new FormControl(),
    claimRoute: new FormControl(),
    ehrRoute: new FormControl(),    
    claimIsManual: new FormControl(),
    dsIsManual:new FormControl(),
    ehrIsManual:new FormControl(),
    ssIsManual:new FormControl(),
    snfIsManual: new FormControl(),
    patIsManual:  new FormControl(),
    reviewerComments:new FormControl(),
    ssPickedUpdt: new FormControl(),
    ehrForm: new FormControl(),
    claimForm: new FormControl(),
    snfForm: new FormControl(),
    ssForm: new FormControl(),
    dsForm : new FormControl(),
    patForm : new FormControl(),
    ehrRouteDesc : new FormControl(),
    claimRouteDesc : new FormControl(),
    snfRouteDesc : new FormControl(),
    ssRouteDesc : new FormControl(),
    dsRouteDesc : new FormControl(),
    patRouteDesc : new FormControl()

  })


  constructor(public dialogRef: MatDialogRef<EditMedRegimeComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dataservice: DataService, private toastr: ToastrService
  ) {

    console.log(data)
    this.medData = data.medication
  }

  ngOnInit(): void {
    this.getFrequencyData()
    this.getDropDownData()
    this.getRegimeData(this.medData)
    this.medform.controls['medication'].disable();
    this.medform.controls['freqValclaim'].disable();
    this.medform.controls['freqValss'].disable();
    this.medform.controls['freqValds'].disable();
    this.medform.controls['freqValehr'].disable();
    this.medform.controls['freqValsnf'].disable();
    this.medform.controls['freqValPat'].disable();

   
  

  }
  
  getFrequencyData() {

    this.dataservice.getIsmpData('F').subscribe(data => {

      this.frequencyData = data
      this.frequencyData.forEach(element => {
        if (element.abbr != 'OTH') {
          element['abbrMeaning'] = element['abbr'] + ' - ' + element['meaning']
        }
        else { element['abbrMeaning'] = element['abbr'] }
      })



    }, error => {

    })


  }

  getDropDownData(){
    this.dataservice.getdropdown("FORM").subscribe(data => {
      this.formData = data["values"]

    }, error => {


    })
    this.dataservice.getdropdown("ROUTE").subscribe(data => {
      this.routeData = data["values"]

    }, error => {


    })
  }
  ndcCheck(data){
    var NDC = this.getNDC(data)
    console.log(NDC)
    if((!data.claimNDC) && (data.claimDaysOfSupply||data.claimDiagnosis||data.claimForm||data.claimDose||data.claimFrequency||data.claimInstructions||data.claimQuantity||data.claimUnits||data.claimStrength) )
    {
      data.claimNDC = NDC
      console.log('1')
    }
    if((!data.ssNDC) && (data.ssDaysOfSupply||data.ssDiagnosis||data.ssForm||data.ssDose||data.ssFrequency||data.ssInstructions||data.ssQuantity||data.ssUnits||data.ssStrength) )
      {
        data.ssNDC = NDC
        console.log('2')
      }
    if((!data.dsNDC) && (data.dsDaysOfSupply||data.dsDiagnosis||data.dsForm||data.dsDose||data.dsFrequency||data.dsInstructions||data.dsQuantity||data.dsUnits||data.dsStrength) )
      {
        data.dsNDC = NDC
        console.log('3')
      }  
    if((!data.ehrNDC) && (data.ehrDaysOfSupply||data.ehrDiagnosis||data.ehrForm||data.ehrDose||data.ehrFrequency||data.ehrInstructions||data.ehrQuantity||data.ehrUnits||data.ehrStrength) )
      {
        data.ehrNDC = NDC
        console.log('4')
      }
    if((!data.snfNDC) && (data.snfDaysOfSupply||data.snfDiagnosis||data.snfForm||data.snfDose||data.snfFrequency||data.snfInstructions||data.snfQuantity||data.snfUnits||data.snfStrength) )
      {
        data.snfNDC = NDC
        console.log('5')
      }
    if((!data.patNDC) && (data.patDaysOfSupply||data.patDiagnosis||data.patForm||data.patDose||data.patFrequency||data.patInstructions||data.patQuantity||data.patUnits||data.patStrength) )
      {
        data.patNDC = NDC
        console.log('6')
      }
  }
  getNDC(data){
    if(data.claimNDC){
      return data.claimNDC
    }
    else if(data.ehrNDC){
      return data.ehrNDC
    }
    else if(data.ssNDC){
      return data.ssNDC
    }
    else if(data.dsNDC){
      return data.dsNDC
    }    
    else if(data.patNDC){
      return data.patNDC
    }
    else if(data.snfNDC){
      return data.snfNDC
    }
  }
  getRegimeData(data) {
        
    let input = {
      "gpi": data.gpi,
      "id": localStorage.getItem("encounterId")
    }
    let matchclaim = false
    let matchss = false
    let matchds = false
    let matchehr = false
    let matchsnf = false
    let matchpat = false
    this.dataservice.getDrugRegimeMed(input).subscribe(data => {
      this.ndcCheck(data[0])
      console.log('loaded data',data)
      this.loadedData = data
      console.log(data[0]['claimNDC'],data[0]['ehrNDC'],data[0]['ssNDC'],data[0]['dsNDC'],data[0]['snfNDC'],data[0]['NDC'])
      if(data[0]['claimNDC'].length > 0){
        this.sourceListInpatient.push('Claims')
        this.sourceListSnf.push('Claims')
        this.sourceListMemberReported.push('Claims')
      }
      if(data[0]['ehrNDC'].length > 0){
        this.sourceListInpatient.push('EHR')
        this.sourceListSnf.push('EHR')
        this.sourceListMemberReported.push('EHR')
      }
      if(data[0]['ssNDC'].length > 0){
        this.sourceListInpatient.push('POS')
        this.sourceListSnf.push('POS')
        this.sourceListMemberReported.push('POS')
      }
      if(data[0]['dsNDC'].length > 0){        
        this.sourceListSnf.push('Inpatient')
        this.sourceListMemberReported.push('Inpatient')
      }
      if(data[0]['snfNDC'].length > 0 ){
        this.sourceListInpatient.push('SNF')        
        this.sourceListMemberReported.push('SNF')
      }
      if(data[0]['patNDC'].length > 0){
        this.sourceListInpatient.push('Member Reported')
        this.sourceListSnf.push('Member Reported')        
      }      
      this.medform.patchValue({
        encounterId: data[0]["encounterId"],
        prescriptionId: data[0]["prescriptionId"],
        recordId: data[0]["recordId"],
        mbrRefNo: data[0]["mbrRefNo"],
        mrn: data[0]["mrn"],
        firstName: data[0]["firstName"],
        lastName: data[0]["lastName"],
        dob: data[0]["dob"],
        gender: data[0]["gender"],
        medication: data[0]["medication"],
        drugCategory: data[0]["drugCategory"],
        gpi: data[0]["gpi"],
        claimNDC: data[0]["claimNDC"],
        claimFrequency: data[0]["claimFrequency"] != '' ? data[0]["claimFrequency"] : null,
        freqValclaim: data[0]["claimFrequency"] != '' ? data[0]["claimFrequency"] : null,
        claimlastFilledDate: data[0]["claimlastFilledDate"],
        claimDaysOfSupply: data[0]["claimDaysOfSupply"],
        claimInstructions: data[0]["claimInstructions"],
        claimUnits: data[0]["claimUnits"],
        claimStrength: data[0]["claimStrength"],
        claimRefilNbr: data[0]["claimRefilNbr"],
        claimRxNbr: data[0]["claimRxNbr"],
        claimTotalRefill: data[0]["claimTotalRefill"],
        claimWrittenDate: data[0]["claimWrittenDate"],
        claimprescriptionEnddate: data[0]["claimprescriptionEnddate"],
        claimstatus: data[0]["claimstatus"],
        ssNDC: data[0]["ssNDC"],
        ssFrequency: data[0]["ssFrequency"] != '' ? data[0]["ssFrequency"] : null,
        freqValss: data[0]["ssFrequency"] != '' ? data[0]["ssFrequency"] : null,
        sslastFilledDate: data[0]["sslastFilledDate"],
        ssDaysOfSupply: data[0]["ssDaysOfSupply"],
        ssInstructions: data[0]["ssInstructions"],
        ssUnits: data[0]["ssUnits"],
        ssStrength: data[0]["ssStrength"],
        ssRefilNbr: data[0]["ssRefilNbr"],
        ssRxNbr: data[0]["ssRxNbr"],
        ssTotalRefill: data[0]["ssTotalRefill"],
        ssWrittenDate: data[0]["ssWrittenDate"],
        ssprescriptionEnddate: data[0]["ssprescriptionEnddate"],
        ssstatus: data[0]["ssstatus"],
        ssMessageId: data[0]["ssMessageId"],
        dsNDC: data[0]["dsNDC"],
        dsFrequency: data[0]["dsFrequency"] != '' ? data[0]["dsFrequency"] : null,
        freqValds: data[0]["dsFrequency"] != '' ? data[0]["dsFrequency"] : null,
        dslastFilledDate: data[0]["dslastFilledDate"],
        dsDaysOfSupply: data[0]["dsDaysOfSupply"],
        dsInstructions: data[0]["dsInstructions"],
        dsUnits: data[0]["dsUnits"],
        dsStrength: data[0]["dsStrength"],
        dsRefilNbr: data[0]["dsRefilNbr"],
        dsRxNbr: data[0]["dsRxNbr"],
        dsTotalRefill: data[0]["dsTotalRefill"],
        dsWrittenDate: data[0]["dsWrittenDate"],
        dsprescriptionEnddate: data[0]["dsprescriptionEnddate"],
        dsstatus: data[0]["dsstatus"],
        snfNDC: data[0]["snfNDC"],
        snfFrequency: data[0]["snfFrequency"] != '' ? data[0]["snfFrequency"] : null,
        freqValsnf: data[0]["snfFrequency"] != '' ? data[0]["snfFrequency"] : null,
        snflastFilledDate: data[0]["snflastFilledDate"],
        snfDaysOfSupply: data[0]["snfDaysOfSupply"],
        snfInstructions: data[0]["snfInstructions"],
        snfUnits: data[0]["snfUnits"],
        snfStrength: data[0]["snfStrength"],
        snfRefilNbr: data[0]["snfRefilNbr"],
        snfRxNbr: data[0]["snfRxNbr"],
        snfTotalRefill: data[0]["snfTotalRefill"],
        snfWrittenDate: data[0]["snfWrittenDate"],
        snfprescriptionEnddate: data[0]["snfprescriptionEnddate"],
        snfstatus: data[0]["snfstatus"],
        ehrNDC: data[0]["ehrNDC"],
        ehrFrequency: data[0]["ehrFrequency"] != '' ? data[0]["ehrFrequency"] : null,
        freqValehr: data[0]["ehrFrequency"] != '' ? data[0]["ehrFrequency"] : null,
        ehrlastFilledDate: data[0]["ehrlastFilledDate"],
        ehrDaysOfSupply: data[0]["ehrDaysOfSupply"],
        ehrInstructions: data[0]["ehrInstructions"],
        ehrUnits: data[0]["ehrUnits"],
        ehrStrength: data[0]["ehrStrength"],
        ehrRefilNbr: data[0]["ehrRefilNbr"],
        ehrRxNbr: data[0]["ehrRxNbr"],
        ehrTotalRefill: data[0]["ehrTotalRefill"],
        ehrWrittenDate: data[0]["ehrWrittenDate"],
        ehrprescriptionEnddate: data[0]["ehrprescriptionEnddate"],
        ehrstatus: data[0]["ehrstatus"],
        patNDC: data[0]["patNDC"],
        patFrequency: data[0]["patFrequency"] != '' ? data[0]["patFrequency"] : null,
        freqValPat: data[0]["patFrequency"] != '' ? data[0]["patFrequency"] : null,
        patlastFilledDate: data[0]["patlastFilledDate"],
        patDaysOfSupply: data[0]["patDaysOfSupply"],
        patInstructions: data[0]["patInstructions"],
        patUnits: data[0]["patUnits"],
        patStrength: data[0]["patStrength"],
        patRefilNbr: data[0]["patRefilNbr"],
        patRxNbr: data[0]["patRxNbr"],
        patTotalRefill: data[0]["patTotalRefill"],
        patWrittenDate: data[0]["patWrittenDate"],
        patprescriptionEnddate: data[0]["patprescriptionEnddate"],
        patstatus: data[0]["patstatus"],
        patComments: data[0]["patComments"],
        patTakingMed: data[0]["patTakingMed"],
        techCheck: data[0]["techCheck"],
        rphCheck: data[0]["rphCheck"],
        finalSource: data[0]["finalSource"],
        reviewBy: data[0]["reviewBy"],
        reviewerComments: data[0]["reviewerComments"],
        reviewDatettime: data[0]["reviewDatettime"],
        reconcilledBy: data[0]["reconcilledBy"],
        reconcilledDatetime: data[0]["reconcilledDatetime"],
        providerComments: data[0]["providerComments"],
        actionplan: data[0]["actionplan"],
        claimprescriberName: data[0]["claimprescriberName"],
        claimprescriberNPI: data[0]["claimprescriberNPI"],
        claimprescriberSpeciality: data[0]["claimprescriberSpeciali"],
        claimPharmacyName: data[0]["claimPharmacyName"],
        claimPharmacyNPI: data[0]["claimPharmacyNPI"],
        claimPharmacyContact: data[0]["claimPharmacyContact"],
        ssprescriberName: data[0]["ssprescriberName"],
        ssprescriberNPI: data[0]["ssprescriberNPI"],
        ssprescriberSpeciality: data[0]["ssprescriberSpeciality"],
        ssPharmacyName: data[0]["ssPharmacyName"],
        ssPharmacyNPI: data[0]["ssPharmacyNPI"],
        ssPharmacyContact: data[0]["ssPharmacyContact"],
        dsprescriberName: data[0]["dsprescriberName"],
        dsprescriberNPI: data[0]["dsprescriberNPI"],
        dsprescriberSpeciality: data[0]["dsprescriberSpeciality"],
        dsPharmacyName: data[0]["dsPharmacyName"],
        dsPharmacyNPI: data[0]["dsPharmacyNPI"],
        dsPharmacyContact: data[0]["dsPharmacyContact"],
        snfprescriberName: data[0]["snfprescriberName"],
        snfprescriberNPI: data[0]["snfprescriberNPI"],
        snfprescriberSpeciality: data[0]["snfprescriberSpeciality"],
        snfPharmacyName: data[0]["snfPharmacyName"],
        snfPharmacyNPI: data[0]["snfPharmacyNPI"],
        snfPharmacyContact: data[0]["snfPharmacyContact"],
        ehrprescriberName: data[0]["ehrprescriberName"],
        ehrprescriberNPI: data[0]["ehrprescriberNPI"],
        ehrprescriberSpeciality: data[0]["ehrprescriberSpeciality"],
        ehrPharmacyName: data[0]["ehrPharmacyName"],
        ehrPharmacyNPI: data[0]["ehrPharmacyNPI"],
        ehrPharmacyContact: data[0]["ehrPharmacyContact"],
        patprescriberName: data[0]["patprescriberName"],
        patprescriberNPI: data[0]["patprescriberNPI"],
        patprescriberSpeciality: data[0]["patprescriberSpeciality"],
        patPharmacyName: data[0]["patPharmacyName"],
        patPharmacyNPI: data[0]["patPharmacyNPI"],
        patPharmacyContact: data[0]["patPharmacyContact"],
        patDiagnosis: data[0]["patDiagnosis"],
        patDiagnosisDesc: data[0]["patDiagnosisDesc"],
        patDiagnosisEffectDt: data[0]["patDiagnosisEffectDt"],
        patForm: this.onChangeForm(data[0]["patForm"],'pat','Y'),
        patDose: data[0]["patDose"],
        ehrDiagnosis: data[0]["ehrDiagnosis"],
        ehrDiagnosisDesc: data[0]["ehrDiagnosisDesc"],
        ehrDiagnosisEffectDt: data[0]["ehrDiagnosisEffectDt"],
        ehrForm: this.onChangeForm(data[0]["ehrForm"],'ehr','Y'),
        ehrDose: data[0]["ehrDose"],
        dsDiagnosis: data[0]["dsDiagnosis"],
        dsDiagnosisDesc: data[0]["dsDiagnosisDesc"],
        dsDiagnosisEffectDt: data[0]["dsDiagnosisEffectDt"],
        dsForm: this.onChangeForm(data[0]["dsForm"],'ds','Y'),
        dsDose: data[0]["dsDose"],
        snfDiagnosis: data[0]["snfDiagnosis"],
        snfDiagnosisDesc: data[0]["snfDiagnosisDesc"],
        snfDiagnosisEffectDt: data[0]["snfDiagnosisEffectDt"],
        snfForm: this.onChangeForm(data[0]["snfForm"],'snf','Y'),
        snfDose: data[0]["snfDose"],
        ssDiagnosis: data[0]["ssDiagnosis"],
        ssDiagnosisDesc: data[0]["ssDiagnosisDesc"],
        ssDiagnosisEffectDt: data[0]["ssDiagnosisEffectDt"],
        ssForm: this.onChangeForm(data[0]["ssForm"],'ss','Y'),
        ssDose: data[0]["ssDose"],
        claimDiagnosis: data[0]["claimDiagnosis"],
        claimDiagnosisDesc: data[0]["claimDiagnosisDesc"],
        claimDiagnosisEffectDt: data[0]["claimDiagnosisEffectDt"],
        claimForm: this.onChangeForm(data[0]["claimForm"],'claim','Y'),
        claimDose: data[0]["claimDose"],
        medDecision: data[0]["medDecision"],
        actionPlan: data[0]["actionPlan"],
        ssQuantity: data[0]["ssQuantity"],
        dsQuantity: data[0]["dsQuantity"],
        snfQuantity: data[0]["snfQuantity"],
        patQuantity: data[0]["patQuantity"],
        claimQuantity: data[0]["claimQuantity"],
        ehrQuantity: data[0]["ehrQuantity"],
        ssPRN: data[0]["ssPRN"],
        dsPRN: data[0]["dsPRN"],
        snfPRN: data[0]["snfPRN"],
        patPRN: data[0]["patPRN"],
        claimPRN: data[0]["claimPRN"],
        ehrPRN: data[0]["ehrPRN"],
        claimIsManual: data[0]['claimIsManual'],
        patIsManual: data[0]['patIsManual'],
        ehrIsManual: data[0]['ehrIsManual'],
        snfIsManual: data[0]['snfIsManual'],
        dsIsManual: data[0]['dsIsManual'],
        ssIsManual: data[0]['ssIsManual'],
        ssPickedUpdt: data[0]['ssPickedUpdt'],
      

      })
      this.checkRoute(data[0]["dsRoute"],'ds')
      this.checkRoute(data[0]["ssRoute"],'ss')
      this.checkRoute(data[0]["snfRoute"],'snf')
      this.checkRoute(data[0]["patRoute"],'pat')
      this.checkRoute(data[0]["claimRoute"],'claim')
      this.checkRoute(data[0]["ehrRoute"],'ehr')

      this.frequencyData.forEach(element => {
        if (data[0]['claimFrequency'] == element['meaning']) { matchclaim = true }
        if (data[0]['ssFrequency'] == element['meaning']) { matchss = true }
        if (data[0]['dsFrequency'] == element['meaning']) { matchds = true }
        if (data[0]['ehrFrequency'] == element['meaning']) { matchehr = true }
        if (data[0]['snfFrequency'] == element['meaning']) { matchsnf = true }
        if (data[0]['patFrequency'] == element['meaning']) { matchpat = true }
      })
     

      if (!matchclaim) {
        this.medform.patchValue({ claimFrequency: "" });
        this.medform.controls['freqValclaim'].enable();
        this.isOTHclaim = true;
      }
      if (!matchss) {
        this.medform.patchValue({ ssFrequency: "" })
        this.medform.controls['freqValss'].enable();
        this.isOTHss = true;
      }
      if (!matchds) {
        this.medform.patchValue({ dsFrequency: "" })
        this.medform.controls['freqValds'].enable();
        this.isOTHds = true;
      }
      if (!matchehr) {
        this.medform.patchValue({ ehrFrequency: "" })
        this.medform.controls['freqValehr'].enable();
        this.isOTHehr = true;
      }
      if (!matchsnf) {
        this.medform.patchValue({ snfFrequency: "" })
        this.medform.controls['freqValsnf'].enable();
        this.isOTHsnf = true;
      }
      if (!matchpat) {
        this.medform.patchValue({ patFrequency: "" })
        this.medform.controls['freqValPat'].enable();
        this.isOTHpat = true;
      }

    }, error => {

    })

  }
  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
  }
  prevStep() {
    this.step--;
  }
  setStep2(index: number) {
    this.step2 = index;
  }
  nextStep2() {
    this.step2++;
  }
  prevStep2() {
    this.step2--;
  }

  saveDrugRegime() {
debugger
    this.payload = this.medform.value
    console.log(this.medform.value['claimFrequency'])
    console.log(this.isOTHclaim, this.isOTHds, this.isOTHehr, this.isOTHpat, this.isOTHsnf, this.isOTHss)
    if (this.isOTHclaim) {
      let val = this.medform.getRawValue().freqValclaim;
      this.payload['claimFrequency'] = val
    }
    if (this.isOTHss) {
      let val = this.medform.getRawValue().freqValss;
      this.payload['ssFrequency'] = val
    }
    if (this.isOTHds) {
      let val = this.medform.getRawValue().freqValds;
      this.payload['dsFrequency'] = val      
    }
    if (this.isOTHehr) {
      let val = this.medform.getRawValue().freqValehr;
      this.payload['ehrFrequency'] = val      
    }
    if (this.isOTHpat) {
      let val = this.medform.getRawValue().freqValPat;
      this.payload['patFrequency'] = val      
    }
    if (this.isOTHsnf) {
      let val = this.medform.getRawValue().freqValsnf;
      this.payload['snfFrequency'] = val
    }
    this.payload.ehrRoute = (this.routeDict['ehr']? this.payload.ehrRouteDesc : this.payload.ehrRoute? this.payload.ehrRoute.split('-')[1].trim():null)
    this.payload.snfRoute = (this.routeDict['snf']? this.payload.snfRouteDesc : this.payload.snfRoute? this.payload.snfRoute.split('-')[1].trim():null)
    this.payload.ssRoute = (this.routeDict['ss']? this.payload.ssRouteDesc : this.payload.ssRoute? this.payload.ssRoute.split('-')[1].trim():null)
    this.payload.dsRoute = (this.routeDict['ds']? this.payload.dsRouteDesc : this.payload.dsRoute? this.payload.dsRoute.split('-')[1].trim():null)
    this.payload.claimRoute = (this.routeDict['claim']? this.payload.claimRouteDesc : this.payload.claimRoute? this.payload.claimRoute.split('-')[1].trim():null)
    this.payload.patRoute = (this.routeDict['pat']? this.payload.patRouteDesc : this.payload.patRoute? this.payload.patRoute.split('-')[1].trim():null)

    this.payload.claimInstructions = this.readableInstructions(this.payload.claimInstructions,this.payload.claimPRN)
    this.payload.ehrInstructions = this.readableInstructions(this.payload.ehrInstructions,this.payload.ehrPRN)
    this.payload.ssInstructions = this.readableInstructions(this.payload.ssInstructions,this.payload.ssPRN)
    this.payload.dsInstructions = this.readableInstructions(this.payload.dsInstructions,this.payload.dsPRN)
    this.payload.patInstructions = this.readableInstructions(this.payload.patInstructions,this.payload.patPRN)
    this.payload.snfInstructions = this.readableInstructions(this.payload.snfInstructions,this.payload.snfPRN)

    delete this.payload['freqValclaim'];
    delete this.payload['freqValss'];
    delete this.payload['freqValds'];
    delete this.payload['freqValehr'];
    delete this.payload['freqValPat'];
    delete this.payload['freqValsnf'];
    console.log('saved',this.medform.value,this.medform.getRawValue(),this.payload)

    this.dataservice.updateDrugRegime(this.payload).subscribe(data => {
      this.toastr.success('Drug Edited Successfully','',{
        timeOut: 1500,
                })
    }, error => {
      this.toastr.error('Some Error Occured','',{
        timeOut: 1500,
                })
    })
  }
  checkRoute(data,src){
    console.log('asdasdasxas',data)
    var matchRoute = false
    if (this.routeData.includes(data)){
      matchRoute = true
    }
    else if(!this.routeData.includes(data)){
        this.routeData.forEach(ele => {
        if(data === ele.split('-')[0].trim() ){
          data = ele        
          matchRoute = true
          console.log(data,src) 
        }
        else if(ele != 'Other' && data === ele.split('-')[1].trim()){
          data = ele        
          matchRoute = true
        }
        })}

    if(matchRoute == true){
      if (src === 'ehr'){
        this.medform.patchValue({ehrRouteDesc:this.camelCase(data),ehrRoute:data})
        this.medform.controls.ehrRouteDesc.disable()
      }
      else if(src === 'claim'){
        this.medform.patchValue({claimRouteDesc:this.camelCase(data),claimRoute:data})
        this.medform.controls.claimRouteDesc.disable()
      }
      else if(src === 'snf'){
        this.medform.patchValue({snfRouteDesc:this.camelCase(data),snfRoute:data})
       this.medform.controls.snfRouteDesc.disable()
      }
      else if(src === 'ss'){
        this.medform.patchValue({ssRouteDesc:this.camelCase(data),ssRoute:data})
       this.medform.controls.ssRouteDesc.disable()
      }
      else if(src === 'ds'){
        this.medform.patchValue({dsRouteDesc:this.camelCase(data),dsRoute:data})
       this.medform.controls.dsRouteDesc.disable()
      }
      else if(src === 'pat'){
        this.medform.patchValue({patRouteDesc:this.camelCase(data),patRoute:data})
        this.medform.controls.patRouteDesc.disable()
      }
    }     
    else if(data.length>0){
      if (src === 'ehr'){        
        this.medform.controls.ehrRouteDesc.enable()    
        this.medform.patchValue({ehrRouteDesc:data,ehrRoute:'Other'})
        this.routeDict['ehr'] = true
      }
      else if(src === 'claim'){        
        this.medform.controls.claimRouteDesc.enable()   
        this.medform.patchValue({claimRouteDesc:data,claimRoute:'Other'})
        this.routeDict['claim'] = true     
      }
      else if(src === 'snf'){
        
        this.medform.controls.snfRouteDesc.enable()    
        this.medform.patchValue({snfRouteDesc:data,snfRoute:'Other'})
        this.routeDict['snf'] = true    
      }
      else if(src === 'ss'){
        
        this.medform.controls.ssRouteDesc.enable()
        this.medform.patchValue({ssRouteDesc:data,ssRoute:"Other"})
        this.routeDict['ss'] = true
      }
      else if(src === 'ds'){        
        this.medform.controls.dsRouteDesc.enable()
        this.medform.patchValue({dsRouteDesc:data,dsRoute:"Other"})
        this.routeDict['ds'] = true
      }
      else if(src === 'pat'){        
        this.medform.controls.patRouteDesc.enable() 
        this.medform.patchValue({patRouteDesc:data,patRoute:"Other"})
        this.routeDict['pat'] = true       
      }
    }
  }
    
   
  readableInstructions(str,PNR){
    if (PNR === 'Yes'){
      console.log('sdv',str.trim())
      if ((str.length === 0) || (str.trim() === 'as needed') || (str.trim() === 'as needed for')){
        return "as needed"
      }
      else if (str.includes('as needed for')){
        return str
      }
      else if (str.length > 0 && !str.includes('as needed')){
        return "as needed for " + str
      }
      
    }
    else{
      return str
    }
  }

  onChangeForm(event,src,from)
  {
    if(event === "Other"){
      this.enableForm[src] = true
      if (src == 'ehr'){
      this.medform.patchValue({ehrForm:''})
      }
      else if (src == 'claim'){
        this.medform.patchValue({claimForm:''})
      }
      else if (src == 'ds'){
        this.medform.patchValue({dsForm:''})
        }
      else if (src == 'ss'){
        this.medform.patchValue({ssForm:''})
        }
      else if (src == 'pat'){
        this.medform.patchValue({patForm:''})
        }
      else if (src == 'snf'){
          this.medform.patchValue({snfForm:''})
          }
    }
    else if(from === 'N'){
      this.enableForm[src] = false
    }
    else if (from === 'Y' && (this.formData.includes(event) || event === null || event === undefined || event.length === 0)){
      // console.log('1' , event)
      this.enableForm[src] = false
      return event
    }
    else{ 
      // console.log('2',event)
      this.enableForm[src] = true
      return event
    }
  }  
  onChangeRoute(event,src) {
    if(event === "Other"){
      console.log('1')
      if (src === 'ehr'){
        this.medform.patchValue({ehrRouteDesc:''})
        this.medform.controls.ehrRouteDesc.enable()
        this.routeDict['ehr'] = true
      }
      else if(src === 'claim'){
        this.medform.patchValue({claimRouteDesc:''})
        this.medform.controls.claimRouteDesc.enable()
        this.routeDict['claim'] = true
      }
      else if(src === 'snf'){
        this.medform.patchValue({snfRouteDesc:''})
        this.medform.controls.snfRouteDesc.enable()
        this.routeDict['snf'] = true
      }
      else if(src === 'ss'){
        this.medform.patchValue({ssRouteDesc:''})
        this.medform.controls.ssRouteDesc.enable()
        this.routeDict['ss'] = true
      }
      else if(src === 'ds'){
        this.medform.patchValue({dsRouteDesc:''})
        this.medform.controls.dsRouteDesc.enable()
        this.routeDict['ds'] = true
      }
      else if(src === 'pat'){
        this.medform.patchValue({patRouteDesc:''})
        this.medform.controls.patRouteDesc.enable()
        this.routeDict['pat'] = true
      }
    }
    else{
      let val = event
      console.log('2')
      if (src === 'ehr'){
        this.medform.patchValue({ehrRouteDesc:this.camelCase(val)})
       this.medform.controls.ehrRouteDesc.disable()
      }
      else if(src === 'claim'){
        this.medform.patchValue({claimRouteDesc:this.camelCase(val)})
       this.medform.controls.claimRouteDesc.disable()
      }
      else if(src === 'snf'){
        this.medform.patchValue({snfRouteDesc:this.camelCase(val)})
       this.medform.controls.snfRouteDesc.disable()
      }
      else if(src === 'ss'){
        this.medform.patchValue({ssRouteDesc:this.camelCase(val)})
       this.medform.controls.ssRouteDesc.disable()
      }
      else if(src === 'ds'){
        this.medform.patchValue({dsRouteDesc:this.camelCase(val)})
       this.medform.controls.dsRouteDesc.disable()
      }
      else if(src === 'pat'){
        this.medform.patchValue({patRouteDesc:this.camelCase(val)})
       this.medform.controls.patRouteDesc.disable()
      }
    }

  }
  camelCase(str){    
    if(!this.routeData.includes(str)){
      console.log(str)      
    }
    if (str.includes('-')){
      str = str.split('-')[1].trim()
      }
    str = str.split(' ').map((s) => `${s[0].toUpperCase()}${s.slice(1)}`);
    return str.join(' ')
  }

  onChangeFrequency(data, type) {
    let val = data;
    console.log(val)
    if (type == 'claim') {
      if (data == '') {
        this.medform.patchValue({ freqValclaim: "" })
        this.medform.controls['freqValclaim'].enable();
        this.isOTHclaim = true
      }
      else {
        this.isOTHclaim = false
        let splitString = val
          .split(' ')
          .map((s) => `${s[0].toUpperCase()}${s.slice(1)}`);
        splitString = splitString.join(' ')
        this.medform.patchValue({ freqValclaim: splitString })
        this.medform.controls['freqValclaim'].disable();
      }
    }
    else if (type == 'ss') {
      if (data == '') {
        this.medform.patchValue({ freqValss: "" })
        this.medform.controls['freqValss'].enable();
        this.isOTHss = true
      }
      else {
        this.isOTHss = false
        let splitString = val
          .split(' ')
          .map((s) => `${s[0].toUpperCase()}${s.slice(1)}`);
        splitString = splitString.join(' ')
        this.medform.patchValue({ freqValss: splitString })
        this.medform.controls['freqValss'].disable();

        //this.medform.patchValue({freqVal: ""})
      }
    }
    else if (type == 'ds') {
      if (data == '') {
        this.medform.patchValue({ freqValds: "" })
        this.medform.controls['freqValds'].enable();
        this.isOTHds = true
      }
      else {
        this.isOTHds = false
        let splitString = val
          .split(' ')
          .map((s) => `${s[0].toUpperCase()}${s.slice(1)}`);
        splitString = splitString.join(' ')
        this.medform.patchValue({ freqValds: splitString })
        this.medform.controls['freqValds'].disable();

        //this.medform.patchValue({freqVal: ""})
      }
    }
    else if (type == 'snf') {
      if (data == '') {
        this.medform.patchValue({ freqValsnf: "" })
        this.medform.controls['freqValsnf'].enable();
        this.isOTHsnf = true
      }
      else {
        this.isOTHsnf = false
        let splitString = val
          .split(' ')
          .map((s) => `${s[0].toUpperCase()}${s.slice(1)}`);
        splitString = splitString.join(' ')
        this.medform.patchValue({ freqValsnf: splitString })
        this.medform.controls['freqValsnf'].disable();

        //this.medform.patchValue({freqVal: ""})
      }
    }
    else if (type == 'ehr') {
      if (data == '') {
        this.medform.patchValue({ freqValehr: "" })
        this.medform.controls['freqValehr'].enable();
        this.isOTHehr = true
      }
      else {
        this.isOTHehr = false
        let splitString = val
          .split(' ')
          .map((s) => `${s[0].toUpperCase()}${s.slice(1)}`);
        splitString = splitString.join(' ')
        this.medform.patchValue({ freqValehr: splitString })
        this.medform.controls['freqValehr'].disable();

        //this.medform.patchValue({freqVal: ""})
      }
    }
    else if (type == 'pat') {
      if (data == '') {
        this.medform.patchValue({ freqValPat: "" })
        this.medform.controls['freqValPat'].enable();
        this.isOTHpat = true
      }
      else {
        this.isOTHpat = false
        let splitString = val
          .split(' ')
          .map((s) => `${s[0].toUpperCase()}${s.slice(1)}`);
        splitString = splitString.join(' ')
        this.medform.patchValue({ freqValPat: splitString })
        this.medform.controls['freqValPat'].disable();

       
      }
    }
   
  }

  onChangeSource(event,source)
  {
    debugger    
    this.payload = this.medform.getRawValue()            
    if (event == 'EHR'){
      this.Dose = this.payload.ehrDose;
      this.Route = this.payload.ehrRoute
      this.Units = this.payload.ehrUnits
      this.Frequency = this.payload.ehrFrequency
      this.PRN = this.payload.ehrPRN
      this.Instructions = this.payload.ehrInstructions
      this.WrittenDate = this.payload.ehrWrittenDate
      this.lastFilledDate = this.payload.ehrlastFilledDate
      this.Strength = this.payload.ehrStrength
      this.Quantity = this.payload.ehrQuantity
      this.DaysOfSupply = this.payload.ehrDaysOfSupply
      this.prescriptionEnddate = this.payload.ehrprescriptionEnddate
      this.RefilNbr = this.payload.ehrRefilNbr
      this.TotalRefill = this.payload.ehrTotalRefill
      this.RxNbr = this.payload.ehrRxNbr
      this.Diagnosis = this.payload.ehrDiagnosis
      this.DiagnosisDescription = this.payload.ehrDiagnosisDescription
      this.DiagnosisEffectFrom = this.payload.ehrDiagnosisEffectDt
      this.prescriberName = this.payload.ehrprescriberName
      this.prescriberNPI = this.payload.ehrprescriberNPI
      this.prescriberSpeciality = this.payload.ehrprescriberSpeciality
      this.PharmacyStore = this.payload.ehrPharmacyStore
      this.PharmacyContact = this.payload.ehrPharmacyContact
      this.freqVal = this.payload.freqValehr
      this.NDC = this.payload.ehrNDC
      this.Form = this.payload.ehrForm
      this.RouteVal = this.payload.ehrRouteDesc
    }
    else if(event == 'Claims'){
      this.Dose = this.payload.claimDose;
      this.Route = this.payload.claimRoute
      this.Units = this.payload.claimUnits
      this.Frequency = this.payload.claimFrequency
      this.PRN = this.payload.claimPRN
      this.Instructions = this.payload.claimInstructions
      this.WrittenDate = this.payload.claimWrittenDate
      this.lastFilledDate = this.payload.claimlastFilledDate
      this.Strength = this.payload.claimStrength
      this.Quantity = this.payload.claimQuantity
      this.DaysOfSupply = this.payload.claimDaysOfSupply
      this.prescriptionEnddate = this.payload.claimprescriptionEnddate
      this.RefilNbr = this.payload.claimRefilNbr
      this.TotalRefill = this.payload.claimTotalRefill
      this.RxNbr = this.payload.claimRxNbr
      this.Diagnosis = this.payload.claimDiagnosis
      this.DiagnosisDescription = this.payload.claimDiagnosisDescription
      this.DiagnosisEffectFrom = this.payload.claimDiagnosisEffectDt
      this.prescriberName = this.payload.claimprescriberName
      this.prescriberNPI = this.payload.claimprescriberNPI
      this.prescriberSpeciality = this.payload.claimprescriberSpeciality
      this.PharmacyStore = this.payload.claimPharmacyStore
      this.PharmacyContact = this.payload.claimPharmacyContact
      this.freqVal = this.payload.freqValclaim
      this.NDC = this.payload.claimNDC      
      this.Form = this.payload.claimForm
      this.RouteVal = this.payload.claimRouteDesc
    }
    else if(event == 'POS'){
      console.log('Hi,notification',this.medform.getRawValue().ssInstructions)
      this.Dose = this.payload.ssDose;
      this.Route = this.payload.ssRoute
      this.Units = this.payload.ssUnits
      this.Frequency = this.payload.ssFrequency
      this.PRN = this.payload.ssPRN
      this.Instructions = this.payload.ssInstructions
      this.WrittenDate = this.payload.ssWrittenDate
      this.lastFilledDate = this.payload.sslastFilledDate
      this.Strength = this.payload.ssStrength
      this.Quantity = this.payload.ssQuantity
      this.DaysOfSupply = this.payload.ssDaysOfSupply
      this.prescriptionEnddate = this.payload.ssprescriptionEnddate
      this.RefilNbr = this.payload.ssRefilNbr
      this.TotalRefill = this.payload.ssTotalRefill
      this.RxNbr = this.payload.ssRxNbr
      this.Diagnosis = this.payload.ssDiagnosis
      this.DiagnosisDescription = this.payload.ssDiagnosisDescription
      this.DiagnosisEffectFrom = this.payload.ssDiagnosisEffectDt
      this.prescriberName = this.payload.ssprescriberName
      this.prescriberNPI = this.payload.ssprescriberNPI
      this.prescriberSpeciality = this.payload.ssprescriberSpeciality
      this.PharmacyStore = this.payload.ssPharmacyStore
      this.PharmacyContact = this.payload.ssPharmacyContact   
      this.freqVal = this.payload.freqValss
      this.NDC = this.payload.ssNDC            
      this.Form = this.payload.ssForm
      this.RouteVal = this.payload.ssRouteDesc  
    }
    else if(event == 'Inpatient'){      
      this.Dose = this.payload.dsDose;
      this.Route = this.payload.dsRoute
      this.Units = this.payload.dsUnits
      this.Frequency = this.payload.dsFrequency
      this.PRN = this.payload.dsPRN
      this.Instructions = this.payload.dsInstructions
      this.WrittenDate = this.payload.dsWrittenDate
      this.lastFilledDate = this.payload.dslastFilledDate
      this.Strength = this.payload.dsStrength
      this.Quantity = this.payload.dsQuantity
      this.DaysOfSupply = this.payload.dsDaysOfSupply
      this.prescriptionEnddate = this.payload.dsprescriptionEnddate
      this.RefilNbr = this.payload.dsRefilNbr
      this.TotalRefill = this.payload.dsTotalRefill
      this.RxNbr = this.payload.dsRxNbr
      this.Diagnosis = this.payload.dsDiagnosis
      this.DiagnosisDescription = this.payload.dsDiagnosisDescription
      this.DiagnosisEffectFrom = this.payload.dsDiagnosisEffectDt
      this.prescriberName = this.payload.dsprescriberName
      this.prescriberNPI = this.payload.dsprescriberNPI
      this.prescriberSpeciality = this.payload.dsprescriberSpeciality
      this.PharmacyStore = this.payload.dsPharmacyStore
      this.PharmacyContact = this.payload.dsPharmacyContact   
      this.freqVal = this.payload.freqValds
      this.NDC = this.payload.dsNDC      
      this.Form = this.payload.dsForm
      this.RouteVal = this.payload.dsRouteDesc

    }
    else if(event == 'SNF'){
      this.Dose = this.payload.snfDose;
      this.Route = this.payload.snfRoute
      this.Units = this.payload.snfUnits
      this.Frequency = this.payload.snfFrequency
      this.PRN = this.payload.snfPRN
      this.Instructions = this.payload.snfInstructions
      this.WrittenDate = this.payload.snfWrittenDate
      this.lastFilledDate = this.payload.snflastFilledDate
      this.Strength = this.payload.snfStrength
      this.Quantity = this.payload.snfQuantity
      this.DaysOfSupply = this.payload.snfDaysOfSupply
      this.prescriptionEnddate = this.payload.snfprescriptionEnddate
      this.RefilNbr = this.payload.snfRefilNbr
      this.TotalRefill = this.payload.snfTotalRefill
      this.RxNbr = this.payload.snfRxNbr
      this.Diagnosis = this.payload.snfDiagnosis
      this.DiagnosisDescription = this.payload.snfDiagnosisDescription
      this.DiagnosisEffectFrom = this.payload.snfDiagnosisEffectDt
      this.prescriberName = this.payload.snfprescriberName
      this.prescriberNPI = this.payload.snfprescriberNPI
      this.prescriberSpeciality = this.payload.snfprescriberSpeciality
      this.PharmacyStore = this.payload.snfPharmacyStore
      this.PharmacyContact = this.payload.snfPharmacyContact
      this.freqVal = this.payload.freqValsnf
      this.NDC = this.payload.snfNDC   
      this.Form = this.payload.snfForm
      this.RouteVal = this.payload.snfRouteDesc   
    }
    else if(event == 'Member Reported'){
      this.Dose = this.payload.patDose
      this.Route = this.payload.patRoute
      this.Units = this.payload.patUnits
      this.Frequency = this.payload.patFrequency
      this.PRN = this.payload.patPRN
      this.Instructions = this.payload.patInstructions
      this.WrittenDate = this.payload.patWrittenDate
      this.lastFilledDate = this.payload.patlastFilledDate
      this.Strength = this.payload.patStrength
      this.Quantity = this.payload.patQuantity
      this.DaysOfSupply = this.payload.patDaysOfSupply
      this.prescriptionEnddate = this.payload.patprescriptionEnddate
      this.RefilNbr = this.payload.patRefilNbr
      this.TotalRefill = this.payload.patTotalRefill
      this.RxNbr = this.payload.patRxNbr
      this.Diagnosis = this.payload.patDiagnosis
      this.DiagnosisDescription = this.payload.patDiagnosisDescription
      this.DiagnosisEffectFrom = this.payload.patDiagnosisEffectDt
      this.prescriberName = this.payload.patprescriberName
      this.prescriberNPI = this.payload.patprescriberNPI
      this.prescriberSpeciality = this.payload.patprescriberSpeciality
      this.PharmacyStore = this.payload.patPharmacyStore
      this.PharmacyContact = this.payload.patPharmacyContact
      this.freqVal = this.payload.freqValpat
      this.NDC = this.payload.patNDC     
      this.Form = this.payload.patForm
      this.RouteVal = this.payload.patRouteDesc
    }
    if(source == 'Inpatient'){  
      console.log('Hi,in patient')
      this.medform.patchValue({
        dsDose : this.Dose,
        dsRoute : this.Route,
        dsUnits : this.Units,
        dsFrequency : this.Frequency,
        dsPRN : this.PRN,
        dsInstructions : this.Instructions,
        dsWrittenDate : this.WrittenDate,
        dslastFilledDate : this.lastFilledDate,
        dsStrength : this.Strength,
        dsQuantity : this.Quantity,
        dsDaysOfSupply : this.DaysOfSupply,
        dsprescriptionEnddate : this.prescriptionEnddate,
        dsRefilNbr : this.RefilNbr,
        dsTotalRefill : this.TotalRefill,
        dsRxNbr : this.RxNbr,
        dsDiagnosis : this.Diagnosis,
        dsDiagnosisDescription : this.DiagnosisDescription,
        dsDiagnosisEffectFrom : this.DiagnosisEffectFrom,
        dsprescriberName : this.prescriberName,
        dsprescriberNPI : this.prescriberNPI,
        dsprescriberSpeciality : this.prescriberSpeciality,     
        dsPharmacyStore : this.PharmacyStore,
        dsPharmacyContact : this.PharmacyContact,
        freqValds : this.freqVal,
        dsNDC : this.NDC,
        dsRouteDesc : this.RouteVal,
        dsForm: this.onChangeForm(this.Form,'ds','Y')
      })        
      if(this.Route === 'Other'){
        this.routeDict['ds'] = true
        this.medform.controls.dsRouteDesc.enable()
      }
      else{
        this.medform.controls.dsRouteDesc.disable()
      }
      if(!this.sourceListSnf.includes('Inpatient'))
      {
        this.sourceListSnf.push('Inpatient')
      }      
      if(!this.sourceListMemberReported.includes('Inpatient'))
      {
        this.sourceListMemberReported.push('Inpatient')
      }                         
    }
    else if (source == 'SNF'){
      this.medform.patchValue({
        snfDose : this.Dose,
        snfRoute : this.Route,
        snfUnits : this.Units,
        snfFrequency : this.Frequency,
        snfPRN : this.PRN,
        snfInstructions : this.Instructions,
        snfWrittenDate : this.WrittenDate,
        snflastFilledDate : this.lastFilledDate,
        snfStrength : this.Strength,
        snfQuantity : this.Quantity,
        snfDaysOfSupply : this.DaysOfSupply,
        snfprescriptionEnddate : this.prescriptionEnddate,
        snfRefilNbr : this.RefilNbr,
        snfTotalRefill : this.TotalRefill,
        snfRxNbr : this.RxNbr,
        snfDiagnosis : this.Diagnosis,
        snfDiagnosisDescription : this.DiagnosisDescription,
        snfDiagnosisEffectFrom : this.DiagnosisEffectFrom,
        snfprescriberName : this.prescriberName,
        snfprescriberNPI : this.prescriberNPI,
        snfprescriberSpeciality : this.prescriberSpeciality,     
        snfPharmacyStore : this.PharmacyStore,
        snfPharmacyContact : this.PharmacyContact,
        freqValsnf : this.freqVal,
        snfNDC : this.NDC ,
        snfRouteDesc : this.RouteVal,
        snfForm: this.onChangeForm(this.Form,'snf','Y')
      })         
      if(this.Route === 'Other'){
        this.routeDict['snf'] = true
        this.medform.controls.snfRouteDesc.enable()
      }
      else{
        this.medform.controls.snfRouteDesc.disable()
      }
      if(!this.sourceListInpatient.includes('SNF'))
      {
        this.sourceListInpatient.push('SNF')
      }      
      if(!this.sourceListMemberReported.includes('SNF'))
      {
        this.sourceListMemberReported.push('SNF')
      }      
    }
    else if (source == 'Member Reported'){
      this.medform.patchValue({
        patDose : this.Dose,
        patRoute : this.Route,
        patUnits : this.Units,
        patFrequency : this.Frequency,
        patPRN : this.PRN,
        patInstructions : this.Instructions,
        patWrittenDate : this.WrittenDate,
        patlastFilledDate : this.lastFilledDate,
        patStrength : this.Strength,
        patQuantity : this.Quantity,
        patDaysOfSupply : this.DaysOfSupply,
        patprescriptionEnddate : this.prescriptionEnddate,
        patRefilNbr : this.RefilNbr,
        patTotalRefill : this.TotalRefill,
        patRxNbr : this.RxNbr,
        patDiagnosis : this.Diagnosis,
        patDiagnosisDescription : this.DiagnosisDescription,
        patDiagnosisEffectFrom : this.DiagnosisEffectFrom,
        patprescriberName : this.prescriberName,
        patprescriberNPI : this.prescriberNPI,
        patprescriberSpeciality : this.prescriberSpeciality,     
        patPharmacyStore : this.PharmacyStore,
        patPharmacyContact : this.PharmacyContact,
        freqValPat : this.freqVal,
        patNDC : this.NDC,
        patRouteDesc : this.RouteVal,
        patForm: this.onChangeForm(this.Form,'pat','Y')
      })            
      if(this.Route === 'Other'){
        this.routeDict['pat'] = true
        this.medform.controls.patRouteDesc.enable()
      }
      else{
        this.medform.controls.patRouteDesc.disable()
      }
      if(!this.sourceListInpatient.includes('Member Reported'))
      {
        this.sourceListInpatient.push('Member Reported')
      }      
      if(!this.sourceListSnf.includes('Member Reported'))
      {
        this.sourceListSnf.push('Member Reported')
      }      
    }   
  }
}
