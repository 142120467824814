<mat-toolbar color="primary" class="mainHeader">
  <mat-toolbar-row class="mainNav mat-elevation-z3" fxLayoutAlign="space-between center">
    <a class="logoFont" style="cursor: pointer;">
      <!-- <a class="logoFont" style="cursor: pointer;" [_routerLink]="['/home']" (click)="logoHomeLink()"> -->

      <img height="20px" src="../../../assets/images/optum.png" [routerLink]="['/workqueue']">
      <span class="pageName">| Medication Review and Reconciliation</span>
      <span class="region-name">{{cdoRegionName}} </span>
</a>
<div fxLayout="row" fxLayoutGap="15px" fxLayoutAlign="center center end">
      <span class="usernameWrap" fxLayoutAlign="space-between center">
        <mat-icon class="material-icons-outlined">account_box</mat-icon> {{name}}
      </span>
      <span class="locationicon">
        <button mat-button [matMenuTriggerFor]="beforeMenu">
          <mat-icon class="material-symbols-outlined" title="Switch Location">location_on</mat-icon>
        </button>
        <mat-menu #beforeMenu="matMenu" xPosition="before">
          <div *ngFor="let cdo of cdos" [value]="cdo.region">
            <button *ngIf="cdo.region!=currcdo" mat-menu-item
              (click)="onregionChange(cdo.region)">{{cdo.regionName}}</button>
            <button *ngIf="cdo.region==currcdo" mat-menu-item class="active-rgn"
              (click)="onregionChange(cdo.region)">{{cdo.regionName}}</button>
          </div>
        </mat-menu>
      </span>


      <button mat-flat-button color="warn" (click)="logout()">
        <mat-icon class="material-icons-outlined">logout</mat-icon> Log Out
      </button>
    </div>
  </mat-toolbar-row>
</mat-toolbar>