import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { IdleTimeoutService } from '../idle-timeout.service';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent implements OnInit {
  inputText : String = "";

  constructor(@Inject(MAT_DIALOG_DATA) public data: { message: string ,isComments: String}, private dialogRef: MatDialogRef<ConfirmationDialogComponent>,private toastr: ToastrService, private idleService:IdleTimeoutService) {
    this.idleService.idleTimeout.subscribe(() => {
      this.dialogRef.close()
    })
  }

  confirm(): void {
    this.dialogRef.close(true); 
  }

  cancel(): void {
    this.dialogRef.close(false); 
  }

  doAction():void{
    if(this.inputText.length==0){      
      this.toastr.error("Enter The Reason",'',{
        timeOut: 1500,
                })
    }
    else{
    this.dialogRef.close(this.inputText)
    }
  }

  

  ngOnInit(): void {
  }

}
