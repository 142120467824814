import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { DataService } from '../data.service';

@Component({
  selector: 'app-technicians',
  templateUrl: './technicians.component.html',
  styleUrls: ['./technicians.component.css']
})
export class TechniciansComponent implements OnInit {

  pharmTechSelect: any = false;
  pharmTechMem: any;
  mrnCountData: any;
  selectionData: any;
  successMsg: boolean = false;
  failMsg: boolean = false;
  memberdata: any;
  totalCount: any;

  constructor(private pharmTechMembers: DataService, private _snackBar: MatSnackBar, public dialogRef: MatDialogRef<TechniciansComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public toastr: ToastrService) {
    this.memberdata = data.dataMrnList;
    this.totalCount = data.totalCount
    console.log("mrnCountData>>>>", this.memberdata,data)



  }

  ngOnInit(): void {

    this.pharmTechMembers.getTechnicians().subscribe(data => {
      console.log(data)
      this.pharmTechMem = data
    }, (error: Error) => {
      console.log(error);

    })

  }

  cancelAssign() {

  }

  confirmPharmTech() {

    let mrnsarray = []
    const newArray = this.memberdata;
    let member = newArray.toString().replace("[", "(").replace("]", ")")
    console.log(this.pharmTechSelect)
    let data = {
      "mrnList": "(" + member + ")",
      "assignedTo": this.pharmTechSelect
    }
    console.log(data);

    this.pharmTechMembers.assignmember(data).subscribe((data: any) => {
      this.selectionData = data;
      this.toastr.success("Encounter Assigned successfully",'',{
        timeOut: 1500,
                });

      window.location.reload();
      console.log("SelectionData>>>", this.selectionData)
    }, (error: Error) => {
      console.log(error);
      this.failMsg = true;

    }

    );
  }
}
