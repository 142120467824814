import { Component, OnInit,Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { IdleTimeoutService } from '../idle-timeout.service';

@Component({
  selector: 'app-show-encounter-log',
  templateUrl: './show-encounter-log.component.html',
  styleUrls: ['./show-encounter-log.component.css']
})
export class ShowEncounterLogComponent implements OnInit {
  dataSource : any;
  encounterData:any;
  displayedColumns: string[] = ['remarks', 'previousStatus', 'currentStatus','changedBy', 'createdDate']
  constructor(@Inject(MAT_DIALOG_DATA) public data: any , private dialogRef: MatDialogRef<ShowEncounterLogComponent>, private idleService:IdleTimeoutService) { 
    this.encounterData = data.sort((a, b) => b.createdDate.getTime() - a.createdDate.getTime());
    this.idleService.idleTimeout.subscribe(() => {      
      dialogRef.close()
    })
  }

  ngOnInit(): void {
    console.log(this.encounterData)
    this.dataSource = new MatTableDataSource(this.encounterData)
  }
}
