export const ERROR_CONSTANT = {
   ERROR_LOGIN_REGION : 'Please Select Appropriate Region',
    ERROR_LOGIN_USERNAME : 'Please Select Username',
    Error_LOGIN_PASSWORD : 'Please Select Password',
    Error_AUNAUTHORIZED_USER : 'Unauthorized  User, Please Request Access',
    Error_INVALID_CREDENTIALS : 'Incorrect Username or Password Please Try Again',
    Error_SERVER: 'Something Went Wrong, Please Try Again or Contact Support',

    
 
 };