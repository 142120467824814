import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './/app-routing.module';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HeaderComponent } from './shared/components/header/header.component';
import { FooterComponent } from './shared/components/footer/footer.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthService } from './services/auth.service';
import { AgGridModule } from 'ag-grid-angular';
import { AddNewPatientComponent } from './add-new-patient/add-new-patient.component';
import { AddNewMedComponent } from './add-new-med/add-new-med.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { TechniciansComponent } from './technicians/technicians.component';
import { MembersearchComponent } from './membersearch/membersearch.component';
import { ReportgridComponent } from './reportgrid/reportgrid.component';
import { OureachreportviewComponent } from './oureachreportview/oureachreportview.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AssignmemberComponent } from './assignmember/assignmember.component';
import { ToastrModule } from 'ngx-toastr';
import { MemberdetailsComponent } from './memberdetails/memberdetails.component';
import { AddEncounterComponent } from './add-encounter/add-encounter.component';
import { EncounterQueueComponent } from './encounter-queue/encounter-queue.component';
import { EditMedRegimeComponent } from './edit-med-regime/edit-med-regime.component';
import { ProviderDataComponent } from './provider-data/provider-data.component';
import { ConfirmDeleteComponent } from './confirm-delete/confirm-delete.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { InterventionComponent } from './intervention/intervention.component';
import { ReuTableComponent } from './widgets/reu-table/reu-table.component';
import { InterventionManagmentComponent } from './intervention-managment/intervention-managment.component';
import { ShowEncounterLogComponent } from './show-encounter-log/show-encounter-log.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TimeoutDialogMemberDetailsComponent } from './timeout-dialog-member-details/timeout-dialog-member-details.component';
import { PhoneformatPipe } from './pipes/phoneformat.pipe';




@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    FooterComponent,
    AddNewPatientComponent,
    AddNewMedComponent,
    LandingpageComponent,
    TechniciansComponent,
    MembersearchComponent,
    ReportgridComponent,
    OureachreportviewComponent,
    AssignmemberComponent,
    MemberdetailsComponent,
    AddEncounterComponent,
    EncounterQueueComponent,
    EditMedRegimeComponent,
    ProviderDataComponent,
    ConfirmDeleteComponent,    
    ConfirmationDialogComponent, 
    InterventionComponent,
    ReuTableComponent,
    InterventionManagmentComponent,
    ShowEncounterLogComponent,
    TimeoutDialogMemberDetailsComponent,
    PhoneformatPipe,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    FlexLayoutModule,
    AgGridModule,
    HttpClientModule,
    MatPaginatorModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    NgIdleKeepaliveModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-top-center',
      preventDuplicates: true
    })
  ],
  providers: [AuthGuardService, AuthService],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
