<h2 mat-dialog-title>
    <div class="dialog-title row">
        <div class="col-md-12 d-flex align-items-center">
            <div class="col-md-11 d-flex align-items-center">
                <mat-icon color="warn" class="material-icons-outlined mar-15">delete</mat-icon> <h2>Delete</h2>
            </div>
            <div class="col-md-1 text-end">
                <button mat-icon-button color="primary" mat-dialog-close class="close-button">
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    </div>
</h2>

<mat-dialog-content class="mat-typography">
    <div class="row col-md-12 align-items-start">
        <h2 style="text-align: center !important; padding: 0; font-size: 14px;">Are you sure you want to delete <strong>{{data.drugName}}?</strong></h2>
        <textarea  *ngIf="isNeedReason" matInput placeholder="Reason for Deletion" [(ngModel)]="deleteReason" 
            style="border: solid 1px #dadada; outline: none !important; height: 63px; text-align: left; padding: 5px; margin: 10px 0 0 0; width: 99%;"> {{deleteReason}}
        </textarea>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button style="margin: 0 10px 0 0;" mat-stroked-button color="default" mat-dialog-close>No </button>
    <button mat-flat-button color="primary" (click)="npsConfirm()">Yes </button>
</mat-dialog-actions>




