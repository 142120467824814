<app-header></app-header>

<div class="col-12">

    <div class="col-12 breadcrumb-section">
        <div class="col-lg-12 row p-2">
            <div class="col-6 text-left actionsbtn-section" style="padding-left: 20px;">
                <button mat-mini-fab color="primary" class="mat-elevation-z0 backIcon" [routerLink]="['/landingPage']">
                    <mat-icon class="material-icons-outlined">home</mat-icon>
                </button>
            </div>
            <div *ngIf="showaddencounter" class="col-6 text-end mt-2"><button (click)="gotoaddencounter()"
                    mat-flat-button color="primary">Add Encounter</button></div>
            <div *ngIf="!showaddencounter" class="col-6 text-end mt-2"><button mat-raised-button disabled>Add
                    Encounter</button></div>
        </div>
    </div>

</div>

<div class="container-fluid addmember mt-2">

    <div class="row mb-3">
        <div class="container">

            <form [formGroup]="addPatientForm">

                <mat-accordion  >

                    <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
                        <mat-expansion-panel-header
                            style="color: #fff; background: #002657; border-radius:  4px 4px 0 0; height: 36px;">
                            <mat-panel-title style="color: #fff; font-size: 14px;">Patient Details</mat-panel-title>
                        </mat-expansion-panel-header>
                        <div class="row addmembersection">

                            <div class="row">
                                <div class="col-lg-2 col-md-3 col-sm-4" style="display: none;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Mbr Ref No</mat-label>
                                        <input matInput type="text" placeholder="MBR Ref No" name="mbrRefNo"
                                            formControlName="mbrRefNo" type="text">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>First Name <span style="color: red;">*</span></mat-label>
                                        <input matInput type="text" placeholder="First Name" name="firstName"
                                            formControlName="firstName"
                                            onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Last Name <span style="color: red;"> *</span></mat-label>
                                        <input matInput type="text" placeholder="Last Name" name="lastName"
                                            formControlName="lastName"
                                            onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4 dobinput">
                                    <mat-form-field appearance="outline">
                                        <mat-label>DOB <span style="color: red;"> *</span></mat-label>
                                        <input matInput type="date" placeholder="DOB" name="dob" formControlName="dob">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Gender <span style="color: red;"> *</span></mat-label>
                                        <mat-select name="gender" formControlName="gender">
                                            <mat-option value="M">Male</mat-option>
                                            <mat-option value="F">Female</mat-option>
                                            <mat-option value="NA">NA</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-1 col-md-3 col-sm-4 text-start mt-1" style="width: 64px;">
                                    <button color="warn" type="submit" mat-raised-button (click)="patientSearch()">
                                        <mat-icon matSuffix class="searchIcon" matSuffix
                                            type="submit">search</mat-icon></button>
                                </div>
                                <div class="col-md-2" style="margin: 10px 0 0 10px;">
                                    <mat-hint style="color: red;" *ngIf="isEditPatient==false">Search to check if member
                                        is already present </mat-hint>
                                    <mat-hint style="color: red;" *ngIf="isEditPatient==false">FirstName, LastName, DOB
                                        and Gender Required</mat-hint>
                                    <mat-hint style="color: red;" *ngIf="isEditPatient==true">Member is already present,
                                        to create encounter click on Add Encounter</mat-hint>


                                </div>
                            </div>

                            <!-- <h6>Member ids</h6> -->
                            <div class="row">
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Facets Member ID</mat-label>
                                        <input matInput type="text" placeholder="Member UD" name="memberId"
                                            formControlName="memberId">
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>MRN</mat-label>
                                        <input matInput type="text" placeholder="MRN" name="mrn" formControlName="mrn"
                                            type="number">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label> Ext Member ID</mat-label>
                                        <input matInput type="text" placeholder="Ext Member ID" name="extMemberId"
                                            formControlName="extMemberId" type="text">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Medicare HIC</mat-label>
                                        <input matInput type="text" placeholder="Medicare HIC" name="medicareHIC"
                                            formControlName="medicareHIC" type="text">
                                    </mat-form-field>
                                </div>
                            </div>

                            <!-- <h6>Address Details</h6> -->
                            <div class="row">
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Address Line 1</mat-label>
                                        <input matInput type="text" placeholder="Address Line1" name="addressline1"
                                            formControlName="addressline1">
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Address Line 2</mat-label>
                                        <input matInput type="text" placeholder="Address Line2" name="addressline2"
                                            formControlName="addressline2">
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>City <span style="color: red;"> *</span> </mat-label>
                                        <input matInput type="text" placeholder="City" name="city" formControlName="city">
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>State <span style="color: red;"> *</span> </mat-label>
                                        <input matInput type="text" placeholder="State" name="state" formControlName="state">

                                    </mat-form-field>
                                </div>

                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Zip Code <span style="color: red;"> *</span></mat-label>
                                        <input matInput type="text" placeholder="Min length 5 is Required"
                                            name="zipCode" formControlName="zipCode" type="text" minlength="5">
                                        <!-- <mat-hint style="color: red;">Min length 5 is required</mat-hint> -->
                                    </mat-form-field>
                                </div>

                            </div>


                            <!-- <h6>Communication Details</h6> -->

                            <div class="row">
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Preferred Language</mat-label>
                                        <input matInput type="text" placeholder="Preferred Language"
                                            name="preferredLanguage" formControlName="preferredLanguage"
                                            onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'>
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Email</mat-label>
                                        <input matInput type="text" placeholder="Email" name="email"
                                            formControlName="email">
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Primary Contact Number</mat-label>
                                        <input matInput type="text" placeholder="Primary Contact Number"
                                            name="primaryContactNumber" formControlName="primaryContactNumber">
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Alternate Contact Number</mat-label>
                                        <input matInput type="text" placeholder="Alternate Contact Number"
                                            name="alternateContactNumber" formControlName="alternateContactNumber">
                                    </mat-form-field>
                                </div>
                            </div>


                            <!-- <h6>Plan Details</h6> -->
                            <div class="row">
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Plan Name </mat-label>
                                        <input matInput type="text" placeholder="planName" name="planName"
                                            formControlName="planName">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Plan Eligibility Status </mat-label>
                                        <mat-select name="planElStatus" formControlName="planElStatus">
                                            <mat-option value="ACTI">A-ACTIVE</mat-option>
                                            <mat-option value="CBRA">C-COBRA</mat-option>
                                            <mat-option value="DECE">D-DECEASED</mat-option>
                                            <mat-option value="PREM">p-PREMEMBER</mat-option>
                                            <mat-option value="RETI">R-RETIRED</mat-option>
                                            <mat-option value="SRVS">S-SURVIVING SPOUSE</mat-option>
                                            <mat-option value="TERM">T-TERMINATED</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Payor</mat-label>
                                        <input matInput type="text" placeholder="Payor" name="payor"
                                            formControlName="payor"
                                            onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'>
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4" style="display: none;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>RAF</mat-label>
                                        <input matInput type="text" placeholder="RAF Score" name="rafScore"
                                            formControlName="rafScore">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>LOB</mat-label>
                                        <input matInput type="text" placeholder="LOB" name="lob" formControlName="lob">
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <mat-divider></mat-divider>

                <mat-accordion > 
                    <mat-expansion-panel [expanded]="true" (opened)="setStep2(1)" hideToggle class="mt-2">
                        <mat-expansion-panel-header
                            style="color: #fff; background: #002657; border-radius:  4px 4px 0 0;">
                            <mat-panel-title style="color: #fff; font-size: 14px;">PCP Details</mat-panel-title>
                        </mat-expansion-panel-header>


                        <div class="row addmembersection">

                            <div class="row">
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>PCP Name </mat-label>
                                        <input matInput type="text" placeholder="PCP Name" name="pcp"
                                            formControlName="pcp">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Specialty </mat-label>
                                        <input matInput type="text" placeholder="PCP Speciality" name="pcpSpeciality"
                                            formControlName="pcpSpeciality">
                                    </mat-form-field>

                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>NPI </mat-label>
                                        <input matInput type="text" placeholder="NPI" name="pcpNPI"
                                            formControlName="pcpNPI">
                                    </mat-form-field>

                                </div>
                            </div>


                            <div class="row">
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>PCP Address Line 1</mat-label>
                                        <input matInput type="text" placeholder="PCP Address Line1"
                                            name="pcpAddreslines1" formControlName="pcpAddressline1">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>PCP Address Line 2</mat-label>
                                        <input matInput type="text" placeholder="PCP Address Line2"
                                            name="pcpAddressline2" formControlName="pcpAddressline2">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>PCP Zip Code</mat-label>
                                        <input matInput type="text" placeholder="PCP Zipcode" name="pcpZipCode"
                                            formControlName="pcpZipCode">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>PCP State </mat-label>
                                        <input matInput type="text" placeholder="pcpState" name="pcpState" formControlName="pcpState">

                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>PCP City </mat-label>
                                         <input matInput type="text" placeholder="PCP City" name="pcpCity"
                                        formControlName="pcpCity">
                                    </mat-form-field>
                                </div>
                            </div>

                            <!-- <h6>Communication Details</h6> -->

                            <div class="row">
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>PCP Phone </mat-label>
                                        <input matInput type="text" placeholder="PCP Phone" name="pcpPhone"
                                            formControlName="pcpPhone">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Fax </mat-label>
                                        <input matInput type="text" placeholder="PCP Fax" name="pcpFax"
                                            formControlName="pcpFax">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4" style="display: none;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Active</mat-label>
                                        <input matInput type="text" placeholder="isActive" name="isActive"
                                            formControlName="isActive">
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-2 col-md-3 col-sm-4" style="display: none;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Inactive Reason</mat-label>
                                        <input matInput type="text" placeholder="InactiveReason" name="inactiveReason"
                                            formControlName="inactiveReason">
                                    </mat-form-field>
                                </div>

                                <div class="col-lg-2 col-md-3 col-sm-4" style="display: none;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>present INPM</mat-label>
                                        <input matInput type="text" placeholder="Present INPM" name="presentINPM"
                                            formControlName="presentINPM">
                                    </mat-form-field>
                                </div>
                                <div class="col-lg-2 col-md-3 col-sm-4" style="display: none;">
                                    <mat-form-field appearance="outline">
                                        <mat-label>System Flag</mat-label>
                                        <input matInput type="text" placeholder="Present INPM" name="systemFlag"
                                            formControlName="systemFlag">
                                    </mat-form-field>
                                </div>
                            </div>



                        </div>

                    </mat-expansion-panel>
                </mat-accordion>


                <mat-divider></mat-divider>



                <mat-card class="mt-2">

                    <div class="row align-items-start">
                        <div class="col-md-12">
                            <button color="basic" type="submit" mat-stroked-button mat-dialog-close
                                style="margin-right: 10px;" [routerLink]="['/landingPage']"
                                matTooltip="Close">Close</button>
                            <button color="primary" type="submit" mat-raised-button (click)="addnewpatient()"
                                *ngIf="isEditPatient==false" style="margin-right: 10px;" matTooltip="Save">Save</button>
                            <button color="primary" type="submit" mat-raised-button (click)="addnewpatient()"
                                *ngIf="isEditPatient==true" [disabled]="systemFlag!='M' && isEditPatient==true"
                                style="margin-right: 10px;" matTooltip="Save">Save</button>
                            <button color="danger" type="submit" mat-raised-button class="danger"
                                *ngIf="isEditPatient==true" [disabled]="systemFlag!='M'" (click)="deletePatient()"
                                matTooltip="Delete"> Delete</button>
                        </div>
                    </div>
                </mat-card>

                <mat-accordion>
                    <mat-expansion-panel [expanded]="step4 === 1" (opened)="setStep4(1)" hideToggle class="mt-2">
                        <mat-expansion-panel-header (click)="icdCodesSearch()"
                            style="color: #fff; background: #002657; border-radius:  4px 4px 0 0;">
                            <mat-panel-title style="color: #fff; font-size: 14px;">Diagnosis Information</mat-panel-title>

                        </mat-expansion-panel-header>
                        <div class="row addmembersection">

                            <div class="col-md-4 Diag">
                                <mat-form-field appearance="outline">
                                    <input matInput placeholder="Search Diagnosis " #DrugValue (keyup)="getIcdData()"
                                        formControlName="diagnosis" fxLayout="row" name="diagnosis" type="text"
                                        [matAutocomplete]="auto" >
                                    <mat-hint style="color: red;" *ngIf="allergyhint">Search Min 3 Characters
                                    </mat-hint>
                                    <mat-autocomplete #auto="matAutocomplete">

                                        <mat-option *ngFor="let diagnosis of filteredDiagnosis" [value]="diagnosis" matTooltip="{{diagnosis}}"  matTooltipPosition="left"
                                        matTooltipClass="changeFont">
                                            {{diagnosis}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-1 col-md-1 col-sm-4" style="margin-top: 5px;">
                                <button color="warn" type="submit" mat-mini-fab mat-dialog-close type="button" (click)="addDiagnosis()"><mat-icon>add</mat-icon></button>
                            </div>
                            <div class="col-lg-9 col-md-3 col-sm-4 mb-3 d-flex">
                                <div class="col-md-1 legendicon" *ngIf="diagnosis.length>0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32.46" height="38.8" viewBox="0 0 32.46 38.8">
                                        <g id="Layer_1" transform="translate(-7.815 -4.63)">
                                            <g id="Group_394" data-name="Group 394">
                                                <circle id="Ellipse_11" data-name="Ellipse 11" cx="5.58" cy="5.58"
                                                    r="5.58" transform="translate(18.47 4.63)" />
                                                <path id="Path_131" data-name="Path 131"
                                                    d="M24.05,14.96h0a9.406,9.406,0,0,0-9.41,9.41V43.43H33.47V24.37A9.421,9.421,0,0,0,24.05,14.96Z" />
                                            </g>
                                            <g id="Group_400" data-name="Group 400">
                                                <path id="Path_132" data-name="Path 132"
                                                    d="M36.66,40.15H11.43a2.992,2.992,0,0,1-2.99-2.99V21.05a2.992,2.992,0,0,1,2.99-2.99H36.66a2.992,2.992,0,0,1,2.99,2.99V37.16A2.992,2.992,0,0,1,36.66,40.15Z"
                                                    stroke="#fafafa" stroke-miterlimit="10" stroke-width="1.25" />
                                                <g id="Group_399" data-name="Group 399">
                                                    <g id="Group_395" data-name="Group 395">
                                                        <path id="Path_133" data-name="Path 133"
                                                            d="M24.05,38.41a1.25,1.25,0,0,1-1.25-1.25V21.38a1.25,1.25,0,0,1,2.5,0V37.16A1.25,1.25,0,0,1,24.05,38.41Z"
                                                            fill="#fafafa" />
                                                    </g>
                                                    <g id="Group_396" data-name="Group 396">
                                                        <path id="Path_134" data-name="Path 134"
                                                            d="M28.58,35.7h-9a1.25,1.25,0,0,1,0-2.5h9a1.25,1.25,0,1,1,0,2.5Z"
                                                            fill="#fafafa" />
                                                    </g>
                                                    <g id="Group_397" data-name="Group 397">
                                                        <path id="Path_135" data-name="Path 135"
                                                            d="M30.08,24.98h-12a1.25,1.25,0,0,1,0-2.5h12a1.25,1.25,0,0,1,0,2.5Z"
                                                            fill="#fafafa" />
                                                    </g>
                                                    <g id="Group_398" data-name="Group 398">
                                                        <path id="Path_136" data-name="Path 136"
                                                            d="M32.08,30.34h-16a1.25,1.25,0,0,1,0-2.5h16a1.25,1.25,0,0,1,0,2.5Z"
                                                            fill="#fafafa" />
                                                    </g>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                                <div class="col-md-11">
                                    <mat-chip-list aria-label="Fish selection">
                                        <mat-chip style="background-color: white; border: solid 2px burlywood; margin-bottom: 10px;"
                                            *ngFor="let code of diagnosis" [removable]="removable" (removed)="removeDiagnosis(code)">{{code}}
                                            <mat-icon matChipRemove *ngIf="diagnosisRemoval">cancel</mat-icon>
                                        </mat-chip>
                                    </mat-chip-list>
                                </div>
                            </div>

                        </div>

                        <div class="row align-items-start mt-2">
                            <div class="col-md-12">
                                <button color="warn" type="submit" mat-raised-button (click)="editDiagnosis()" style="margin-right: 10px;" matTooltip="Edit">Edit</button>
                                <button color="primary" type="submit" mat-raised-button mat-dialog-close (click)="openConfirmationDialog()" style="margin-right: 10px;"
                                    [disabled]="diagnosisSaveDisabled" matTooltip="Save">Save
                                </button>

                            </div>
                        </div>
=                    </mat-expansion-panel>
                </mat-accordion>

                <mat-divider></mat-divider>

                <mat-accordion>
                    <mat-expansion-panel [expanded]="step3 === 1" (opened)="setStep3(1)" hideToggle class="mt-2">
                        <mat-expansion-panel-header
                            style="color: #fff; background: #002657; border-radius:  4px 4px 0 0;">
                            <mat-panel-title style="color: #fff; font-size: 14px;">Allergy Information
                            </mat-panel-title>
                        </mat-expansion-panel-header>


                        <div class="row addmembersection">

                            <div class="col-lg-2 col-md-3 col-sm-4">
                                <mat-form-field appearance="outline">
                                    <mat-label>Allergy Type </mat-label>
                                    <mat-select name="allergyType" formControlName="allergyType"
                                        (selectionChange)="onchangeAllergy($event.value)">
                                        <mat-option value="M" style="background-color:white; border: solid 1px burlywood;" [disabled]="disableOtherAllergy" class="allergydp"> 
                                            <svg xmlns="http://www.w3.org/2000/svg" width="11" height="33" viewBox="0 0 18.973 39">
                                                <g id="Layer_1" transform="translate(-14.486 -4.5)">
                                                    <g id="Group_393" data-name="Group 393">
                                                        <path id="Path_129" data-name="Path 129"
                                                            d="M32.4,4.5H15.544a1.058,1.058,0,0,0-1.058,1.058v6.321H15.54V41.392A2.106,2.106,0,0,0,17.649,43.5H30.3a2.105,2.105,0,0,0,2.108-2.108V11.878h1.054V5.558A1.058,1.058,0,0,0,32.4,4.5Zm-2.1,30H17.649V17.639H30.3Z" />
                                                        <path id="Path_130" data-name="Path 130"
                                                            d="M21.164,25.231H22.6L24.559,28,22.632,30.8h1.643l1.106-1.608L26.5,30.8h1.676l-1.957-2.821,1.738-2.467H26.333l-.878,1.4-1.435-2.02c.049-.023.094-.049.141-.076a1.82,1.82,0,0,0,.923-1.337,2.139,2.139,0,0,0,.006-.51,1.745,1.745,0,0,0-.677-1.236,1.561,1.561,0,0,0-.141-.094,2.54,2.54,0,0,0-1.41-.288H19.773v6.4h1.391V25.231Zm0-2.782h1.391c.816,0,1.18.269,1.18.873a.8.8,0,0,1-.336.7,1.49,1.49,0,0,1-.9.182H21.164V22.449Z" />
                                                    </g>
                                                </g>
                                            </svg> <span>Medication</span>
                                        </mat-option>
                                        <mat-option value="C" style="background-color: white; border: solid 1px aqua; "
                                            [disabled]="disableOtherAllergy" class="allergydp"> <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22.423" viewBox="0 0 22 22.423">
                                                <g id="Layer_1" transform="translate(-4.868 -4.5)">
                                                    <path id="Path_138" data-name="Path 138"
                                                        d="M26.865,8.776a.246.246,0,0,0-.246-.284H19.83a3.413,3.413,0,0,0-2.907,2.191l-.439,1.854V9.043a2.314,2.314,0,1,0-1.173,0v3.745l-.5-2.106a3.413,3.413,0,0,0-2.907-2.191H5.117a.246.246,0,0,0-.246.284,2.156,2.156,0,0,0,1.445,1.84,2.217,2.217,0,0,0,1.3,2.3,1.748,1.748,0,0,0,1.476,2.1h6.13a.456.456,0,0,0,.093-.022v1.3H13.178a1.8,1.8,0,0,0,0,3.6h2.133v1.254H13.178a1.8,1.8,0,0,0,0,3.6h2.133v1.583a.587.587,0,0,0,1.173,0V24.753h3.6a.587.587,0,0,0,0-1.173h-3.6V22.326h2.391a1.8,1.8,0,1,0,0-3.6H16.484V17.472h2.391a1.8,1.8,0,0,0,1.8-1.8,1.784,1.784,0,0,0-.128-.655h2.1a1.748,1.748,0,0,0,1.476-2.1,2.217,2.217,0,0,0,1.3-2.3A2.156,2.156,0,0,0,26.865,8.776ZM14.522,6.814A1.375,1.375,0,1,1,15.9,8.189,1.377,1.377,0,0,1,14.522,6.814ZM13.178,18.726a.627.627,0,1,1,0-1.254h2.133v1.254H13.178Zm0,4.854a.627.627,0,0,1,0-1.254h2.133V23.58H13.178ZM19.5,20.526a.628.628,0,0,1-.627.627H16.484V19.9h2.391A.628.628,0,0,1,19.5,20.526ZM18.876,16.3H16.484V15.045h2.391a.627.627,0,0,1,0,1.254Z"
                                                        transform="translate(0 0)" />
                                                </g>
                                            </svg> <span>Medication Class</span> 
                                        </mat-option>
                                        <mat-option value="O" style="background-color: white; border: solid 1px gray; "
                                            [disabled]="disableOtherAllergy" class="allergydp"> 
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                width="36.973" height="32.833" viewBox="0 0 36.973 32.833">
                                                <g id="Icon_HealthyFood_Blk_RGB" transform="translate(-4.565 -6.703)">
                                                    <path id="Path_128" data-name="Path 128"
                                                        d="M41.538,18.6,39.6,17.569l1.87-1.479-3.88-2.037-2.9,2.3a7.045,7.045,0,0,0-2.964-.663q-.159,0-.318.007a7.627,7.627,0,0,0-3.774,1.091,7.393,7.393,0,0,0-10.4-6.2V6.7l-2.207.981V10.6A7.4,7.4,0,0,0,5.206,20.35l1.433,3.223h0s1.933,4.341,2.02,4.508a5.671,5.671,0,0,0,7.355,2.569c-2.736,3.419-4.994,6.241-5.042,6.308a1.63,1.63,0,0,0,.941,2.533,1.718,1.718,0,0,0,.389.045,1.627,1.627,0,0,0,.575-.1c.123-.046,21.639-9.769,21.639-9.769a7.282,7.282,0,0,0,4.064-8.72Zm-6.849,8.41a5.134,5.134,0,0,1-1.086.641c-5.583,2.523-15.058,6.8-19.364,8.746,3.11-3.89,10.152-12.683,13.277-16.584A4.974,4.974,0,0,1,31.5,17.9q.111,0,.222,0a4.685,4.685,0,0,1,2.842.924,5.147,5.147,0,0,1,2.1,4.125A5.093,5.093,0,0,1,34.689,27.01Z"
                                                        transform="translate(0)" />
                                                </g>
                                            </svg> <span>Others</span>
                                        </mat-option>

                                        <mat-option value="N" style="background-color: white; border: solid 1px green; "
                                            [disabled]="disableNoKnowAllergy" class="allergydp"> 
                                            <svg xmlns=" http://www.w3.org/2000/svg" width="23.474" height="23.475" viewBox="0 0 23.474 23.475">
                                            <g id="Icon_HappySmile_Blk_RGB" transform="translate(-5 -5)">
                                            <path id="Path_139" data-name="Path 139" d="M19.724,12.814a6.91,6.91,0,1,0,6.91,6.91A6.91,6.91,0,0,0,19.724,12.814Zm0,12.584A5.674,5.674,0,1,1,25.4,19.724,5.681,5.681,0,0,1,19.724,25.4Z" transform="translate(-2.987 -2.987)"/>
                                            <path id="Path_140" data-name="Path 140" d="M24.219,5.576a.576.576,0,0,0-.833-.515h0a.576.576,0,0,0-.318.515v3.1h1.152Z" transform="translate(-6.906)"/>
                                            <path id="Path_141" data-name="Path 141" d="M12.653,13.467l.814-.814-2.191-2.191a.576.576,0,0,0-.953.225h0a.576.576,0,0,0,.139.589Z" transform="translate(-2.023 -2.023)"/>
                                            <path id="Path_142" data-name="Path 142" d="M8.676,23.068h-3.1a.576.576,0,0,0-.515.833h0a.576.576,0,0,0,.515.318h3.1V23.068Z" transform="translate(0 -6.907)"/>
                                            <path id="Path_143" data-name="Path 143" d="M10.461,34.76a.576.576,0,0,0,.225.953h0a.576.576,0,0,0,.589-.139l2.191-2.191-.814-.814Z" transform="translate(-2.023 -10.538)"/>
                                            <path id="Path_144" data-name="Path 144" d="M23.068,40.149a.576.576,0,0,0,.833.515h0a.576.576,0,0,0,.318-.515v-3.1H23.068Z" transform="translate(-6.907 -12.251)"/>
                                            <path id="Path_145" data-name="Path 145" d="M33.383,32.569l-.814.814,2.191,2.191a.576.576,0,0,0,.814-.814Z" transform="translate(-10.538 -10.538)"/>
                                            <path id="Path_146" data-name="Path 146" d="M40.664,23.386h0a.576.576,0,0,0-.515-.318h-3.1V24.22h3.1A.576.576,0,0,0,40.664,23.386Z" transform="translate(-12.251 -6.907)"/>
                                            <path id="Path_147" data-name="Path 147" d="M35.574,11.276a.576.576,0,0,0-.225-.953h0a.576.576,0,0,0-.589.139l-2.191,2.191.814.814Z" transform="translate(-10.538 -2.023)"/>
                                            <path id="Path_148" data-name="Path 148" d="M25.16,24a.571.571,0,0,0-.566.491,3.455,3.455,0,0,1-6.84,0,.576.576,0,0,0-1.141.157,4.607,4.607,0,0,0,9.123,0A.576.576,0,0,0,25.16,24Z" transform="translate(-4.437 -7.263)"/>
                                            </g>
                                            </svg> <span>No Known Allergies</span>
                                        </mat-option>
                                    </mat-select>

                                </mat-form-field>
                            </div>

                            <div class="col-md-5 Diag">
                                <mat-form-field appearance="outline">
                                    <input matInput placeholder="{{allergyplaceholder}}" #DrugValue
                                        (keyup)="getMedicationAllergyDropdown()" formControlName="medicationAllergy"
                                        fxLayout="row" name="medicationAllergy" type="text" [matAutocomplete]="auto1"
                                        [disabled]="disableOtherAllergy">
                                    <mat-autocomplete #auto1="matAutocomplete">
                                        <mat-option *ngFor="let medAllergy of allergyList"
                                            [value]="medAllergy | titlecase">
                                            {{getTitleCase(medAllergy)}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>



                            <div class="col-lg-1 col-md-1 col-sm-4" style="margin-top: 5px;">
                                <button color="warn" type="submit" mat-mini-fab mat-dialog-close type="button" (click)="addAllergy()"><mat-icon>add</mat-icon></button>
                            </div>


                            <div class="col-lg-7 col-md-7 col-sm-4 mb-3">
                                <div class="col-md-12 d-flex mb-2">
                                    <div class="col-md-1 legendicon" *ngIf="selectedMedAllergy.length>0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11" height="33"
                                            viewBox="0 0 18.973 39">
                                            <g id="Layer_1" transform="translate(-14.486 -4.5)">
                                                <g id="Group_393" data-name="Group 393">
                                                    <path id="Path_129" data-name="Path 129"
                                                        d="M32.4,4.5H15.544a1.058,1.058,0,0,0-1.058,1.058v6.321H15.54V41.392A2.106,2.106,0,0,0,17.649,43.5H30.3a2.105,2.105,0,0,0,2.108-2.108V11.878h1.054V5.558A1.058,1.058,0,0,0,32.4,4.5Zm-2.1,30H17.649V17.639H30.3Z" />
                                                    <path id="Path_130" data-name="Path 130"
                                                        d="M21.164,25.231H22.6L24.559,28,22.632,30.8h1.643l1.106-1.608L26.5,30.8h1.676l-1.957-2.821,1.738-2.467H26.333l-.878,1.4-1.435-2.02c.049-.023.094-.049.141-.076a1.82,1.82,0,0,0,.923-1.337,2.139,2.139,0,0,0,.006-.51,1.745,1.745,0,0,0-.677-1.236,1.561,1.561,0,0,0-.141-.094,2.54,2.54,0,0,0-1.41-.288H19.773v6.4h1.391V25.231Zm0-2.782h1.391c.816,0,1.18.269,1.18.873a.8.8,0,0,1-.336.7,1.49,1.49,0,0,1-.9.182H21.164V22.449Z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="col-md-11">
                                        <mat-chip-list aria-label="Fish selection">
                                            <mat-chip style="background-color: white; border: solid 2px burlywood; margin-bottom: 10px;"
                                                *ngFor="let catAllergy of selectedMedAllergy" [removable]="removable" (removed)="removeMedAllergy(catAllergy)">{{catAllergy}}
                                                <mat-icon matChipRemove *ngIf="allergyRemovable">cancel</mat-icon>
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>
                                </div>

                                <div class="col-md-12 d-flex mb-2">
                                    <div class="col-md-1 legendicon-medication"  *ngIf="selectedCatAllergy.length>0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22.423"
                                            viewBox="0 0 22 22.423">
                                            <g id="Layer_1" transform="translate(-4.868 -4.5)">
                                                <path id="Path_138" data-name="Path 138" d="M26.865,8.776a.246.246,0,0,0-.246-.284H19.83a3.413,3.413,0,0,0-2.907,2.191l-.439,1.854V9.043a2.314,2.314,0,1,0-1.173,0v3.745l-.5-2.106a3.413,3.413,0,0,0-2.907-2.191H5.117a.246.246,0,0,0-.246.284,2.156,2.156,0,0,0,1.445,1.84,2.217,2.217,0,0,0,1.3,2.3,1.748,1.748,0,0,0,1.476,2.1h6.13a.456.456,0,0,0,.093-.022v1.3H13.178a1.8,1.8,0,0,0,0,3.6h2.133v1.254H13.178a1.8,1.8,0,0,0,0,3.6h2.133v1.583a.587.587,0,0,0,1.173,0V24.753h3.6a.587.587,0,0,0,0-1.173h-3.6V22.326h2.391a1.8,1.8,0,1,0,0-3.6H16.484V17.472h2.391a1.8,1.8,0,0,0,1.8-1.8,1.784,1.784,0,0,0-.128-.655h2.1a1.748,1.748,0,0,0,1.476-2.1,2.217,2.217,0,0,0,1.3-2.3A2.156,2.156,0,0,0,26.865,8.776ZM14.522,6.814A1.375,1.375,0,1,1,15.9,8.189,1.377,1.377,0,0,1,14.522,6.814ZM13.178,18.726a.627.627,0,1,1,0-1.254h2.133v1.254H13.178Zm0,4.854a.627.627,0,0,1,0-1.254h2.133V23.58H13.178ZM19.5,20.526a.628.628,0,0,1-.627.627H16.484V19.9h2.391A.628.628,0,0,1,19.5,20.526ZM18.876,16.3H16.484V15.045h2.391a.627.627,0,0,1,0,1.254Z"
                                                    transform="translate(0 0)" />
                                            </g>
                                        </svg>
                                    </div>
                                    <div class="col-md-11">
                                        <mat-chip-list aria-label="Fish selection">
                                            <mat-chip style="background-color: white; border: solid 2px aqua; margin-bottom: 10px;"
                                                *ngFor="let catAllergy of selectedCatAllergy" [removable]="removable" (removed)="removeAllergycategory(catAllergy)">{{catAllergy}}
                                                <mat-icon matChipRemove *ngIf="allergyRemovable">cancel</mat-icon>
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>
                                </div>

                                <div class="col-md-12 d-flex mb-2">
                                    <div class="col-md-1 legendicon-others"  *ngIf="selectedOtherAllergy.length>0">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24.817" height="22.038" viewBox="0 0 24.817 22.038">
                                            <g id="Icon_HealthyFood_Blk_RGB" transform="translate(-4.565 -6.703)">
                                              <path id="Path_128" data-name="Path 128" d="M29.382,14.688,28.082,14,29.338,13l-2.6-1.367-1.949,1.541a4.729,4.729,0,0,0-1.99-.445q-.107,0-.214,0a5.119,5.119,0,0,0-2.533.732,4.962,4.962,0,0,0-6.983-4.162V6.7l-1.482.658V9.317A4.966,4.966,0,0,0,5,15.863l.962,2.163h0s1.3,2.914,1.356,3.026a3.806,3.806,0,0,0,4.937,1.724C10.411,25.071,8.9,26.965,8.863,27.01a1.094,1.094,0,0,0,.632,1.7,1.153,1.153,0,0,0,.261.03,1.092,1.092,0,0,0,.386-.07c.082-.031,14.524-6.557,14.524-6.557a4.887,4.887,0,0,0,2.728-5.853Zm-4.6,5.645a3.446,3.446,0,0,1-.729.43c-3.747,1.693-10.107,4.567-13,5.87,2.088-2.611,6.814-8.513,8.911-11.131a3.339,3.339,0,0,1,2.676-1.284q.075,0,.149,0a3.145,3.145,0,0,1,1.908.62A3.455,3.455,0,0,1,26.111,17.6,3.419,3.419,0,0,1,24.785,20.334Z" transform="translate(0 0)"/>
                                            </g>
                                          </svg>
                                    </div>
                                    <div class="col-md-11">
                                        <mat-chip-list aria-label="Fish selection">
                                            <mat-chip style="background-color: white; border: solid 2px gray; margin-bottom: 10px;"
                                                *ngFor="let catAllergy of selectedOtherAllergy" [removable]="removable" (removed)="removeotherAllergy(catAllergy)">{{catAllergy}}
                                                <mat-icon matChipRemove *ngIf="allergyRemovable">cancel</mat-icon>
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>
                                </div>

                                <div class="col-md-12 d-flex mb-2">
                                    <div class="col-md-1 legendicon-allergy"  *ngIf="selectedNoKNownAllergy.length>0"> 
                                        <svg xmlns=" http://www.w3.org/2000/svg" width="23.474" height="23.475" viewBox="0 0 23.474 23.475">
                                            <g id="Icon_HappySmile_Blk_RGB" transform="translate(-5 -5)">
                                            <path id="Path_139" data-name="Path 139" d="M19.724,12.814a6.91,6.91,0,1,0,6.91,6.91A6.91,6.91,0,0,0,19.724,12.814Zm0,12.584A5.674,5.674,0,1,1,25.4,19.724,5.681,5.681,0,0,1,19.724,25.4Z" transform="translate(-2.987 -2.987)"/>
                                            <path id="Path_140" data-name="Path 140" d="M24.219,5.576a.576.576,0,0,0-.833-.515h0a.576.576,0,0,0-.318.515v3.1h1.152Z" transform="translate(-6.906)"/>
                                            <path id="Path_141" data-name="Path 141" d="M12.653,13.467l.814-.814-2.191-2.191a.576.576,0,0,0-.953.225h0a.576.576,0,0,0,.139.589Z" transform="translate(-2.023 -2.023)"/>
                                            <path id="Path_142" data-name="Path 142" d="M8.676,23.068h-3.1a.576.576,0,0,0-.515.833h0a.576.576,0,0,0,.515.318h3.1V23.068Z" transform="translate(0 -6.907)"/>
                                            <path id="Path_143" data-name="Path 143" d="M10.461,34.76a.576.576,0,0,0,.225.953h0a.576.576,0,0,0,.589-.139l2.191-2.191-.814-.814Z" transform="translate(-2.023 -10.538)"/>
                                            <path id="Path_144" data-name="Path 144" d="M23.068,40.149a.576.576,0,0,0,.833.515h0a.576.576,0,0,0,.318-.515v-3.1H23.068Z" transform="translate(-6.907 -12.251)"/>
                                            <path id="Path_145" data-name="Path 145" d="M33.383,32.569l-.814.814,2.191,2.191a.576.576,0,0,0,.814-.814Z" transform="translate(-10.538 -10.538)"/>
                                            <path id="Path_146" data-name="Path 146" d="M40.664,23.386h0a.576.576,0,0,0-.515-.318h-3.1V24.22h3.1A.576.576,0,0,0,40.664,23.386Z" transform="translate(-12.251 -6.907)"/>
                                            <path id="Path_147" data-name="Path 147" d="M35.574,11.276a.576.576,0,0,0-.225-.953h0a.576.576,0,0,0-.589.139l-2.191,2.191.814.814Z" transform="translate(-10.538 -2.023)"/>
                                            <path id="Path_148" data-name="Path 148" d="M25.16,24a.571.571,0,0,0-.566.491,3.455,3.455,0,0,1-6.84,0,.576.576,0,0,0-1.141.157,4.607,4.607,0,0,0,9.123,0A.576.576,0,0,0,25.16,24Z" transform="translate(-4.437 -7.263)"/>
                                            </g>
                                            </svg>
                                    </div>
                                    <div class="col-md-11">
                                        <mat-chip-list aria-label="Fish selection">
                                            <mat-chip style="background-color: white; border: solid 2px green; margin-bottom: 10px;"
                                                *ngFor="let catAllergy of selectedNoKNownAllergy" [removable]="removable"
                                                (removed)="removenoKnownAllergy(catAllergy)">{{catAllergy}}
                                                <mat-icon matChipRemove *ngIf="allergyRemovable">cancel</mat-icon>
                                            </mat-chip>
                                        </mat-chip-list>
                                    </div>
                                </div>

                            </div>
                        </div>


                        <div class="row align-items-start mt-2">
                            <div class="col-md-12 ">
                                <button color="warn" type="button" mat-raised-button mat-dialog-close matTooltip="Edit" style="margin-right: 10px;"
                                    (click)="editAllergy()">Edit
                                </button>
                                <button color="primary" type="submit" mat-raised-button mat-dialog-close type="button"
                                    matTooltip="Save" (click)="saveAllergy()" [disabled]="allergySaveDisabled">Save
                                </button>                                                            
                            </div>

                        </div>
  

        </mat-expansion-panel>
        </mat-accordion>



        </form>