<app-header></app-header>

<div class="container-fluid mt-4">

    <div class="row mb-3">
        <div class="container text-center align-items-center">
            <div class="row justify-content-md-center">
                <div class="col-11 text-center"></div>
                <div class="col-1 text-end"><button mat-flat-button color="primary" (click)="navigateToAddMember()">Add
                        Member</button></div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-2">
            <div class="listWrapper mb-3" [@enterAnimation]>
                <button mat-flat-button (click)="goBackHome()" [routerLink]="['/landingPage']">
                    <mat-icon mat-flat-button>home</mat-icon> Home
                </button>
            </div>
            <div class="filterList" [@enterAnimation]>
                <form novalidate [formGroup]="searchPatient" fxLayout="column" fxLayoutGap="4px">
                    <mat-form-field appearance="outline">
                        <mat-label>Mbr Ref No</mat-label>
                        <input matInput placeholder="Mbr Ref No" formControlName="mrn" type="text">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Name</mat-label>
                        <input matInput placeholder="Name" formControlName="firstName" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))' >
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Phone</mat-label>
                        <input matInput placeholder="Phone" type="text" formControlName="primaryPhone">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Gender</mat-label>
                        <mat-select formControlName="gender">
                            <mat-option value="m"> Male</mat-option>
                            <mat-option value="f"> Female</mat-option>
                            <mat-option value="NA">NA</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="dobinput">
                        <mat-form-field appearance="outline">
                            <mat-label>DOB</mat-label>
                            <input matInput placeholder="DOB" type="date" formControlName="dob">
                        </mat-form-field>
                    </div>
                    <mat-form-field appearance="outline">
                        <mat-label>City</mat-label>
                        <input matInput placeholder="City" formControlName="city">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>State</mat-label>
                        <input matInput placeholder="State" formControlName="state">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Email</mat-label>
                        <input matInput placeholder="Primary Email" formControlName="email">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Postal Code</mat-label>
                        <input matInput placeholder="Postal Code" type="text" formControlName="zipCode" minlength="5"  >
                    </mat-form-field>
                    <div fxLayout="row" class="searchButtonWrapper" fxFlex="100%" fxLayoutGap="15px"
                        fxLayoutAlign="space-between center">
                        <div fxFlex="50%"><button fxFlex="100%" type="reset" mat-stroked-button color="default"
                                (click)="clearSearch()">Reset</button></div>
                        <div fxFlex="50%"><button fxFlex="100%" mat-flat-button color="primary"
                                (click)="serverSearch()">Search</button></div>
                    </div>
                </form>
            </div>
        </div>


        <div class="col-lg-10" [@enterAnimation]>


            <div fxLayout="column" class="innSpinner" *ngIf="isSpinner">
                <div class="lds-spinner">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
            <div class="contentPanel">
                <ag-grid-angular style="width: 100%; height: calc(100vh - 170px);" class="ag-theme-material"
                    (gridReady)="onGridReady($event)" [rowData]="rowData" [columnDefs]="columnDefs"
                    [defaultColDef]="defaultColDefs" rowSelection="single" (rowSelected)="onRowSelect($event)"
                    [pagination]="pagination" [paginationPageSize]="paginationPageSize" [headerHeight]=50
                    [rowHeight]=34></ag-grid-angular>
            </div>
        </div>
    </div>