import { Component, Input, OnInit,ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from 'protractor';
import { AuthService } from 'src/app/services/auth.service';
import { Keepalive } from '@ng-idle/keepalive'
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { TimeoutDialogMemberDetailsComponent } from 'src/app/timeout-dialog-member-details/timeout-dialog-member-details.component';
import { CONSTANT } from 'src/assets/appConstant';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  username: string;
  msid: string;
  name: any


  patientDetails: boolean = false;
  cdoRegionName: string;
  cdos: any = [
    {
    "region": "UT",
    "regionName": "Utah"
   },
  {
    "region": "ID",
    "regionName": "Idaho"
  }
]
  currcdo = localStorage.getItem('regionName')
  idleState = "NOT_STARTED";
  countdown?: number = null;
  lastPing?: Date = null;
  currentPath: String;


  constructor(public dialog: MatDialog,public _router: Router,private idle: Idle,private keepalive: Keepalive,private cd: ChangeDetectorRef,location:Location) {


   }

   resetIdle() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  ngOnInit(): void {
    this.resetIdle()
    this.name = localStorage.getItem('usname')
    this.cdoRegionName = localStorage.getItem('cdoRegion')
}



  logoHomeLink() {


  }




  onregionChange(value) {
    debugger
    localStorage.setItem('cdo', value);
    let listcdos = this.cdos
    let cdosregion = listcdos.filter(cdo => cdo.region === value)[0]
    localStorage.setItem('cdoRegion', cdosregion.regionName);
    localStorage.setItem('regionName', cdosregion.region)

    this.cdoRegionName = cdosregion.regionName


    if(this.checkAccessOnChange()){

    this._router.navigate(["/landingPage"]);
    console.log(this.cdos)
    setTimeout(() => {
      window.location.reload()

    }, 1000);





  }
  else {
    this._router.navigate(["/login"]);
  }
  }
  checkAccessOnChange(): boolean {

    if(JSON.parse(localStorage.getItem('loginresponse')).portal=='Y' && localStorage.getItem('regionName')==CONSTANT.REGION_IDAHO){

      return true
    }
    else if(JSON.parse(localStorage.getItem('loginresponse')).portalUtah=='Y' && localStorage.getItem('regionName')==CONSTANT.REGION_UTAH){

      return true
    }

    return false;

  }

  logout() {

    localStorage.clear()

    this._router.navigate(['/login'])

  }

}
