<form [formGroup]="medform">

    <h2 mat-dialog-title>
        <div class="dialog-title row">

            <div class="col-md-12 d-flex align-items-center">
                <div class="col-md-1">
                    <h2 style="font-size: 16px !important;">Add Medication</h2>
                </div>
                <div class="col-md-2" style="margin: 0 0 0 15px;">
                    <mat-form-field appearance="outline">
                        <mat-label>Med Source<span style="color: red;"> *</span></mat-label>
                        <mat-select name="medsource" formControlName="medsource"
                            (selectionChange)="onchangeSource($event.value)">
                            <mat-option value="SNF">SNF</mat-option>
                            <mat-option value="Inpatient">Inpatient</mat-option>
                            <mat-option value="Member Reported">Member Reported</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-6"></div>
                <div class="col-md-2">
                    <mat-form-field appearance="outline">
                        <mat-label>Encounter ID</mat-label>
                        <input matInput type="text" placeholder="Encounter Id" name="encounterId"
                            formControlName="encounterId">
                    </mat-form-field>
                </div>
                <div class="col-md-1 text-end">
                    <button mat-icon-button color="primary" mat-dialog-close
                        class="close-button"><mat-icon>close</mat-icon></button>
                </div>
            </div>

        </div>
    </h2>


    <div class="col-md-12">


        <div class="row">


            <div class="col-md-5 Diag">
                <mat-form-field (keyup)="getDrugData()" appearance="outline">
                    <input matInput placeholder="Search Medications" #DrugValue (input)="getDrug(DrugValue)"
                        formControlName="drugName" fxLayout="row" name="drugName" type="text" [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                        <mat-option *ngFor="let dru of drugDetails" [value]="dru.drug_name"
                            (click)="onDrugChange(dru.ndc)">
                            <span [matTooltip]="dru.drug_name+'-'+dru.ndc"
                                style="font-weight: bold; font-size: 15.5px;"> {{dru.drug_name }} -{{dru.ndc}} </span>
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>



            <div class="col-md-4">
                <mat-form-field appearance="outline">
                    <mat-label>Medication Name <span style="color: red;"> *</span></mat-label>
                    <input matInput type="text" placeholder="Medication Name" name="medName" formControlName="medName">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Dosage</mat-label>
                    <input matInput type="number" min="0" placeholder="Dosage" name="dose" formControlName="dose">
                </mat-form-field>
            </div>


            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Form</mat-label>
                    <input matInput type="text" formControlName="form" [matAutocomplete]="auto1">
                    <mat-autocomplete name="form" #auto1="matAutocomplete">
                        <mat-option *ngFor="let data of formData" [value]="data ">
                            {{data }}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Route</mat-label>
                    <mat-select (selectionChange)="onChangeRoute($event.value)" name="route" formControlName="route">
                        <mat-option *ngFor="let formdata of routeData" [value]="formdata">{{formdata}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Route Desc</mat-label>
                    <input matInput type="text" placeholder="Route Value" name="routeVal" formControlName="routeVal">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Frequency<span style="color: red;"> *</span></mat-label>
                    <mat-select (selectionChange)="onChangeFrequency($event.value)" name="frequency"
                        formControlName="frequency">
                        <mat-option *ngFor="let formdata of frequencyData"
                            [value]="formdata.meaning">{{formdata.abbrMeaning}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Frequency Desc<span style="color: red;"> *</span></mat-label>
                    <input matInput type="text" placeholder="Frequency Value" name="freqVal" formControlName="freqVal">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>PRN</mat-label>
                    <mat-select name="prn" formControlName="prn" (selectionChange)="onchangePRN($event.value)">
                        <mat-option value="Yes">Yes</mat-option>
                        <mat-option value="No"> No</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <mat-form-field appearance="outline">
                    <mat-label>Instructions</mat-label>
                    <textarea matInput type="text" placeholder="Instructions" name="instructions"
                        formControlName="instructions"></textarea>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Patient Taking Medication</mat-label>
                    <mat-select name="paitentTakingMed" formControlName="paitentTakingMed">
                        <mat-option *ngFor="let formdata of patientTakingDropdown"
                            [value]="formdata">{{formdata}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-2 dobinput">
                <mat-form-field appearance="outline">
                    <mat-label>Written Date</mat-label>
                    <input matInput type="date" placeholder="Written Date" name="recordeddatetime"
                        formControlName="recordeddatetime">
                </mat-form-field>
            </div>
            <div class="col-md-2 dobinput">
                <mat-form-field appearance="outline">
                    <mat-label>Last Filled Date</mat-label>
                    <input matInput type="date" placeholder="Last Filled Date" name="lastfilleddatetime"
                        formControlName="lastfilleddatetime">
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Strength</mat-label>
                    <input matInput type="text" placeholder="Strength" name="strength" formControlName="strength">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Unit of Measure</mat-label>
                    <input matInput type="text" placeholder="Unit Of Measure" name="unitofmeasure"
                        formControlName="unitofmeasure">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Quantity</mat-label>
                    <input matInput type="text" placeholder="Quantity" name="quantity" formControlName="quantity">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label> Days Supply</mat-label>
                    <input matInput type="number" name="daysofsupply" formControlName="daysofsupply" min="0">
                </mat-form-field>
            </div>
        </div>

        <div class="row" *ngIf="hide">
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Rx Claim Number</mat-label>
                    <input matInput type="text" placeholder="Rx Claim Number" name="rxclaimnbr"
                        formControlName="rxclaimnbr">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Rx Claim Status</mat-label>
                    <input matInput type="text" placeholder="Rx Claim Status" name="rxclaimstatus"
                        formControlName="rxclaimstatus">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Notification Msg ID</mat-label>
                    <input matInput type="text" placeholder="Notification Msg Id" name="notificationMsgId"
                        formControlName="notificationMsgId">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Diagnosis</mat-label>
                    <input matInput type="text" placeholder="Diagnosis" name="diagnosis" formControlName="diagnosis">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Diagnosis Description</mat-label>
                    <input matInput type="text" placeholder="Description" name="diagnosisDescription"
                        formControlName="diagnosisDescription">
                </mat-form-field>
            </div>
            <div class="col-md-2 diagnosisSaveDisabled">
                <mat-form-field appearance="outline">
                    <mat-label>Effective Date</mat-label>
                    <input matInput type="date" placeholder="Effective Date" name="diagnosisEffectFrom"
                        formControlName="diagnosisEffectFrom">
                </mat-form-field>
            </div>
        </div>

        <div class="row" *ngIf="hide">
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Prescriber Name</mat-label>
                    <input matInput type="text" placeholder="Name" name="prescriberName"
                        formControlName="prescriberName">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Prescriber NPI</mat-label>
                    <input matInput type="text" placeholder="NPI" name="prescriberNPI" formControlName="prescriberNPI">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Prescriber Speciality</mat-label>
                    <input matInput type="text" placeholder="Speciality" name="prescriberSpeciality"
                        formControlName="prescriberSpeciality">
                </mat-form-field>
            </div>
        </div>

        <div class="row" *ngIf="hide">
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Pharmacy Name</mat-label>
                    <input matInput type="text" placeholder="Name" name="pharmacyName" formControlName="pharmacyName">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Pharmacy NPI</mat-label>
                    <input matInput type="text" placeholder="Pharmacy NPI" name="pharmacyNPI"
                        formControlName="pharmacyNPI">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Pharmacy Contact No</mat-label>
                    <input matInput type="text" placeholder="Contact" name="pharmacyContact"
                        formControlName="pharmacyContact">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline" *ngIf="hide">
                    <mat-label>Category</mat-label>
                    <input matInput type="text" placeholder="Drug Category" name="category" formControlName="category">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline" *ngIf="hide">
                    <mat-label>NDC</mat-label>
                    <input matInput type="text" placeholder="NDC" name="ndc" formControlName="ndc">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline" *ngIf="hide">
                    <mat-label>GPI</mat-label>
                    <input matInput type="text" placeholder="GPI" name="gpi" formControlName="gpi">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline" *ngIf="hide">
                    <mat-label>Form</mat-label>
                    <mat-select name="form" formControlName="form">
                        <mat-option *ngFor="let formdata of formlist" [value]="formdata">{{formdata}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="row" *ngIf="hide">
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Patient Comments</mat-label>
                    <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5" name="patientcomments" formControlName="patientcomments"></textarea>
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline">
                    <mat-label>Refill Number</mat-label>
                    <input matInput type="text" name="noofrefill" formControlName="noofrefill">
                </mat-form-field>
            </div>
            <div class="col-md-2">
                <mat-form-field appearance="outline" *ngIf="hide">
                    <mat-label *ngIf="!isprn"> Total No of Refill</mat-label>
                    <mat-label *ngIf="isprn"> Total No of Refill</mat-label>
                    <input matInput type="text" name="totalNoofrefill" formControlName="totalNoofrefill">
                </mat-form-field>
            </div>
        </div>

    </div>

    <div class="row align-items-start mt-2 windowbtns">
        <div class="col-md-12">
            <button color="default" type="submit" mat-stroked-button mat-dialog-close
                style="margin-right: 10px;">Close</button>
            <button color="default" type="submit" mat-stroked-button *ngIf="isEditPatient==true"
                (click)="clearMedForm()" style="margin-right: 10px;">Clear</button>
            <button color="primary" type="submit" mat-raised-button (click)="addnewmed()"
                *ngIf="isEditPatient==true">Save</button>

        </div>
    </div>

</form>