import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { app_config } from 'src/assets/config';
import { DataService } from '../data.service';

@Component({
  selector: 'app-oureachreportview',
  templateUrl: './oureachreportview.component.html',
  styleUrls: ['./oureachreportview.component.css']
})
export class OureachreportviewComponent implements OnInit {


  reportApiLink = "https://app.powerbi.com/reportEmbed?reportId=70df043e-68a4-48d4-aecf-71cf20602d78&autoAuth=true&ctid=db05faca-c82a-4b9d-b9c5-0f64b6755421"
  controllerSrc: any;
  constructor(private sanitizer: DomSanitizer, private dataService: DataService,) { }

  ngOnInit(): void {
   this.getreportlink();
  }
  getreportlink(): string {

    debugger

    this.dataService.getdropdown(localStorage.getItem('reportName')+'_'+localStorage.getItem('regionName')).subscribe(data => {
      this.controllerSrc= this.sanitizer.bypassSecurityTrustResourceUrl(data["values"][0])
      console.log(this.reportApiLink)


    }, error => {


    })
    return this.reportApiLink
  }

}
