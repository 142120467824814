
export class MemberAllergy {

    private type: string;
    private activeFlag: string;
    private description: String
    private mbrRefNo: string

    public gettype() {
        return this.type;
    }
    public setType(type: string) {
        return this.type = type;
    }
    public getactiveFlag() {
        return this.activeFlag;
    }
    public setActiveFlag(activeFlag: any) {
        this.activeFlag = activeFlag;
    }
    public getDescription() {
        return this.description;
    }
    public setDescription(description: string) {
        this.description = description;
    }
    public getmberRefNo() {
        return this.mbrRefNo;
    }
    public setmbrRefNo(mbrREfNo: string) {
        this.mbrRefNo = mbrREfNo;
    }

}