import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { element } from 'protractor';
import { DataService } from '../data.service';
import { TitleCasePipe } from '@angular/common';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { E } from '@angular/cdk/keycodes';
import { IdleTimeoutService } from '../idle-timeout.service';

@Component({
  selector: 'app-add-new-med',
  templateUrl: './add-new-med.component.html',
  styleUrls: ['./add-new-med.component.css']
})
export class AddNewMedComponent implements OnInit {

  isprn: any = false;
  isOTH: boolean = false;
  isRoute: boolean = false;

  frequencyData: any = []
  isreconcille = true;
  isEditPatient = true
  isreview = false
  hide = false
  meddata: any
  presdata: any
  componentname: any
  drugDetails: any
  drug: string;
  formdata1 : any = []
  routeData : any = []
  formlist = ["SOSY", "LOZG", "SUSR", "C12A", "CSDR", "CPPK", "CPEP", "CP24", "KIT", "SHAM", "INHA", "TABS", "SOPN", "GEL", "PADS", "SUER", "NEBU", "PTCH", "DEVI", "TBDD", "SRER", "LIQD", "CPDR", "POWD", "TBDP", "CAPS", "TBPK", "", "SOLR", "PSTE", "FOAM", "WAFR", "EMUL", "GRAN", "DISK", "SOLN", "CHEW", "CREA", "TROC", "GUM", "SOLG", "TBEC", "TB12", "SOAJ", "AJKT", "PNKT", "TAPE", "PTTW", "ELIX", "PTWK", "LOTN", "OIL", "TBCR", "PT72", "CONC", "SUSY", "LQPK", "STRP", "AERO", "CP12", "CPCR", "PT24", "FILM", "AEPB", "TB24", "ENEM", "PACK", "AERB", "SWAB", "T24A", "PLLT", "CPSP", "SUBL", "", "PRSY", "SOCT", "INJ", "OINT", "SYRP", "SUPN", "TBEF", "INST", "SUSP", "SUPP", "MISC", "AERP", "AERS", "AUIJ", "T12A", "PSKT"]
  step = 0;
  step2 = 0;
  patientTakingDropdown = ['Yes', 'No']
  dataSource: any
  encounterId: any
  historyMed: []
  formData : any = []
  enable: boolean = false;

  constructor(public dialogRef: MatDialogRef<AddNewMedComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public dataservice: DataService, private toastr: ToastrService,private idleService:IdleTimeoutService) {
    this.idleService.idleTimeout.subscribe(() => {
      this.dialogRef.close()
    })

    this.meddata = data.meddata
    this.encounterId = data.encounterId
    this.historyMed = data.historymed
  }
  hideRequiredControl = new FormControl(false);
  medform: FormGroup = new FormGroup({
    encounterId: new FormControl(),
    prescriptionId: new FormControl(),
    recordId: new FormControl(),
    drugName: new FormControl(),
    medsource: new FormControl(null, [Validators.required]),
    medName: new FormControl(''),
    category: new FormControl(''),
    ndc: new FormControl(''),
    gpi: new FormControl(''),
    strength: new FormControl(),
    unitofmeasure: new FormControl(),
    quantity: new FormControl(),
    dose: new FormControl(),
    route: new FormControl(''),
    // frequency: new FormControl(null, [Validators.required]),
    frequency: new FormControl(null),
    instructions: new FormControl(),
    form: new FormControl(),
    daysofsupply: new FormControl(),
    noofrefill: new FormControl(),
    totalNoofrefill: new FormControl(),
    lastfilleddatetime: new FormControl(),
    recordeddatetime: new FormControl(),
    prn: new FormControl(),
    patientcomments: new FormControl(),
    rxclaimnbr: new FormControl(),
    rxclaimstatus: new FormControl(),
    notificationMsgId: new FormControl(),
    paitentTakingMed: new FormControl(),
    diagnosis: new FormControl(),
    diagnosisDescription: new FormControl(),
    diagnosisEffectFrom: new FormControl(),
    prescriberName: new FormControl(),
    prescriberNPI: new FormControl(),
    prescriberSpeciality: new FormControl(),
    pharmacyName: new FormControl(),
    pharmacyNPI: new FormControl(),
    pharmacyContact: new FormControl(),
    freqVal: new FormControl(null, [Validators.required]),
    routeVal: new FormControl(null, [Validators.required])
  })
  ngOnInit(): void {
    console.log(this.encounterId)

    this.medform.controls['encounterId'].disable();
    this.medform.controls['freqVal'].disable();
    this.medform.controls['routeVal'].disable();
    this.medform.controls['prescriptionId'].disable();
    this.medform.controls['recordId'].disable();
    this.medform.patchValue({ encounterId: this.encounterId })
    this.getFrequencyData()
    this.getDropDownData()

  }
  addnewmed(): void {
    if(this.medform.getRawValue().medName === null || this.medform.getRawValue().medName === "" || this.medform.getRawValue().medName === undefined){
      this.toastr.error("Please Enter Medication Name",'',{
        timeOut: 1500,
                });
      return
    }

    let val = this.medform.getRawValue().frequency
    if (this.isOTH) { val = this.medform.getRawValue().freqVal }
    let valRoute
    if (this.isRoute) { valRoute = this.medform.getRawValue().routeVal }
    else{
      valRoute = this.medform.getRawValue().route?this.medform.getRawValue().route.split('-')[1].trim():null
    }
    let valInst : String    = ""
    if ((this.medform.getRawValue().instructions === null || this.medform.getRawValue().instructions.length === 0) && this.medform.getRawValue().prn === 'Yes')
      {
        valInst = "as needed"
      }
    else if(this.medform.getRawValue().prn === 'Yes' && this.medform.getRawValue().instructions.length > 0 ) {
      valInst = "as needed for " + this.medform.getRawValue().instructions
    }
    else{
      valInst = this.medform.getRawValue().instructions
    }
    let newmedata = {
      "encounterId": this.medform.getRawValue().encounterId,
      "prescriptionId": this.medform.getRawValue().prescriptionId,
      "recordId": this.medform.getRawValue().recordId,
      "drugName": this.medform.getRawValue().medName,
      "medsource": this.medform.getRawValue().medsource,
      "medName": this.medform.getRawValue().medName,
      "category": this.medform.getRawValue().category,
      "ndc": this.medform.getRawValue().ndc,
      "gpi": this.medform.getRawValue().gpi,
      "strength": this.medform.getRawValue().strength,
      "unitofmeasure": this.medform.getRawValue().unitofmeasure,
      "quantity": this.medform.getRawValue().quantity,
      "dose": this.medform.getRawValue().dose,
      "route": valRoute,
      "mbrRefNo": localStorage.getItem('mbrRefNo'),
      // "frequency": this.medform.getRawValue().frequency,
      "frequency": val,
      "form": this.medform.getRawValue().form,
      "daysofsupply": this.medform.getRawValue().daysofsupply,
      "noofrefill": this.medform.getRawValue().noofrefill,
      "totalNoofrefill": this.medform.getRawValue().totalNoofrefill,
      "lastfilleddatetime": this.medform.getRawValue().lastfilleddatetime,
      "recordeddatetime": this.medform.getRawValue().recordeddatetime,
      "instructions": valInst,
      "patientcomments": this.medform.getRawValue().patientcomments,
      "rxclaimnbr": this.medform.getRawValue().rxclaimnbr,
      "rxclaimstatus": this.medform.getRawValue().rxclaimstatus,
      "notificationMsgId": this.medform.getRawValue().notificationMsgId,
      "paitentTakingMed": this.medform.getRawValue().paitentTakingMed,
      "diagnosis": this.medform.getRawValue().diagnosis,
      "diagnosisDescription": this.medform.getRawValue().diagnosisDescription,
      "diagnosisEffectFrom": this.medform.getRawValue().diagnosisEffectFrom,
      "prescriberName": this.medform.getRawValue().prescriberName,
      "prescriberLastName": "",
      "prescriberNPI": this.medform.getRawValue().prescriberNPI,
      "prescriberSpeciality": this.medform.getRawValue().prescriberSpeciality,
      "pharmacyName": this.medform.getRawValue().pharmacyName,
      "pharmacyNPI": this.medform.getRawValue().pharmacyNPI,
      "pharmacyContact": this.medform.getRawValue().pharmacyContact,
      "firstName": this.meddata.firstName,
      "lastName": this.meddata.lastName,
      "dob": this.meddata.dob,
      "gender": this.meddata.gender,
      "prn": this.medform.getRawValue().prn
    }



    if (this.medform.valid && this.medform.getRawValue().frequency != null) {

      console.log("----ndc", this.medform.getRawValue().ndc)
      console.log("----gpi", this.medform.getRawValue().gpi)
      if (this.medform.getRawValue().ndc == "" || this.medform.getRawValue().ndc == null) {

        newmedata["ndc"] = this.medform.getRawValue().medName;

      }
      if (this.medform.getRawValue().gpi == "" || this.medform.getRawValue().gpi == null) {

        newmedata["gpi"] = this.medform.getRawValue().medName;

      }
      let isAdded = false
      console.log(newmedata)

        this.dataservice.addnewmed(newmedata).subscribe(data => {
          this.toastr.success("Medication Added Successfully",'',{
            timeOut: 1500,
                    });
          this.clearMedForm()


        }, error => {
          this.toastr.error("Issue In Data Saving",'',{
            timeOut: 1500,
                    });

        })
    }

    // }
    else {
      this.toastr.error("Please Fill All The Mandatory Fields",'',{
        timeOut: 1500,
                })
    }





  }
  getFrequencyData() {

    this.dataservice.getIsmpData('F').subscribe(data => {

      this.frequencyData = data
      this.frequencyData.forEach(element => {
        if (element.abbr != 'OTH') {
          element['abbrMeaning'] = element['abbr'] + ' - ' + element['meaning']
        }
        else { element['abbrMeaning'] = element['abbr'] }
      })



    }, error => {

    })


  }

  getDropDownData(){
    this.dataservice.getdropdown("FORM").subscribe(data => {
      this.formData = data["values"]
      console.log(this.formData)
    }, error => {


    })
    this.dataservice.getdropdown("ROUTE").subscribe(data => {
      this.routeData = data["values"]

    }, error => {


    })
  }
  cancel(): void {

  }
  setdata(presdata) {

    console.log(presdata);
    this.medform = new FormGroup({
      DrugName: new FormControl(presdata.medication),
      frequency: new FormControl(presdata.frequency),
      Dosage: new FormControl(presdata.DOSE),
      time: new FormControl(presdata.time),
      instructions: new FormControl(presdata.INSTRUCTIONS),
      prn: new FormControl(presdata.prn),
      Indication: new FormControl(presdata.indication),
      Duration: new FormControl(presdata.Duration),
      Prescriber: new FormControl(presdata.presfirstname),
      Adherence: new FormControl(presdata.adherence),
      Durationofth: new FormControl(presdata.durationoftherepy),
      datestart: new FormControl(presdata.meddatestarted),
      enddate: new FormControl(presdata.meddateend),
      Prescribedon: new FormControl(presdata.authored_on),
      lastfilldate: new FormControl(presdata.lastfilldate),
      daysofsupply: new FormControl(presdata.daysofsupply),
      presexpdate: new FormControl(presdata.pres_enddt),
      quantity: new FormControl(presdata.quantity),
      priorrefills: new FormControl(presdata.priorrefill),
      medremain: new FormControl(presdata.medinhand),
      intcmt: new FormControl(presdata.internalcomment),
      extcmt: new FormControl(presdata.externalcomment),
      presspl: new FormControl(presdata.PRESCRIBERSPECIALTY),
      pfcmt: new FormControl(presdata.patientcomment),
      new_med: new FormControl(presdata.new_med),
      phar_reviwed: new FormControl(presdata.phar_reviwed),
      nottakenasdirected: new FormControl(presdata.nottakenasdirected),
      dischargeMed: new FormControl(presdata.dischargeMed),
      episodic: new FormControl(presdata.episodic),
      nonchronic: new FormControl(presdata.nonchronic),
      takingmed: new FormControl(presdata.patienttakingmed),
      asneededfor: new FormControl(presdata.asneededfor),
      ndc: new FormControl(presdata.ndc),
      gpi: new FormControl(presdata.gpi),
      category: new FormControl(presdata.category_1),
      RECORDID: new FormControl(presdata.RECORDID)
    })

  }

  ndcserach() {
    let ndcdata = {}

    let value = Number(this.medform.getRawValue().drugsearch)

    if (Number.isInteger(this.medform.getRawValue().drugsearch)) {
      ndcdata = {
        'type': 'ndc',
        'value': value
      }

    }
    else {
      ndcdata = {
        'type': 'ndc',
        'value': value
      }

    }
    this.dataservice.serachndcdata(ndcdata).subscribe(data => {

      console.log(data)


      this.medform = new FormGroup({
        DrugName: new FormControl(data[0].DRUG_NAME
        ),
        frequency: new FormControl(),

        Dosage: new FormControl(),
        time: new FormControl(),
        instructions: new FormControl(),
        prn: new FormControl(),
        Indication: new FormControl(),
        Duration: new FormControl(),
        Prescriber: new FormControl(),
        Adherence: new FormControl(),
        Durationofth: new FormControl(),
        datestart: new FormControl(),
        enddate: new FormControl(),
        Prescribedon: new FormControl(),
        lastfilldate: new FormControl(),
        daysofsupply: new FormControl(),
        presexpdate: new FormControl(),
        quantity: new FormControl(),
        priorrefills: new FormControl(),
        medremain: new FormControl(),
        intcmt: new FormControl(),
        extcmt: new FormControl(),
        presspl: new FormControl(),
        pfcmt: new FormControl(),
        new_med: new FormControl(),
        phar_reviwed: new FormControl(),
        nottakenasdirected: new FormControl(),
        dischargeMed: new FormControl(),
        episodic: new FormControl(),
        nonchronic: new FormControl(),
        takingmed: new FormControl(),
        ndc: new FormControl(data[0].NDC),
        gpi: new FormControl(data[0].GNRC_ID_NBR),
        category: new FormControl(data[0].CATEGORY_1),
        drugsearch: new FormControl(),
        RECORDID: new FormControl(),
        routeVal: new FormControl()
      })



    }, error => {
      this.toastr.error("Issue In Data Saving",'',{
        timeOut: 1500,
                });

    })


  }

  getmedinfo() {

    let medName = this.medform.getRawValue().drugName
    let inputdata = { "drug": medName }
    this.dataservice.getNDC(inputdata).subscribe(data => {
      this.drugDetails = data

    }, error => {

    })

  }
  getDrug(DrugValue: HTMLInputElement) {
    debugger
    var drugs = DrugValue.value
    console.log("ddddddddddddddddddddd", drugs)
    this.drug = drugs
  }

  onDrugChange(value) {
    debugger


    this.drugDetails.forEach(element => {
      if (element.ndc == value) {
        this.medform.patchValue({
          medName: element.drug_name ,
          ndc: element.ndc,
          gpi: element.gpi,
          category: element.category,
          encounterId: localStorage.getItem('encounterId')

        }

        )
        this.medform.controls['medName'].disable();

      }

    });




  }
  setStep(index: number) {
    this.step = index;
  }

  clearMedForm() {

    this.medform.patchValue({
      prescriptionId: null,
      recordId: null,
      drugName: null,
      medsource: null,
      medName: null,
      category: null,
      ndc: null,
      gpi: null,
      strength: null,
      unitofmeasure: null,
      quantity: null,
      dose: null,
      route: null,
      frequency: null,
      instructions: null,
      form: null,
      daysofsupply: null,
      noofrefill: null,
      totalNoofrefill: null,
      lastfilleddatetime: null,
      recordeddatetime: null,
      prn: null,
      patientcomments: null,
      rxclaimnbr: null,
      rxclaimstatus: null,
      notificationMsgId: null,
      paitentTakingMed: null,
      diagnosis: null,
      diagnosisDescription: null,
      diagnosisEffectFrom: null,
      prescriberName: null,
      prescriberNPI: null,
      prescriberSpeciality: null,
      pharmacyName: null,
      pharmacyNPI: null,
      pharmacyContact: null,
      freqVal: null,
      routeVal:null,

    })

  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
  setStep2(index: number) {
    this.step2 = index;
  }

  nextStep2() {
    this.step2++;
  }

  prevStep2() {
    this.step2--;
  }
  onchangeSource(data) {

    console.log(data)
    this.dataSource = data
  }
  getDrugData() {

    this.medform.controls['medName'].enable();
    console.log(this.medform.getRawValue().drugName.length)
    if (this.medform.getRawValue().drugName.length > 3) {

      let inputdata =
      {

        "drug_name": this.medform.getRawValue().drugName,

        "label_name": "",

        "ndc": "",

        "identifier": "D",

        "match": "E"

      }

      this.dataservice.getMedFromNDCGPI(inputdata).subscribe(data => {
        this.drugDetails = data

      }, error => {
        this.toastr.error("Issue In Data Saving",'',{
          timeOut: 1500,
                  });

      })
    }
  }
  onchangePRN(value) {
    if (value == 'Yes') {
      this.isprn = true
    } else {
      this.isprn = false

    }
  }
  onChangeFrequency(data) {
    let val = data;
    console.log(val)
    if (data == '') {
      this.medform.patchValue({ freqVal: null })
      this.medform.controls['freqVal'].enable();
      this.isOTH = true
    }
    else {
      this.isOTH = false
      let splitString = val
        .split(' ')
        .map((s) => `${s[0].toUpperCase()}${s.slice(1)}`);
      splitString = splitString.join(' ')
      this.medform.patchValue({ freqVal: splitString })
      this.medform.controls['freqVal'].disable();

      //this.medform.patchValue({freqVal: ""})
    }
  }
  camelCase(str){
    str = str.split(' ').map((s) => `${s[0].toUpperCase()}${s.slice(1)}`);
    return str.join(' ')
  }

  onChangeRoute(data) {
    let val = data;
    console.log(val)
    if (data == 'Other') {
      this.medform.patchValue({ routeVal: null })
      this.medform.controls['routeVal'].enable();
      this.isRoute = true
    }
   else if (data == '') {
          this.medform.patchValue({ routeVal: null })
          this.medform.controls['routeVal'].disable();
          this.isRoute = false
        }
    else {
      this.isRoute = false
      let splitString = val.split('-')[1].trim()
      this.medform.patchValue({ routeVal: this.camelCase(splitString)})
      this.medform.controls['routeVal'].disable();
    }
  }


}
